import { useState, useEffect } from 'react'
import dotGrid from "../../images/dot-grid.png"
import "./ItilFoundationLanding.css"
import { Link, useNavigate } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import logo from "../../images/ict-logo.png"
import { Button } from '@mui/material'
import { useDispatch } from "react-redux"
import baseline2Blog from "../../images/baseline2-blog.jpg"
import agitateArrow from "../../images/agitate-arrow.png"
import smallBucket from "../../images/small-bucket.png"
import bigBucket from "../../images/big-bucket.png"
import digitalTransformation from "../../images/digital-transformation.jpg"
import reason1 from "../../images/reason1.png"
import reason2 from "../../images/reason2.png"
import reason3 from "../../images/reason3.png"
import happyWoman from "../../images/happy-woman.jpg"
import happyWomanSmall from "../../images/happy-woman-small.jpg"
import heroWoman from "../../images/hero-woman.png"
import heroWomanSmall from "../../images/hero-woman-small.png"
import happyBlackMan1 from "../../images/happy-blackman1.jpg"
import happyBlackMan1Small from "../../images/happy-blackman1-small.jpg"
import mutisoPhoto from "../../images/mutiso-muathime.jpg"
import mutisoPhotoSmall from "../../images/mutiso-muathime-small.jpg"

import kra from "../../images/kra-logo.png"
import hf from "../../images/hf-logo.webp"
import kpmg from "../../images/kpmg-logo.png"
import naivas from "../../images/naivas-logo.png"
import ncba from "../../images/ncba-logo.png"
import kenyaSeedCompany from "../../images/kenya-seed-company.png"
import kentrade from "../../images/kentrade.png"
import kerra from "../../images/kerra.png"
import kengen from "../../images/kengen.png"
import kemri from "../../images/kemri.png"
import ictAuthority from "../../images/ict-authority.png"
import zemen from "../../images/zemen.png"
import stanbic from "../../images/stanbic.png"
import amica from "../../images/amica.jpg"
import kimisitu from "../../images/kimisitu.png"
import pioneer from "../../images/pioneer.png"
import finlays from "../../images/finlays.png"
import impax from "../../images/impax.png"
import kenyaAirways from "../../images/kenya-airways.png"
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import rocket from "../../images/rocket.png"
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import digitalVisitTransformation from "../../images/digitalvsit-transformation.jpg"
import locationFound from "../../images/location-found.png"
import PlayBtn from '@mui/icons-material/PlayArrowRounded';
import BookmarksRoundedIcon from '@mui/icons-material/BookmarksRounded';
import { InlineWidget } from "react-calendly";
import axios from 'axios'


// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import Swiper required modules
import { Pagination, Autoplay } from 'swiper/modules';
import ReactGa from "react-ga4"





const ItilFoundationLanding = () => {


    // STATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [companySize, setCompanySize] = useState('')
    const [activeNav, setActiveNav] = useState(false)

    const [loading, setLoading] = useState(false)



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate()
    const dispatch = useDispatch()

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');

    // USEEFFECTS

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')
        const appearDowns = document.querySelectorAll('.appear-down')
        const appearUps = document.querySelectorAll('.appear-up')


        const appearLeftsFull = document.querySelectorAll('.appear-left-full')
        const appearRightsFull = document.querySelectorAll('.appear-right-full')
        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftFullOptions = {
            threshold: 0.2,
        }

        const appearLeftFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftFullOptions)

        appearLeftsFull.forEach(appearLeft => {
            appearLeftFullObserver.observe(appearLeft)
        })

        const appearRightFullOptions = {
            threshold: 0.2,
        }

        const appearRightFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFullOptions)

        appearRightsFull.forEach(appearRight => {
            appearRightFullObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])


    ////////////////////////////////////////////////////////



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS

    // CHANGE NAV BACKGROUND ON SCROLL

    const changeNavBg = () => {
        if (window.scrollY >= 50) {
            setActiveNav(true)
        } else {
            setActiveNav(false)
        }
    }


    window.addEventListener('scroll', changeNavBg)

    ////////////////////////////////////////////////////////////////////////////////////////////


    // Book Demo Form Functionality
    const onBeforeBookSubmit = (e) => {
        e.preventDefault()




        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (company.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your company name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (companySize.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your company size"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        }

        else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "company": company,
                "companySize": companySize
            }


            onSubmitBooking(payload)
        }



    }

    const onSubmitBooking = (payload) => {
        axios.post("https://www.ict-mentors.com/api/book_demo", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setLoading(false)

                setName('')
                setEmail('')
                setPhone('')
                setCompany('')
                setCompanySize('')
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
        openCalendly()
        window.dataLayer.push({event: 'book_offer_demo'})

        ReactGa.event("book_offer_demo", {product_name: "ITIL 4 Foundation"})
    }

    /////////////////////////////////////////////////////////////////////////

    const scrollSection = () => {
        const solSection = document.querySelector('.itilfoundation-land-container .solution-section')
        const bodyRect = document.body.getBoundingClientRect()
        const elementRect = solSection.getBoundingClientRect()

        const navContainer = document.querySelector('.itilfoundation-land-container .nav-container')

        let verticalPosition = elementRect.top - bodyRect.top
        let navHeight = navContainer.offsetHeight + 30;

        window.scrollTo(0, verticalPosition - navHeight)
    }

    const scrollCalendly = () => {
        const calSection = document.querySelector('.itilfoundation-land-container .calendly-container')
        const bodyRect = document.body.getBoundingClientRect()
        const elementRect = calSection.getBoundingClientRect()

        const navContainer = document.querySelector('.itilfoundation-land-container .nav-container')

        let verticalPosition = elementRect.top - bodyRect.top
        let navHeight = navContainer.offsetHeight + 30;

        window.scrollTo(0, verticalPosition - navHeight)
    }

    const scrollDemo = () => {
        const demoSection = document.querySelector('.itilfoundation-land-container .cta-section')
        const bodyRect = document.body.getBoundingClientRect()
        const elementRect = demoSection.getBoundingClientRect()

        const navContainer = document.querySelector('.itilfoundation-land-container .nav-container')

        let verticalPosition = elementRect.top - bodyRect.top
        let navHeight = navContainer.offsetHeight + 50;

        window.scrollTo(0, verticalPosition - navHeight)

    }


    const openCalendly = () => {

        const calendlyContainer = document.querySelector('.itilfoundation-land-container .calendly-container')

        calendlyContainer.classList.toggle('active')


        if (calendlyContainer.classList.contains('active')) {
            setTimeout(() => {
                scrollCalendly()
            }, 500)
        }
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    return (
        <ThemeProvider theme={theme}>
            <div className='itilfoundation-land-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>

                <div className={activeNav ? 'nav-container active' : 'nav-container'}>
                    <Link to="/"><img src={logo} className="logo appear-up" alt="ICT Mentors Logo" loading='lazy' /></Link>
                    <Button variant='outlined' className='land-cta-btn appear-right' color='primary'>Book  a Free Demo</Button>
                </div>

                <section className="hero-section">
                    <div className="hero-left">
                        <h1 className="hero-title appear-left">Sustained <span style={{ color: "var(--secondary)" }}>growth</span> that compounds with time</h1>
                        <p className="hero-p appear-down">Digital transformation roadmap that has been tailored to your own organization</p>
                        <Button variant='contained' className='cta-btn appear-right' color='secondary' onClick={scrollDemo}>Book a Free Demo</Button>

                        <img src={locationFound} alt="" className="small-hero-img appear-right" />
                    </div>

                    <div className="hero-right">
                        <div className="video-container appear-right">
                            <img src={baseline2Blog} alt="" />
                            <div className="overlay">
                                <PlayBtn className='play-btn' />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="agitate-section">
                    <div className="agitate-left">
                        <p className="agitate-p appear-up">Business owners, are you worried where you worried where your next client will come from? Are you just barely breaking even due to high churn rate? Is there a disconnect between your sales and marketing?</p>
                        <img src={agitateArrow} alt="" className='agitate-arrow' onClick={scrollSection} />
                    </div>
                    <div className="agitate-right">
                        <img src={smallBucket} alt="" className='small-bucket small-bucket1 appear-right' />
                        <img src={smallBucket} alt="" className='small-bucket small-bucket2 appear-left' />
                        <img src={bigBucket} alt="" className='big-bucket appear-down' />
                    </div>
                </section>

                <section className="solution-section">
                    <div className="solution-left">
                        <div className="img-container appear-left">
                            <img src={digitalTransformation} alt="" />
                            <div className="overlay"></div>
                        </div>
                    </div>
                    <div className="solution-right">
                        <h1 className="section-title appear-right">Have 60% more customers chase you, not vice versa!!</h1>
                        <p className="solution-p appear-up">Automate your sales outreach to ensure each day you are reaching thousands of potential clients all on auto-pilot. Ensure your leads are constantly engaged to keep them warm, while your support staff is sleeping or on weekends.</p>
                        <Button variant='contained' className='cta-btn appear-down' color='secondary' onClick={scrollDemo}>Book a Free Demo</Button>
                    </div>
                </section>

                <section className="dream-section">
                    <div className="dream-wrapper">
                        <div className="dream dream1 appear-left">
                            <img src={reason1} alt="" className="dream-img" />
                            <h3 className="dream-h3">Dream Outcome1</h3>
                            <p className="dream-p">Align your IT services with your business objectives to ensure you’re always meeting your targets</p>
                        </div>
                        <div className="dream dream2 appear-up">
                            <img src={reason2} alt="" className="dream-img" />
                            <h3 className="dream-h3">Dream Outcome2</h3>
                            <p className="dream-p">Align your IT services with your business objectives to ensure you’re always meeting your targets</p>
                        </div>
                        <div className="dream dream3 appear-right">
                            <img src={reason3} alt="" className="dream-img" />
                            <h3 className="dream-h3">Dream Outcome3</h3>
                            <p className="dream-p">Align your IT services with your business objectives to ensure you’re always meeting your targets</p>
                        </div>
                    </div>
                </section>

                <section className="testimonials-section">
                    <div className="testimonial-left appear-left">
                        <h1 className="section-title test-small-h1">What our clients are saying</h1>

                        <Swiper
                            pagination={{ clickable: true }}
                            loop
                            grabCursor
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${happyWomanSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={happyWoman} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Jerioth Wanjiru</h3>

                                        <p className="test-p">Working with ICT Mentors Solutions is one of the best decisions I’ve ever made for my career. Not only were they understanding, but also determined to see me grow as a professional</p>
                                        <p className="test-p test-p2">~Cyber Security Analyst</p>


                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${heroWomanSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={heroWoman} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Nivah Makanda</h3>

                                        <p className="test-p">ICT Mentors Solutions is an excellent workplace for professional trainers, offering strong support for professional development and growth. The training environment is versatile and supportive, fostering the love I have for my career.</p>
                                        <br />
                                        <p className="test-p test-p2">~DevOps Trainer</p>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${happyBlackMan1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={happyBlackMan1} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Dennis Yegon</h3>

                                        <p className="test-p">My experience as a SOC analyst was quite enriching. The company's emphasis on cyber resilience, upskilling, and use of cutting-edge tools, including top-rated SIEM systems, provided valuable growth opportunities. It's a proactive environment, where learning from experience, is a major theme.</p>
                                        <br />
                                        <p className="test-p test-p2">~Cyber Security Analyst</p>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${mutisoPhotoSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={mutisoPhoto} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Mutiso Muathime</h3>

                                        <p className="test-p">I am very thankful for the opportunity ICT Mentors Solutions gave me. By far the best place I've worked in to date. The environment is always vibrant and enthusiastic, it's like a second family.</p>
                                        <br />
                                        <p className="test-p test-p2">~Digital & AI Expert</p>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>

                    <div className="testimonial-right appear-right">
                        <h1 className="test-right-h1">What our clients are saying</h1>
                    </div>
                </section>

                <section className="clients-section">
                    <h1 className="section-title appear-right">Some of our Clients</h1>

                    <div className="logos">
                        <div className="logos-slide">
                            <img src={kra} alt="KRA" className='kra' loading='lazy' />
                            <img src={kenyaSeedCompany} alt="Kenya Seed Company" className='ksc' loading='lazy' />
                            <img src={kemri} alt="Kemri" className='kemri' loading='lazy' />
                            <img src={kengen} alt="KenGen" className='kengen' loading='lazy' />
                            <img src={kerra} alt="Kenya Rural Roads Authority" className='kerra' loading='lazy' />
                            <img src={kentrade} alt="The Kenya TradeNet" className='kentrade' loading='lazy' />
                            <img src={ictAuthority} alt="ICT Authority" className='ict-authority' loading='lazy' />
                            <img src={zemen} alt="Zemen Bank" className="zemen" />
                            <img src={stanbic} alt="Stanbic Bank" className="stanbic" />
                            <img src={kimisitu} alt="Kimisitu Sacco" className="kimisitu" />
                            <img src={amica} alt="Amica" className="amica" />
                            <img src={pioneer} alt="Pioneer Insurance" className="pioneer" />
                            <img src={finlays} alt="Finlays" className="finlays" />
                            <img src={impax} alt="Impax" className="impax" />
                            <img src={kenyaAirways} alt="Kenya Airways" className="kenya-airways" />
                            <img src={hf} alt="HFC Bank" className='hf' loading='lazy' />
                            <img src={kpmg} alt="KPMG" className='kpmg' loading='lazy' />
                            <img src={naivas} alt="Naivas" className='naivas' loading='lazy' />
                            <img src={ncba} alt="NCBA" className='ncba' loading='lazy' />
                        </div>

                        <div className="logos-slide">
                            <img src={kra} alt="KRA" className='kra' loading='lazy' />
                            <img src={kenyaSeedCompany} alt="Kenya Seed Company" className='ksc' loading='lazy' />
                            <img src={kemri} alt="Kemri" className='kemri' loading='lazy' />
                            <img src={kengen} alt="KenGen" className='kengen' loading='lazy' />
                            <img src={kerra} alt="Kenya Rural Roads Authority" className='kerra' loading='lazy' />
                            <img src={kentrade} alt="The Kenya TradeNet" className='kentrade' loading='lazy' />
                            <img src={ictAuthority} alt="ICT Authority" className='ict-authority' loading='lazy' />
                            <img src={zemen} alt="Zemen Bank" className="zemen" />
                            <img src={stanbic} alt="Stanbic Bank" className="stanbic" />
                            <img src={kimisitu} alt="Kimisitu Sacco" className="kimisitu" />
                            <img src={amica} alt="Amica" className="amica" />
                            <img src={pioneer} alt="Pioneer Insurance" className="pioneer" />
                            <img src={finlays} alt="Finlays" className="finlays" />
                            <img src={impax} alt="Impax" className="impax" />
                            <img src={kenyaAirways} alt="Kenya Airways" className="kenya-airways" />
                            <img src={hf} alt="HFC Bank" className='hf' loading='lazy' />
                            <img src={kpmg} alt="KPMG" className='kpmg' loading='lazy' />
                            <img src={naivas} alt="Naivas" className='naivas' loading='lazy' />
                            <img src={ncba} alt="NCBA" className='ncba' loading='lazy' />
                        </div>
                    </div>
                </section>

                <section className="offer-section">
                    <div className="offer-left appear-left"><img src={rocket} alt="" /></div>
                    <div className="offer-right">

                        <div className="offer-right-top">
                            <h2 className="offer-h2 appear-right">Get ITIL 4 Foundation</h2>
                            <h1 className="discount-h1 appear-left">At 45% Discount</h1>
                            <div className="bonuses">
                                <h2 className="offer-h2 appear-up">+ Free AI Audit </h2>
                                <h2 className="offer-h2 appear-down">+ Free PenTest</h2>
                            </div>
                        </div>

                        <div className="offer-right-bottom">
                            <Button variant='contained' className='cta-btn appear-up' color='secondary' onClick={scrollDemo}>Book a Free Demo</Button>

                            <div className="notices">
                                <p className="notice-p notice1 appear-left">No Credit Card Required</p>
                                <p className="notice-p notice2 appear-right">Demo is only 15 Minutes</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="cta-section">
                    <div className="cta-left">
                        <h1 className="section-title appear-left">Start a Journey of Transformation</h1>
                        <p className="cta-p appear-right">Join top organizations in Kenya transforming their business landscape</p>

                        <div className="notices">
                            <p className="notice-p notice1 appear-left">No Credit Card Required</p>
                            <p className="notice-p notice2 appear-right">Demo is only 15 Minutes</p>
                        </div>

                        <form onSubmit={onBeforeBookSubmit} id='landing-form'>
                            <TextField label="Name" className='text-field appear-left' type='text' variant="outlined" name='name' value={name} onChange={e => setName(e.target.value)} />
                            <TextField label="Email" className='text-field appear-right' type='email' variant="outlined" name='email' value={email} onChange={e => setEmail(e.target.value)} />
                            <TextField label="Phone" className='text-field appear-left' type='number' variant="outlined" name='phone' value={phone} onChange={e => setPhone(e.target.value)} />
                            <TextField label="Company" className='text-field appear-right' type='text' variant="outlined" name='company' value={company} onChange={e => setCompany(e.target.value)} />
                            <TextField label="Company Size" className='text-field appear-left' type='number' variant="outlined" name='companySize' value={companySize} onChange={e => setCompanySize(e.target.value)} />

                            <LoadingButton
                                className='cta-btn appear-down'
                                type='submit' variant='contained'
                                color='secondary'
                                id='ITIL 4 Foundation'
                                style={{ color: "#ffffff" }}
                                endIcon={<BookmarksRoundedIcon className='book-icon' />}
                                loading={loading}
                                loadingPosition="end"
                            >
                                <span>Book Free Demo</span>

                            </LoadingButton>
                        </form>
                    </div>

                    <div className="cta-right">
                        <img src={digitalVisitTransformation} alt="" />
                        <div className="overlay"></div>
                    </div>
                </section>

                <div className="calendly-container">
                    <InlineWidget url="https://calendly.com/ict-mentors/product-demo" prefill={{ email: email, name: name }} />
                </div>

                <div className="warning">
                    <p className="warning-p appear-up">** We were planning to run the offer a bit longer to avail this opportunity to more people, but due to extreme demand, we’re shutting the doors pretty soon, so hurry and book your free demo today **</p>
                </div>


                <div className="logo-footer">
                    <img src={logo} alt="" />
                </div>


            </div>
        </ThemeProvider>
    )
}

export default ItilFoundationLanding