import { useState, useEffect } from 'react'
import "./Work.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import workImg from "../../images/work-with-us.jpg"
import workImgSmall from "../../images/work-with-us-small.jpg"
import happyWoman from "../../images/happy-woman.jpg"
import happyWomanSmall from "../../images/happy-woman-small.jpg"
import heroWoman from "../../images/hero-woman.png"
import heroWomanSmall from "../../images/hero-woman-small.png"
import happyBlackMan1 from "../../images/happy-blackman1.jpg"
import happyBlackMan1Small from "../../images/happy-blackman1-small.jpg"
import happyBlackMan2 from "../../images/happy-blackman2.jpg"
import happyBlackMan2Small from "../../images/happy-blackman2-small.jpg"
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Button } from '@mui/material'
import { createTheme, ThemeProvider } from "@mui/material/styles"
import reason1 from "../../images/reason1.png"
import reason2 from "../../images/reason2.png"
import reason3 from "../../images/reason3.png"
import placeholder from "../../images/page-not-found.png"
import mutisoPhoto from "../../images/mutiso-muathime.jpg"
import mutisoPhotoSmall from "../../images/mutiso-muathime-small.jpg"
import ReactGa from "react-ga4"




// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import Swiper required modules
import { Pagination, Autoplay } from 'swiper/modules';




const Work = () => {

    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#011B25"
            }

        }
    })


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    return (
        <ThemeProvider theme={theme}>
            <div className='work-container'>

                <Helmet>
                    <title>Work With Us</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="hero-section">
                    <div className="hero-left appear-left">
                        <h1 className="hero-title">Work With Us <span className="dot-span">.</span></h1>
                        <p className="hero-p">Start on a journey of Growth and Collaboration at ICT Mentors Solutions – Where Expertise Flourishes in a Thriving Community</p>
                        <img src={placeholder} alt="" className='placeholder' />
                    </div>

                    <div className="hero-right">
                        <div className="img-container blur-div" style={{ backgroundImage: `url(${workImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                            <img src={workImg} alt="" />
                        </div>
                    </div>
                </section>

                {/* <section className="opportunities-section">
                    <h1 className="opportunities-h1 appear-left">Current Opportunities</h1>
                    <div className="opportunities-wrapper appear-right">
                        <Swiper
                            pagination={{ clickable: true }}
                            loop
                            grabCursor
                            autoplay={{
                                delay: 7000,
                                disableOnInteraction: false,
                            }}
                            slidesPerView={1}
                            breakpoints={{
                                500: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                    spaceBetween: 20
                                },
                                900: {
                                    slidesPerView: 3,
                                    slidesPerGroup: 3,
                                    spaceBetween: 20
                                },
                                1700: {
                                    spaceBetween: 40
                                }
                            }}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >

                            <SwiperSlide>
                                <div className="opportunity opportunity1">
                                    <h3 className="opp-title">ITIL 4 specialist Trainer </h3>
                                    <p className="opp-p">Responsible for conducting training classes for ITIL and must be ITILv4 MP/SL certified </p>
                                    <a href="#" target='__blank'><Button variant='outlined' color='warning' className='opp-btn' id='ITIL 4 specialist Trainer'>Explore Opportunity</Button></a>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="opportunity opportunity2">
                                    <h3 className="opp-title">Cyber Security Analysts </h3>
                                    <p className="opp-p">Responsible for protecting an organization's networks and systems from cyber-attacks.</p>
                                    <a href="#" target='__blank'><Button variant='outlined' color='warning' className='opp-btn' id='Cyber Security Analysts'>Explore Opportunity</Button></a>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="opportunity opportunity3">
                                    <h3 className="opp-title">CISSP Trainer</h3>
                                    <p className="opp-p">Responsible for conducting training classes for CISSP and must be CISSP certified</p>
                                    <a href="#" target='__blank'><Button variant='outlined' color='warning' className='opp-btn' id='CISSP Trainer'>Explore Opportunity</Button></a>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="opportunity opportunity3">
                                    <h3 className="opp-title">TOGAF Trainer</h3>
                                    <p className="opp-p">Responsible for conducting training classes for TOGAF and must be TOGAF certified</p>
                                    <a href="#" target='__blank'><Button variant='outlined' color='warning' className='opp-btn' id='TOGAF Trainer'>Explore Opportunity</Button></a>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="opportunity opportunity3">
                                    <h3 className="opp-title">ISO Implementation Consultants</h3>
                                    <p className="opp-p">Responsible for conducting training & Implementation of ISO 27001/ ISO 22301 frameworks and must be certified in those areas.</p>
                                    <a href="#" target='__blank'><Button variant='outlined' color='warning' className='opp-btn' id='ISO Implementation Consultants'>Explore Opportunity</Button></a>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="opportunity opportunity3">
                                    <h3 className="opp-title">ISO20000 Trainer</h3>
                                    <p className="opp-p">Responsible for conducting training classes on ISO 20000 certification course. Should be ISO 20000 certified.</p>
                                    <a href="#" target='__blank'><Button variant='outlined' color='warning' className='opp-btn' id='ISO20000 Trainer'>Explore Opportunity</Button></a>
                                </div>
                            </SwiperSlide>

                        </Swiper>

                    </div>
                </section> */}

                <section className="testimonials-section">
                    <div className="testimonial-left appear-left">
                        <h1 className="test-small-h1">Choose a space to grow</h1>

                        <Swiper
                            pagination={{ clickable: true }}
                            loop
                            grabCursor
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${happyWomanSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={happyWoman} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Jerioth Wanjiru</h3>

                                        <p className="test-p">Working with ICT Mentors Solutions is one of the best decisions I’ve ever made for my career. Not only were they understanding, but also determined to see me grow as a professional</p>
                                        <p className="test-p test-p2">~Cyber Security Analyst</p>


                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${heroWomanSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={heroWoman} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Nivah Makanda</h3>

                                        <p className="test-p">ICT Mentors Solutions is an excellent workplace for professional trainers, offering strong support for professional development and growth. The training environment is versatile and supportive, fostering the love I have for my career.</p>
                                        <br />
                                        <p className="test-p test-p2">~DevOps Trainer</p>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${happyBlackMan1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={happyBlackMan1} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Dennis Yegon</h3>

                                        <p className="test-p">My experience as a SOC analyst was quite enriching. The company's emphasis on cyber resilience, upskilling, and use of cutting-edge tools, including top-rated SIEM systems, provided valuable growth opportunities. It's a proactive environment, where learning from experience, is a major theme.</p>
                                        <br />
                                        <p className="test-p test-p2">~Cyber Security Analyst</p>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="test-container">
                                    <div className="test-left">
                                        <div className="img-container blur-div" style={{ backgroundImage: `url(${mutisoPhotoSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                            <img src={mutisoPhoto} alt="" />
                                        </div>
                                    </div>

                                    <div className="test-right">
                                        <div className="stars">
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                            <StarRoundedIcon />
                                        </div>

                                        <h3 className="test-name">Mutiso Muathime</h3>

                                        <p className="test-p">I am very thankful for the opportunity ICT Mentors Solutions gave me. By far the best place I've worked in to date. The environment is always vibrant and enthusiastic, it's like a second family.</p>
                                        <br />
                                        <p className="test-p test-p2">~Digital & AI Expert</p>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>

                    <div className="testimonial-right appear-right">
                        <h1 className="test-right-h1">Choose A Space To Grow</h1>
                    </div>
                </section>



                <section className="why-section">
                    <h1 className="why-h1 appear-left">Why Work With Us</h1>

                    <div className="why-wrapper">
                        <div className="reason reason1">
                            <div className="reason-left appear-left">
                                <img src={reason1} alt="" />
                            </div>

                            <div className="reason-right appear-right">
                                <h3 className="reason-title">Professional Development</h3>
                                <p className="reason-p">We place a strong emphasis on employee development,hence we offer various training programs and resources to help you grow in your role, from technical certifications to soft skills development.</p>
                            </div>
                        </div>

                        <div className="reason reason2">
                            <div className="reason-left appear-left">
                                <img src={reason2} alt="" />
                            </div>

                            <div className="reason-right appear-right">
                                <h3 className="reason-title">Innovative Environment</h3>
                                <p className="reason-p">We foster an innovative culture, encouraging employees to think creatively and come up with new solutions. This environment can be invigorating and inspiring for those who embrace innovation.</p>
                            </div>
                        </div>

                        <div className="reason reason3">
                            <div className="reason-left appear-left">
                                <img src={reason3} alt="" />
                            </div>

                            <div className="reason-right appear-right">
                                <h3 className="reason-title">Strong Company Culture</h3>
                                <p className="reason-p">A positive work culture is a hallmark of ICT Mentors Solutions Limited. We value teamwork, spirit of excellence, collaboration, and a supportive atmosphere. Expect to work in an environment that respects work-life balance and values the well-being of its employees.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default Work