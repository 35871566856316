import { useState, useEffect } from 'react'
import "./CoursePage.css"
import Nav from "../nav/Nav"
import Footer from "../footer/Footer"
import { Button } from '@mui/material'
import { createTheme, ThemeProvider } from "@mui/material/styles"
import itilImg from "../../images/itil-image.png"
import calendar from "../../images/calendar.svg"
import book from "../../images/book.svg"
import folder from "../../images/folder.svg"
import wallet from "../../images/wallet.svg"
import arrowrightCircle from "../../images/arrow-right-circle.svg"
import { Link, useNavigate } from "react-router-dom"
import funkyLines from "../../images/funky-lines.png"
import courseHero from "../../images/course-hero.png"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"


// ACCORDION IMPORTS

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from 'axios'
import { useDispatch } from 'react-redux'


// ACCORDION COMPONENTS


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));




const ISTQBCtfl = () => {

  // STATES

  const courseId = 'C029'

  const [expanded, setExpanded] = useState('panel1');
  const [similarCourses, SetSimilarCourses] = useState([])


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // INITIALIZATIONS

  const theme = createTheme({
    palette: {
      primary: {
        main: '#27AAE1'
      },

      secondary: {
        main: "#2E3092"
      },
      warning: {
        main: "#ffffff"
      }

    }
  })

  const navigate = useNavigate('')
  const dispatch = useDispatch()

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // USEEFFECTS

  // INTERSECTION OBSERVERS

  useEffect(() => {
    const appearLefts = document.querySelectorAll('.appear-left')
    const appearRights = document.querySelectorAll('.appear-right')
    const appearDowns = document.querySelectorAll('.appear-down')
    const appearUps = document.querySelectorAll('.appear-up')


    const appearLeftsFull = document.querySelectorAll('.appear-left-full')
    const appearRightsFull = document.querySelectorAll('.appear-right-full')
    const appearDownsFull = document.querySelectorAll('.appear-down-full')


    const appearLeftOptions = {
      threshold: 0.2,
    }

    const appearLeftObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftOptions)

    appearLefts.forEach(appearLeft => {
      appearLeftObserver.observe(appearLeft)
    })




    const appearRightOptions = {
      threshold: 0.2,
    }

    const appearRightObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightOptions)

    appearRights.forEach(appearRight => {
      appearRightObserver.observe(appearRight)
    })


    const appearDownOptions = {
      threshold: 0.2,
    }

    const appearDownObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownOptions)

    appearDowns.forEach(appearRight => {
      appearDownObserver.observe(appearRight)
    })




    const appearLeftFullOptions = {
      threshold: 0.2,
    }

    const appearLeftFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftFullOptions)

    appearLeftsFull.forEach(appearLeft => {
      appearLeftFullObserver.observe(appearLeft)
    })

    const appearRightFullOptions = {
      threshold: 0.2,
    }

    const appearRightFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightFullOptions)

    appearRightsFull.forEach(appearRight => {
      appearRightFullObserver.observe(appearRight)
    })

    const appearDownFullOptions = {
      threshold: 0.2,
    }

    const appearDownFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearDownsFull.forEach(appearRight => {
      appearDownFullObserver.observe(appearRight)
    })

    const appearUpOptions = {
      threshold: 0.2,
    }

    const appearUpObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearUps.forEach(appearUp => {
      appearUpObserver.observe(appearUp)
    })
  }, [])


  // Get Similar Courses

  useEffect(() => {
    axios.post('https://www.ict-mentors.com/api/get_similar', { 'courseId': courseId })
      .then(res => {
        SetSimilarCourses(res.data)

      })
      .catch(err => {
        const error = {
          message: err.response.data,
          status: err.response.status
        }

        dispatch({
          type: 'GET_ERRORS',
          payload: error
        })

      })
  }, [])



  /////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////





  // FUNCTIONS

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const openAudience = () => {
    document.querySelector('.coursepage-container .detailed-section .detailed-body .detail-div.active').classList.remove('active')
    document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.active').classList.remove('active')

    const audienceDiv = document.querySelector('.coursepage-container .detailed-section .detailed-body .audience-div')
    const audienceLi = document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.audience-li')


    audienceDiv.classList.add('active')
    audienceLi.classList.add('active')
  }


  const openObjectives = () => {
    document.querySelector('.coursepage-container .detailed-section .detailed-body .detail-div.active').classList.remove('active')
    document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.active').classList.remove('active')

    const objectivesDiv = document.querySelector('.coursepage-container .detailed-section .detailed-body .objectives-div')
    const objectivesLi = document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.objectives-li')


    objectivesDiv.classList.add('active')
    objectivesLi.classList.add('active')

  }

  const openExams = () => {
    document.querySelector('.coursepage-container .detailed-section .detailed-body .detail-div.active').classList.remove('active')
    document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.active').classList.remove('active')

    const examsDiv = document.querySelector('.coursepage-container .detailed-section .detailed-body .exams-div')
    const examsLi = document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.exams-li')


    examsDiv.classList.add('active')
    examsLi.classList.add('active')
  }

  const navigateCourse = (url) => {
    navigate(`/${url}`)
  }



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // ACCORDION STUFF


  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


  const goContact = () => {
    window.dataLayer.push({event: 'go_enquire'})

    ReactGa.event("go_enquire", {enquiring_from: "ISTQB CTFL"})
    navigate('/contact_us?subject=ISTQB Certified Tester Foundation Level (CTFL)')
  }

  const goBack = () => {
    window.history.back();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




  return (
    <ThemeProvider theme={theme}>
      <div className='coursepage-container'>

        <Helmet>
          <title>ISTQB® Certified Tester Foundation Level (CTFL)</title>
          <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
        </Helmet>

        <Nav />
        <section className="hero-section">
          <div className="triangle"></div>
          <div className="hero-left appear-left">

            <svg width="58" height="52" viewBox="0 0 58 52" fill="none" className='back-arrow' onClick={goBack}>
              <path d="M18 3.75L4.25 17.5L18 31.25" stroke="#011B25" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4.25 17.5H26.25C41.4382 17.5 53.75 29.8118 53.75 45V47.75" stroke="#011B25" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <h1 className="hero-h1">ISTQB® Certified Tester Foundation Level (CTFL)</h1>

            <ul className="hero-ul">
              <li>
                <div className="bullet-div"></div>
                <span className="point-span">Promote efficient and effective communication by using a common vocabulary for software testing.</span>
              </li>
              <li>
                <div className="bullet-div"></div>
                <span className="point-span">Understand fundamental concepts of software testing.</span>
              </li>
              <li>
                <div className="bullet-div"></div>
                <span className="point-span">Contribute effectively in reviews</span>
              </li>

            </ul>

            <div className="buttons-div">
              <Button variant="outlined" color="secondary" onClick={goContact} className='enquire-btn' id='istqb_ctfl'>Enquire Now</Button>
            </div>
          </div>

          <div className="hero-right ">
            <img src={courseHero} alt="" className='course-hero-img appear-right' loading='lazy' />
          </div>
        </section>


        <section className="overview-section appear-left">
          <h1 className="section-title">Overview</h1>

          <p className="overview-p">The ITIL 4 Specialist Create, Deliver and Support course covers the ‘core’ service management activities and expands the current scope of ITIL to cover the ‘creation’ of services. This course focuses on the integration of different value streams and activities to create, deliver and support IT-enabled products and services whilst also covering supporting practices, methods and tools. In addition this course will also cover service performance and will give practitioners an understanding of service quality and improvement methods. It will enable IT practitioners to continue to deliver innovative yet reliable tech-enabled services to their customers in an increasingly competitive market.</p>
        </section>

        <section className="detailed-section">
          <div className="triangle"></div>
          <div className="detailed-nav appear-left">
            <ul>
              <li className="audience-li active" onClick={openAudience}>Target Audience</li>
              <li className="objectives-li" onClick={openObjectives}>Course Objectives</li>
              <li className="exams-li" onClick={openExams}>Exams</li>
            </ul>
          </div>


          <div className="detailed-body appear-right">

            <div className="audience-div detail-div active">
              <h2 className="section-title">Target Audience of ISTQB® Certified Tester Foundation</h2>

              <p className="audience-p">Job roles that can take this training include, but are not limited to:</p>

              <ul className="audience-ul">
                <li className="audience-li">Aspiring Testers</li>
                <li className="audience-li">Software Testers</li>
                <li className="audience-li">Software Developers</li>
                <li className="audience-li">Project Managers</li>
                <li className="audience-li">Business Analysts</li>
                <li className="audience-li">IT Consultants</li>
                <li className="audience-li">All users who develop, test or accept software systems</li>

              </ul>
            </div>


            <div className="objectives-div detail-div">
              <h2 className="section-title">Course Objectives</h2>

              <p className="objectives-p">Participants who take part in the ITIL 4 Specialist: CDS training will learn about:</p>

              <ul className="objectives-ul">
                <li className="objectives-li">Promote efficient and effective communication by using a common vocabulary for software testing.</li>
                <li className="objectives-li">Understand fundamental concepts of software testing.</li>
                <li className="objectives-li">Demonstrate an understanding of how different development and testing practices, and different constraints on testing, may apply in optimizing testing to different contexts.</li>
                <li className="objectives-li">Contribute effectively in reviews.</li>
                <li className="objectives-li">Use established techniques for designing tests at all test levels.</li>
                <li className="objectives-li">Interpret and execute tests from given test specifications.</li>
                <li className="objectives-li">Report on test results.</li>
                <li className="objectives-li">Understand test management principles for resources, strategies, planning, project control and risk management.</li>
                <li className="objectives-li">Write and communicate clear and understandable defect reports.</li>
                <li className="objectives-li">Understand the project factors that drive the test priorities and test approach.</li>
              </ul>
            </div>
            <div className="exams-div detail-div">
              <h2 className="section-title">Exams</h2>

              <ul className="exams-ul">
                <li className="exams-li"><span className="bold-span">Type of questions:</span> Multiple Choice.</li>
                <li className="exams-li"><span className="bold-span">Duration:</span> 60 Minutes.</li>
                <li className="exams-li"><span className="bold-span">Total questions:</span> There are 40 questions</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="why-section">

          <h1 className="section-title appear-left">Why train with ICT Mentors Soutions?</h1>

          <div className="reasons">
            <div className="reason reason1 appear-left">
              <img src={calendar} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Control over your time</h5>
                <p className="reason-p">Choose the time you want to study that fits perfectly into your schedule</p>
              </div>
            </div>
            <div className="reason reason2 appear-right">
              <img src={book} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Study at your pace</h5>
                <p className="reason-p">Learn without the pressure to catch up, that comes with a classroom</p>
              </div>
            </div>
            <div className="reason reason3 appear-left">
              <img src={folder} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Large course portfolio</h5>
                <p className="reason-p">Get access to our huge database of quality elearning courses</p>
              </div>
            </div>
            <div className="reason reason4 appear-right">
              <img src={wallet} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Affordable pricing</h5>
                <p className="reason-p">Prices that fit well into anyone's budget</p>
              </div>
            </div>
          </div>
        </section>

        {similarCourses.length > 0 &&

          <section className="similar-section">
            <h1 className="section-title">Those who buy this course also go for</h1>

            <div className="similar-courses-container">
              {similarCourses.map(course => (
                <div className="similar-course" onClick={() => navigateCourse(course.short_title)}><p className="similar-p">{course.display_title}</p> <Link to="/"><img src={arrowrightCircle} alt="" className='arrow-right' loading='lazy' /></Link></div>
              ))}

            </div>
          </section>

        }

        <div className="button-divend">
          <Button variant="outlined" color="secondary" onClick={goContact} className='enquire-btn' id='istqb_ctfl'>Enquire Now</Button>
        </div>

        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default ISTQBCtfl