import { useState, useEffect } from 'react'
import "./Trainings.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import trainingScribble from "../../images/training-scribble.svg"
import certificate from "../../images/certificate.svg"
import trainingHero from "../../images/training-hero.png"
import trainingHeroBigSmall from "../../images/training-hero-bigsmall.png"
import trainingHeroSmall from "../../images/training-hero-small.png"
import { Link, useNavigate } from "react-router-dom"
import searchIcon from "../../images/search-icon.svg"
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import certification1 from "../../images/certification1.png"
import certification2 from "../../images/certification2.png"
import certification3 from "../../images/certification3.png"
import ReactPaginate from 'react-paginate';
import { Button } from '@mui/material'
import dotGrid from "../../images/dot-grid.png"
import bin from "../../images/bin.svg"
import binRed from "../../images/bin-red.svg"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import longArrowRight from "../../images/long-arrow-right.svg"
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios'
import searchNoMatch from "../../images/search-nomatch.png"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const Trainings = () => {

  // USESTATES

  const [courses, setCourses] = useState([])
  const [query, setQuery] = useState('')
  const [vendor, setVendor] = useState('')

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // INITIALIZATIONS

  const theme = createTheme({
    palette: {
      primary: {
        main: '#27AAE1'
      },

      secondary: {
        main: "#2E3092"
      },
      warning: {
        main: "#ffffff"
      }

    }
  })

  const navigate = useNavigate()



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




  // USEEFFECTS


  // SEARCH USEEFFECT
  useEffect(() => {
    if (query.length > 2) {
      axios.get(`https://www.ict-mentors.com/api/search?search=${query}`)
        .then(res => {
          setCourses(res.data)


          const searchDiv = document.querySelector('.trainings-container .courses-section .courses-nav .search-div .search-list')
          searchDiv.style.display = 'block'
        })
    } else if (query.length == 0) {
      setCourses([])
      const searchDiv = document.querySelector('.trainings-container .courses-section .courses-nav .search-div .search-list')
      searchDiv.style.display = 'none'
    }
  }, [query])

  useEffect(() => {
    const searchDiv = document.querySelector('.trainings-container .courses-section .courses-nav .search-div .search-list')
    document.onclick = function (e) {
      if (e.target.id !== 'hide') {
        searchDiv.style.display = 'none';
      }
    };



  }, [])


  /////////////////////////////////////////////////////////////////////////////////



  // INTERSECTION OBSERVERS

  useEffect(() => {
    const appearLefts = document.querySelectorAll('.appear-left')
    const appearLeftPaginate = document.querySelectorAll('.trainings-container .courses-section .paginate-ul.appear-left')
    const appearRights = document.querySelectorAll('.appear-right')
    const appearDowns = document.querySelectorAll('.appear-down')
    const appearUps = document.querySelectorAll('.appear-up')


    const appearLeftsFull = document.querySelectorAll('.appear-left-full')
    const appearRightsFull = document.querySelectorAll('.appear-right-full')
    const appearDownsFull = document.querySelectorAll('.appear-down-full')


    const appearLeftOptions = {
      threshold: 0.2,
    }

    const appearLeftObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftOptions)

    appearLefts.forEach(appearLeft => {
      appearLeftObserver.observe(appearLeft)
    })

    const appearLeftPaginateOptions = {
      threshold: 0.2,
    }

    const appearLeftPaginateObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftPaginateOptions)

    appearLeftPaginate.forEach(appearLeftPaginate => {
      appearLeftPaginateObserver.observe(appearLeftPaginate)
    })



    const appearRightOptions = {
      threshold: 0.2,
    }

    const appearRightObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightOptions)

    appearRights.forEach(appearRight => {
      appearRightObserver.observe(appearRight)
    })


    const appearDownOptions = {
      threshold: 0.2,
    }

    const appearDownObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownOptions)

    appearDowns.forEach(appearRight => {
      appearDownObserver.observe(appearRight)
    })




    const appearLeftFullOptions = {
      threshold: 0.2,
    }

    const appearLeftFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftFullOptions)

    appearLeftsFull.forEach(appearLeft => {
      appearLeftFullObserver.observe(appearLeft)
    })

    const appearRightFullOptions = {
      threshold: 0.2,
    }

    const appearRightFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightFullOptions)

    appearRightsFull.forEach(appearRight => {
      appearRightFullObserver.observe(appearRight)
    })

    const appearDownFullOptions = {
      threshold: 0.2,
    }

    const appearDownFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearDownsFull.forEach(appearRight => {
      appearDownFullObserver.observe(appearRight)
    })

    const appearUpOptions = {
      threshold: 0.2,
    }

    const appearUpObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearUps.forEach(appearUp => {
      appearUpObserver.observe(appearUp)
    })
  }, [])

  /////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // FUNCTIONS


  // Drop Vendor
  const dropVendorActive = () => {
    const dropdown = document.querySelector('.trainings-container .courses-section .courses-nav .courses-nav-ul .vendor-li .vendor-drop')
    dropdown.classList.add('active')
  }

  const dropVendorInactive = () => {
    const dropdown = document.querySelector('.trainings-container .courses-section .courses-nav .courses-nav-ul .vendor-li .vendor-drop.active')

    if (dropdown) {
      dropdown.classList.remove('active')
    }

  }

  ///////////////////////////////////////


  // Clear Search Hover

  const deleteHover = () => {
    const bin = document.querySelector('.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img.delete')
    const binRed = document.querySelector('.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img.delete-red')

    bin.classList.remove('active')

    if (binRed) {
      binRed.classList.add('active')
    }
  }

  const deleteUnHover = () => {
    const bin = document.querySelector('.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img.delete')
    const binRed = document.querySelector('.trainings-container .courses-section .courses-nav .courses-nav-ul .delete-li img.delete-red.active')

    if (binRed) {
      binRed.classList.remove('active')
    }

    bin.classList.add('active')
  }


  ////////////////////////////////////////


  // Clear Search Functionality

  const clearSearch = () => {
    setQuery('')
  }

  /////////////////////////////////////////


  // Navigate to contact us page

  const goContact = () => {
    navigate(`/contact_us?subject=${'Interest in Certifications'}`)
  }

  /////////////////////////////////////////


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////





  // PAGINATION STUFF

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 3

  const [currentCourses, setCurrentCourses] = useState([]);
  const [pageCount, setPageCount] = useState(0);



  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentCourses(courses.slice(itemOffset, endOffset));


    setPageCount(Math.ceil(courses.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, courses]);



  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % courses.length;

    setItemOffset(newOffset);
  };



  const dropCartegory1 = (cartegoryClass) => {
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory2.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }

  const dropCartegory2 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }



  const dropCartegory3 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }


  const dropCartegory4 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }


  const dropCartegory5 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }

  const dropCartegory6 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }

  const dropCartegory7 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }

  const dropCartegory8 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }

  const dropCartegory9 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }


  const dropCartegory10 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)
    const cartegory11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory2.classList.remove('active')
    cartegory11.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')


    const arrowUp11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-up`)
    const arrowDown11 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory11 .cartegory-top .arrow-down`)
    arrowUp11.classList.remove('active')
    arrowDown11.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }

  const dropCartegory11 = (cartegoryClass) => {
    const cartegory1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1`)
    const cartegory3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3`)
    const cartegory4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4`)
    const cartegory5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5`)
    const cartegory6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6`)
    const cartegory7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7`)
    const cartegory8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8`)
    const cartegory9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9`)
    const cartegory10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10`)
    const cartegory2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2`)

    cartegory1.classList.remove('active')
    cartegory3.classList.remove('active')
    cartegory4.classList.remove('active')
    cartegory5.classList.remove('active')
    cartegory6.classList.remove('active')
    cartegory7.classList.remove('active')
    cartegory8.classList.remove('active')
    cartegory9.classList.remove('active')
    cartegory10.classList.remove('active')
    cartegory2.classList.remove('active')


    const arrowUp1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-up`)
    const arrowDown1 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory1 .cartegory-top .arrow-down`)
    arrowUp1.classList.remove('active')
    arrowDown1.classList.add('active')

    const arrowUp3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-up`)
    const arrowDown3 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory3 .cartegory-top .arrow-down`)
    arrowUp3.classList.remove('active')
    arrowDown3.classList.add('active')


    const arrowUp4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-up`)
    const arrowDown4 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory4 .cartegory-top .arrow-down`)
    arrowUp4.classList.remove('active')
    arrowDown4.classList.add('active')


    const arrowUp5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-up`)
    const arrowDown5 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory5 .cartegory-top .arrow-down`)
    arrowUp5.classList.remove('active')
    arrowDown5.classList.add('active')


    const arrowUp6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-up`)
    const arrowDown6 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory6 .cartegory-top .arrow-down`)
    arrowUp6.classList.remove('active')
    arrowDown6.classList.add('active')


    const arrowUp7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-up`)
    const arrowDown7 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory7 .cartegory-top .arrow-down`)
    arrowUp7.classList.remove('active')
    arrowDown7.classList.add('active')


    const arrowUp8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-up`)
    const arrowDown8 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory8 .cartegory-top .arrow-down`)
    arrowUp8.classList.remove('active')
    arrowDown8.classList.add('active')


    const arrowUp9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-up`)
    const arrowDown9 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory9 .cartegory-top .arrow-down`)
    arrowUp9.classList.remove('active')
    arrowDown9.classList.add('active')


    const arrowUp10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-up`)
    const arrowDown10 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory10 .cartegory-top .arrow-down`)
    arrowUp10.classList.remove('active')
    arrowDown10.classList.add('active')


    const arrowUp2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-up`)
    const arrowDown2 = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.cartegory2 .cartegory-top .arrow-down`)
    arrowUp2.classList.remove('active')
    arrowDown2.classList.add('active')



    const cartegoryDropdown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass}`)
    const arrowUp = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-up`)
    const arrowDown = document.querySelector(`.trainings-container .courses-section .courses-wrapper .cartegory-div.${cartegoryClass} .cartegory-top .arrow-down`)


    if (cartegoryDropdown.classList.contains('active')) {
      cartegoryDropdown.classList.remove('active')
      arrowUp.classList.remove('active')
      arrowDown.classList.add('active')
    } else {
      cartegoryDropdown.classList.add('active')
      arrowUp.classList.add('active')
      arrowDown.classList.remove('active')
    }

  }



  const scrollSection = () => {
    const coursesNav = document.querySelector('.courses-nav')
    const bodyRect = document.body.getBoundingClientRect()
    const elementRect = coursesNav.getBoundingClientRect()

    const navContainer = document.querySelector('.nav2-container')

    let verticalPosition = elementRect.top - bodyRect.top
    let navHeight = navContainer.offsetHeight + 56;

    window.scrollTo(0, verticalPosition - navHeight)
  }






  /////////////////////////////////////////////////////////////////////////////////////////////////




  return (
    <ThemeProvider theme={theme}>
      <div className='trainings-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>

        <Helmet>
          <title>ICT Mentors | Certifications</title>
          <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
        </Helmet>

        <Nav2 />

        <section className="hero-section">
          <div className="hero-left">

            <h1 className="hero-title appear-left">Build the capacity you need to <span>execute</span>.
              <img src={trainingScribble} alt="" className="scribble" loading='lazy' />
            </h1>


            <p className="hero-p appear-up">Empower your team to accelerate value creation and delivery</p>

            <a className="checkout appear-right" onClick={scrollSection}>
              <span>Check our courses</span>
              <img src={certificate} alt="" loading='lazy' />
            </a>

          </div>

          <div className="hero-right">
            <img src={trainingHeroSmall} alt="" className="hero-small appear-right" loading='lazy' />
            <div className="hero-img-container"><img src={trainingHero} alt="" className="hero-big appear-right" loading='lazy' /></div>

            <div className="bottom-line"></div>
          </div>
        </section>

        <section className="courses-section">



          <div className="courses-nav" id='courses-nav'>
            <div className="search-div appear-left">
              <input type="text" className='search-input' placeholder='Search course...' value={query} onChange={e => setQuery(e.target.value)} />

              <img src={searchIcon} alt="" className='search-icon' loading='lazy' />

              <div className="search-list" id='hide'>

                {courses.length > 0 ?
                  <>

                    {courses.map((course, id) => (
                      <Link to={`/${course.short_title}`} key={id}>
                        <div className="single-search">
                          <h4 className="coursename">{course.display_title}</h4>
                          <img src={longArrowRight} alt="Visit page" className='arrow-right' loading='lazy' />
                        </div>
                      </Link>
                    ))}

                  </>
                  :

                  <div className="no-match">
                    <img src={searchNoMatch} alt="" className="no-match-img" loading='lazy' />
                    <p>No course matches your query...</p>
                  </div>

                }


              </div>
            </div>

            <ul className="courses-nav-ul appear-down">

              <Tooltip title="Clear Search...">
                <li className="delete-li" onMouseMove={deleteHover} onMouseOut={deleteUnHover} onClick={clearSearch}>
                  <span>clear search</span>

                  <img src={bin} alt="" className='delete active' loading='lazy' />
                  <img src={binRed} alt="" className='delete-red' loading='lazy' />

                </li>
              </Tooltip>
            </ul>
          </div>


          <div className="courses-wrapper appear-left">
            <div className="cartegory-div cartegory1" onClick={() => dropCartegory1('cartegory1')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">IT Service Management</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>
              </div>

              <div className="cartegory-courses">
                <Link to="/itil_foundation">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ITIL 4 Foundation</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/itil_cds">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ITIL 4 Specialist - CDS</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/itil_dsv">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ITIL 4 Specialist - DSV</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/itil_hvit">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ITIL 4 Specialist - HVIT</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/itil_dpi">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ITIL 4 Srategist - DPI</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/itil_dits">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ITIL 4 Strategic Leader - DITS</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>
              </div>

            </div>

            <div className="cartegory-div cartegory2" onClick={() => dropCartegory2('cartegory2')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Information Security</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>

              </div>

              <div className="cartegory-courses">
                <Link to="/cscu">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Certified Secure Computer User (CSCU)</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/ceh">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Certified Ethical Hacker</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/cisa">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Certified Information Systems Auditor</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/cism">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Certified Information Security Manager</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/iso_27001">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISO27001 Foundation</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/iso_27001_implementer">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISO27001 Implementer</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/iso_27001_auditor">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISO27001 Auditor</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>

            <div className="cartegory-div cartegory3" onClick={() => dropCartegory3('cartegory3')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">IT Governance</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>

              </div>

              <div className="cartegory-courses">
                <Link to="/cobit_foundation">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">COBIT5/2019 Foundation</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/cobit_implementer">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">COBIT5/2019 Implementer</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/cobit_assessor">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">COBIT5/2019 Assessor</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/cgeit">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Certified in Governance of Enterprise IT</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>
              </div>

            </div>

            <div className="cartegory-div cartegory4" onClick={() => dropCartegory4('cartegory4')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Program Management</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>
              </div>

              <div className="cartegory-courses">
                <Link to="/prince2_foundation">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">PRINCE2 Agile Foundation</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/prince2_practitioner">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">PRINCE2 Agile Practitioner</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>

            <div className="cartegory-div cartegory5" onClick={() => dropCartegory5('cartegory5')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Business Analysis/Application Dev</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>
              </div>

              <div className="cartegory-courses">
                <Link to="/cbap">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Certified Business Analysis Professional</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/devops">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">DevOps Fundamentals</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/devops_leadership">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">DevOps Leadership</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/scrum_1">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Scrum Master I</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/scrum_2">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Scrum Master II</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>
              </div>

            </div>

            <div className="cartegory-div cartegory6" onClick={() => dropCartegory6('cartegory6')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Enterprise Architecture</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>
              </div>

              <div className="cartegory-courses">
                <Link to="/togaf">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">TOGAF 9.0</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>

            <div className="cartegory-div cartegory7" onClick={() => dropCartegory7('cartegory7')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Business Continuity Management</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>
              </div>

              <div className="cartegory-courses">
                <Link to="/iso22301">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISO 22301 – (BCMS) Lead Auditor</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/iso22301_implementer">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISO 22301 – (BCMS) Lead Implementer</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>

            <div className="cartegory-div cartegory8" onClick={() => dropCartegory8('cartegory8')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Software Testing</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>
              </div>

              <div className="cartegory-courses">
                <Link to="/istqb_foundation">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISTQB® Certified Tester – Foundation Level (CTFL)</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/istqb_agile">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISTQB® Certified Tester Foundation Level – Agile Tester (CTFL- AT)</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/istqb_advanced">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">ISTQB Certified Tester Advanced Level – Test Manager (CTAL-TM)</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>

            <div className="cartegory-div cartegory9" onClick={() => dropCartegory9('cartegory9')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Risk Management</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>
              </div>

              <div className="cartegory-courses">
                <Link to="/crisc">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">CRISC (Certified in Risk and Information Systems Control)</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/iso31000">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">IS031000 Foundation</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>

            <div className="cartegory-div cartegory10" onClick={() => dropCartegory10('cartegory10')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Data Protection</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>

              </div>

              <div className="cartegory-courses">
                <Link to="/gdpr">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">GDPR — Certified Data Protection Officer</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>

            <div className="cartegory-div cartegory11" onClick={() => dropCartegory11('cartegory11')}>
              <div className="cartegory-top" >
                <h3 className="cartegory-title">Business Transformation</h3>

                <div className="arrows">
                  <KeyboardArrowDownRoundedIcon className='arrow arrow-down active' />
                  <KeyboardArrowUpRoundedIcon className='arrow arrow-up' />
                </div>

              </div>

              <div className="cartegory-courses">
                <Link to="/agile_fundamentals">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Agile Fundamentals</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/csm">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Certified Scrum Master</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/leading_safe">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Leading SAFe</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

                <Link to="/implementing_safe">
                  <div className="single-cartegory-course">
                    <h4 className="course-title">Implementig SAFe</h4>

                    <img src={longArrowRight} alt="" className="arrow-right" loading='lazy' />
                  </div>
                </Link>

              </div>

            </div>
          </div>


        </section>

        <section className="help-section">
          <h1 className="help-h1 appear-left">Need help choosing the right course?</h1>

          <p className="help-p appear-right">Give us a call on +254 741 184039 or enquire</p>

          <Button variant='contained' color='secondary' onClick={goContact} className='enquire-btn appear-up' id='training-page-enquire'>Enquire Now</Button>
        </section>


        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default Trainings