import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/digital-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/system-migration2-blog.jpg"
import img1Small from "../../images/system-migration2-blog-small.jpg"
import img2 from "../../images/vulnerability2-blog.jpg"
import img2Small from "../../images/vulnerability2-blog-small.jpg"
import img3 from "../../images/wedo-img.jpg"
import img3Small from "../../images/wedo-img-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const DigitalTransformation = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Digital Transformation"})
        navigate('/contact_us?subject=Digital Transformation Experience')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Digital Transformation Experience</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Digital Transformation Experience</h1>
                            <p className="solution-p appear-right">This is an intensive workshop bringing together IT and business champions. It focuses on an honest and aligned team evaluation of the critical key pillars underpinning digital transformation (includes data, security, applications, governance and others). It looks at their current maturity and the desired maturity (against global best practices and the business vision and strategy). The sub-teams build a roadmap to transform the current to the desired level. The outcome is a roadmap built and owned by the team (IT and business) and leads to significant transformation upon execution.</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Clarity of Vision</strong> - DTE workshops help business leaders and employees gain a clear understanding of what digital transformation means for their organization. It can define the vision, goals, and objectives of the transformation journey.</li>
                                <li className="values-li appear-right"><strong>Alignment</strong> - Workshops facilitate alignment among different teams and departments within the organization. By bringing together various stakeholders, a DTE workshop can ensure that everyone is on the same page regarding the digital transformation strategy.</li>
                                <li className="values-li appear-left"><strong>Cultural Change</strong> - Digital transformation often requires a cultural shift within an organization. DTE workshops can address cultural challenges, create a sense of urgency, and promote a culture of innovation and adaptability.</li>
                                <li className="values-li appear-right"><strong>Identification of Key Initiatives</strong> - Through the workshop, organizations can identify key digital initiatives that will drive the transformation. These initiatives can include implementing new technologies, improving processes, or enhancing customer experiences.</li>
                                <li className="values-li appear-right"><strong>Risk Assessment and Mitigation</strong> - DTE workshops can help identify potential risks and challenges associated with digital transformation and develop strategies to mitigate them. This includes cybersecurity concerns, data privacy issues, and organizational resistance.</li>
                                <li className="values-li appear-right"><strong>Customer-Centric Approach</strong> - Digital transformation is often centered around improving the customer experience. DTE workshops can help businesses identify ways to use digital technologies to better understand and meet customer needs using technology.</li>
                                <li className="values-li appear-right"><strong>Efficiency and Productivity</strong> - : Businesses can use digital tools and automation to improve operational efficiency and productivity. DTE workshops can identify areas where these improvements are most needed.</li>
                                <li className="values-li appear-right"><strong>Data-Driven Decision Making</strong> - Digital transformation often involves leveraging data and analytics to make informed decisions. Workshops can help businesses determine how to collect, analyze, and use data to their advantage.</li>
                                <li className="values-li appear-right"><strong>Competitive Advantage</strong> - Embracing digital transformation can give a business a competitive edge in the market. Workshops can help organizations identify opportunities to differentiate themselves from competitors.</li>
                                <li className="values-li appear-right"><strong>Scalability and Growth</strong> - Digital technologies can enable businesses to scale their operations and reach new markets. DTE workshops can help map out strategies for growth and expansion.</li>
                                <li className="values-li appear-right"><strong>Cost Savings</strong> - By streamlining processes and reducing manual work, digital transformation can lead to cost savings over time. A DTE workshop can help identify areas where cost reductions are possible.</li>
                                <li className="values-li appear-right"><strong>Agility and Adaptability</strong> - Digital transformation allows organizations to become more agile and adaptable in response to changing market conditions. Workshops can help businesses develop strategies for staying flexible and responsive.</li>
                                <li className="values-li appear-right"><strong>Employee Engagement</strong> - Engaging employees in the digital transformation process is crucial for success. DTE workshops can involve employees in shaping the transformation strategy and make them feel more invested in its success.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Digital Transformation' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>

                            <div className="square img6 secondary"></div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default DigitalTransformation