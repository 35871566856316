import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/businessprocess-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/soc.jpg"
import img1Small from "../../images/soc-small.jpg"
import img2 from "../../images/digital-transformation.jpg"
import img2Small from "../../images/digital-transformation-small.jpg"
import img3 from "../../images/digitalvsit-transformation.jpg"
import img3Small from "../../images/digitalvsit-transformation-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const BusinessProcess = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Business Process"})
        navigate('/contact_us?subject=Business Process Re-engineering')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Business Process Re-engineering</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Business Process Re-engineering</h1>
                            <p className="solution-p appear-right">With ICT Mentors Solutions as your preferred business partner, we walk with you in transforming and re-engineering your business processes to drive efficiencies, customer satisfaction and realization of IT value. </p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Improved Efficiency</strong> - BPR aims to streamline and optimize processes. By reengineering workflows, businesses can eliminate bottlenecks, reduce unnecessary steps, and automate tasks. This leads to increased efficiency and productivity.</li>
                                <li className="values-li appear-right"><strong>Cost Reduction</strong> - By eliminating redundant tasks and automating processes, BPR can lead to significant cost savings. This can include savings in labor costs, materials, and other resources.</li>
                                <li className="values-li appear-left"><strong>Enhanced Quality</strong> - BPR focuses on improving the quality of processes. When processes are reengineered, they are designed to be more consistent and error-free. This can lead to higher-quality products or services.</li>
                                <li className="values-li appear-right"><strong>Increased Agility</strong> - BPR allows businesses to adapt to changes in the market more quickly. By simplifying processes and reducing bureaucracy, organizations can respond faster to customer demands and market trends.</li>
                                <li className="values-li appear-left"><strong>Better Customer Service</strong> - Streamlined processes and quicker response times can lead to improved customer service. BPR can help businesses meet customer needs more effectively, leading to higher customer satisfaction.</li>
                                <li className="values-li appear-right"><strong>Competitive Advantage</strong> - BPR can give a business a competitive edge. When a company can deliver products or services more efficiently and cost-effectively, it can outperform competitors in the market.</li>
                                <li className="values-li appear-left"><strong>Innovation</strong> - BPR encourages businesses to rethink their processes from the ground up. This often leads to innovative solutions and new ways of doing things. It can foster a culture of continuous improvement and innovation within the organization.</li>
                                <li className="values-li appear-right"><strong>Employee Satisfaction</strong> - Simplified and more efficient processes can reduce the burden on employees, making their jobs easier and more satisfying. This can lead to higher morale and reduced turnover.</li>
                                <li className="values-li appear-left"><strong>Better Decision-Making</strong> - BPR often involves gathering and analyzing data about processes. This can provide better insights for decision-making and help businesses make informed choices about resource allocation and strategy.</li>
                                <li className="values-li appear-right"><strong>Compliance and Risk Management</strong> - BPR can help businesses ensure that their processes are compliant with relevant regulations and industry standards. It can also reduce the risk of errors and non-compliance.</li>
                                <li className="values-li appear-left"><strong>Faster Time to Market</strong> - Streamlined processes can speed up product development and time to market. This is especially important in industries where rapid innovation is crucial.</li>
                                <li className="values-li appear-right"><strong>Improved Communication</strong> - BPR often involves better communication and coordination among different parts of an organization. This can break down silos and improve cross-functional collaboration.</li>
                                <li className="values-li appear-left"><strong>Measurable Results</strong> - BPR typically involves setting clear metrics and Key Performance Indicators (KPIs) to measure the success of process improvements. This allows businesses to track and demonstrate the value of BPR initiatives.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Business Process' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default BusinessProcess