import { useState, useEffect } from 'react'
import "./SOC.css"
import Nav3 from '../nav3/Nav3'
import Footer from '../footer/Footer'
import cyberAbstract from "../../images/cyber-abstract.png"
import blurCircle from "../../images/blur-circle.png"
import { Button, TextField } from '@mui/material'
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import socPadlock from "../../images/soc-padlock.png"
import socSeriousFace from "../../images/soc-serious-face.png"
import sampleSocReport from "../../images/sample-soc-report.png"
import waves from "../../images/waves.png"
import binoculars from "../../images/binoculars.png"
import checklist from "../../images/checklist.png"
import fastTime from "../../images/fast-time.png"
import calendar from "../../images/calendar.svg"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const SOC = () => {

  // USESTATES

  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // INITIALIZATIONS

  const theme = createTheme({
    palette: {
      primary: {
        main: '#27AAE1'
      },

      secondary: {
        main: "#2E3092"
      },
      warning: {
        main: "#ffffff"
      }

    }
  })



  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // USEEFFECTS

  // INTERSECTION OBSERVERS

  useEffect(() => {
    const appearLefts = document.querySelectorAll('.appear-left')
    const appearRights = document.querySelectorAll('.appear-right')
    const appearDowns = document.querySelectorAll('.appear-down')
    const appearUps = document.querySelectorAll('.appear-up')


    const appearLeftsFull = document.querySelectorAll('.appear-left-full')
    const appearRightsFull = document.querySelectorAll('.appear-right-full')
    const appearDownsFull = document.querySelectorAll('.appear-down-full')


    const appearLeftOptions = {
      threshold: 0.2,
    }

    const appearLeftObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftOptions)

    appearLefts.forEach(appearLeft => {
      appearLeftObserver.observe(appearLeft)
    })




    const appearRightOptions = {
      threshold: 0.2,
    }

    const appearRightObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightOptions)

    appearRights.forEach(appearRight => {
      appearRightObserver.observe(appearRight)
    })


    const appearDownOptions = {
      threshold: 0.2,
    }

    const appearDownObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownOptions)

    appearDowns.forEach(appearRight => {
      appearDownObserver.observe(appearRight)
    })




    const appearLeftFullOptions = {
      threshold: 0.2,
    }

    const appearLeftFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftFullOptions)

    appearLeftsFull.forEach(appearLeft => {
      appearLeftFullObserver.observe(appearLeft)
    })

    const appearRightFullOptions = {
      threshold: 0.2,
    }

    const appearRightFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightFullOptions)

    appearRightsFull.forEach(appearRight => {
      appearRightFullObserver.observe(appearRight)
    })

    const appearDownFullOptions = {
      threshold: 0.2,
    }

    const appearDownFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearDownsFull.forEach(appearRight => {
      appearDownFullObserver.observe(appearRight)
    })

    const appearUpOptions = {
      threshold: 0.2,
    }

    const appearUpObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearUps.forEach(appearUp => {
      appearUpObserver.observe(appearUp)
    })
  }, [])

  /////////////////////////////////////////////////////////////////



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  // FUNCTIONS

  const openCalendly = () => {

    window.dataLayer.push({event: 'book_demo'})

    ReactGa.event("calendly_book_demo")

    const calendlyContainer = document.querySelector('.soc-container .book-section .book-right .calendly-container')

    calendlyContainer.classList.toggle('active')


    if (calendlyContainer.classList.contains('active')) {
      setTimeout(() => {
        scrollSection()
      }, 500)
    }
  }

  const scrollSection = () => {
    const calendlyContainer = document.querySelector('.soc-container .book-section .book-right .calendly-container')
    const bodyRect = document.body.getBoundingClientRect()
    const elementRect = calendlyContainer.getBoundingClientRect()

    const navContainer = document.querySelector('.nav3-container')

    let verticalPosition = elementRect.top - bodyRect.top
    let navHeight = navContainer.offsetHeight;

    window.scrollTo(0, verticalPosition - navHeight)
  }

  const scrollBookSection = () => {
    const bookContainer = document.querySelector('.soc-container .book-section')
    const bodyRect = document.body.getBoundingClientRect()
    const elementRect = bookContainer.getBoundingClientRect()

    const navContainer = document.querySelector('.nav3-container')

    let verticalPosition = elementRect.top - bodyRect.top
    let navHeight = navContainer.offsetHeight + 50;

    window.scrollTo(0, verticalPosition - navHeight)
  }




  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  return (
    <ThemeProvider theme={theme}>
      <div className='soc-container'>

        <Helmet>
          <title>Security Operations Centre (SOC)</title>
          <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
        </Helmet>

        <Nav3 />

        <section className="hero-section">
          <div className="hero-left">
            <img src={blurCircle} alt="" className="blur-circle appear-down" loading='lazy' />

            <h1 className="hero-title appear-left">Sophisticated Security Made Simple</h1>
            <p className="hero-p appear-right">Let us take away the hustle of monitoring, and mitigating security threats</p>
            <Button variant='outlined' color='primary' className='demo-btn' onClick={scrollBookSection}>
              <span>Book Demo</span>
              <LockRoundedIcon className='lock-icon' />
            </Button>
          </div>

          <div className="hero-right">
            <img src={cyberAbstract} alt="" className='cyber-abstract appear-up' loading='lazy' />
            <img src={socPadlock} alt="" className="soc-padlock appear-right" loading='lazy' />
          </div>
        </section>

        <section className="ensure-section">
          <img src={socSeriousFace} alt="" className="soc-serious-face" loading='lazy' />
          <h2 className="ensure-h2">Mr. SOC WILL ENSURE YOU’RE SECURED, 24/7 THREE SIXTY FIVE</h2>
        </section>

        <section className="dream-section">
          <div className="dream-left">
            <div className="dream dream1 appear-left">
              <h3 className="dream-h3">Reduced Risk and Exposure</h3>
              <p className="dream-p">SOC implementation reduces risk, detects threats, and prevents data breaches, safeguarding finances and reputation.</p>
            </div>

            <div className="dream dream2 appear-right">
              <h3 className="dream-h3">Proactive Threat Hunting</h3>
              <p className="dream-p">Our dedicated SOC proactively hunts and neutralizes threats to boost security and minimize incidents.</p>
            </div>

            <div className="dream dream3 appear-down">
              <h3 className="dream-h3">Increased Stakeholder Confidence</h3>
              <p className="dream-p">Implementing a SOC signals security commitment to stakeholders, boosting trust, relationships, and reputation.</p>
            </div>
          </div>

          <div className="dream-right appear-right">
            <img src={sampleSocReport} alt="" className="soc-report-img" loading='lazy' />
            <img src={waves} alt="" className="waves-img" loading='lazy' />
          </div>
        </section>

        <section className="capabilities-section">
          <h1 className="section-title appear-down">Capabilities</h1>

          <div className="capabilities-wrapper">

            <div className="capability capability1 appear-left">
              <h1 className="watermark watermark1">Reduced Risk and Exposure</h1>
              <img src={calendar} alt="" className="cap-img calendar" loading='lazy' />
              <h3 className="capability-h3">Log Management</h3>
              <p className="capability-p">SOC centralizes logs and security data, coming from diverse sources, including servers, networks, and apps.
              </p>
            </div>

            <div className="capability capability1 appear-right">
              <h1 className="watermark watermark2">Compliance with security requirements</h1>
              <img src={binoculars} alt="" className="cap-img binoculars" loading='lazy' />
              <h3 className="capability-h3">Real-time monitoring and analysis</h3>
              <p className="capability-p">Of security events to detect suspicious activities and potential threats as they occur.</p>
            </div>

            <div className="capability capability1 appear-down">
              <h1 className="watermark watermark3">Faster incident resolution</h1>
              <img src={fastTime} alt="" className="cap-img clock" loading='lazy' />
              <h3 className="capability-h3">Generation of alerts and notifications</h3>
              <p className="capability-p">Based on predefined rules and thresholds to notify security personnel of potential security incidents.</p>
            </div>

            <div className="capability capability1 appear-right">
              <img src={checklist} alt="" className="cap-img checklist" loading='lazy' />
              <h3 className="capability-h3">Compliance Management</h3>
              <p className="capability-p">Tools for monitoring and reporting on compliance with industry regulations and standards</p>
            </div>
          </div>
        </section>

        <section className="book-section">
          <div className="book-left">
            <h1 className="section-title appear-down">Book a Demo</h1>

            <div className="book-p appear-left">We’re glad to take you through a personalized demonstration of our SOC service, for you to clearly understand how it works, and how your organization stands to benefit.</div>

          </div>

          <div className="book-right appear-down">
            <Button variant='contained' color='secondary' onClick={openCalendly} className='book-btn'>Book Demo</Button>

            <div className="calendly-container">
              <InlineWidget url="https://calendly.com/ict-mentors/soc-demo"/>
            </div>

          </div>
        </section>

        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default SOC