import React from 'react'
import "./Footer.css"
import rightArrowFilled from "../../images/right-arrow-filled.svg"
import { Link } from 'react-router-dom'
import location from "../../images/location.svg"
import phone from "../../images/phone.svg"
import mail from "../../images/mail.svg"
import footerImg from "../../images/footer-img.png"
import facebook from "../../images/facebook.svg"
import instagram from "../../images/instagram.svg"
import twitter from "../../images/twitter.svg"
import linkedin from "../../images/linkedin.svg"
import ReactGa from "react-ga4"



const Footer = () => {
    return (
        <div className='footer-container'>
            <div className="footer-wrapper">
                <div className="about-ict">
                    <h3 className="footer-head">ICT <span className="mentors-span">MENTORS</span> <span className="solutions-span">SOLUTIONS</span></h3>
                    <p className="about-p">Partnering for Digital Transformation</p>
                </div>

                <div className="services-div">
                    <h3 className="footer-title">OUR SERVICES</h3>

                    <div className="services-wrapper">
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/strategy_formulation" className="service-link">IT Strategy Formulation</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/soc" className="service-link">Security Operations Center (SOC)</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/global_frameworks" className="service-link">Global Frameworks Implementation</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/baseline_maturity" className="service-link">Baseline and Maturity Assessment</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/service_desk" className="service-link">Service Desk Deployment and Management</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/business_continuity" className="service-link">Business Continuity and Disaster Recovery</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/business_process" className="service-link">Business Process Re-engineering</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/digital_transformation" className="service-link">Digital Transformation Experience</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/ict_audits" className="service-link">ICT Audits</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/iso_20000" className="service-link">ISO 20000</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/project_advisory" className="service-link">Project Advisory Services</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/agile_coaching" className="service-link">Agile Coaching</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/vulnerability" className="service-link">Vulnerability Assessment</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/pentest" className="service-link">Penetration Testing</Link>
                        </div>
                        <div className="single-service">
                            <img src={rightArrowFilled} alt="" loading='lazy' />
                            <Link to="/implement_iso27001" className="service-link">ISO 27001</Link>
                        </div>
                    </div>


                </div>

                <div className="reach-div">
                    <h3 className="footer-title">REACH US</h3>

                    <div className="reach-wrapper">
                        <div className="single-contact">
                            <img src={location} alt="location" className='location' loading='lazy'/>
                            <p className="contact-p">3rd floor, AAYMCA’s Building State House Crescent, Nairobi</p>
                        </div>
                        <div className="single-contact">
                            <img src={phone} alt="phone" className='phone' loading='lazy'/>
                            <p className="contact-p">+254 741 184039</p>
                        </div>
                        <div className="single-contact">
                            <img src={mail} alt="phone" className='mail' loading='lazy'/>
                            <p className="contact-p">info@ict-mentors.com</p>
                        </div>
                    </div>

                </div>

                <div className="extras-div">
                    <div className="single-extra">
                        <img src={rightArrowFilled} alt="" loading='lazy' />
                        <Link className="extra-p" to="/trainings">View Certification Courses</Link>
                    </div>
                    <div className="single-extra">
                        <img src={rightArrowFilled} alt="" loading='lazy' />
                        <Link className="extra-p" to="/blog">Visit our Blog</Link>
                    </div>
                </div>

                
            </div>

            <div className="footer-ribbon">
                <img src={footerImg} alt="" className="footer-img" loading='lazy' />
                <p className="copyright">| Copyright ©2024 ICT Mentors Solutions Ltd. All rights reserved |</p>

                <div className="socials">
                    <a href="https://www.facebook.com/ICTMentorsLtd" target='__blank' className='go-social' id='go-fb' onClick={() => ReactGa.event("go_socials", {social_platform: "Facebook"})}><img src={facebook} alt="facebook" loading='lazy' /></a>
                    <a href="#" className='go-social' id='go-ig' onClick={() => ReactGa.event("go_socials", {social_platform: "Instagram"})}><img src={instagram} alt="instagram" loading='lazy' /></a>
                    <a href="https://twitter.com/ICTMSL" target='__blank' className='go-social' id='go-twitter' onClick={() => ReactGa.event("go_socials", {social_platform: "Twitter"})}><img src={twitter} alt="twitter" loading='lazy' /></a>
                    <a href="https://www.linkedin.com/company/ict-mentors-solutions" target='__blank' className='go-social' id='go-linkedin' onClick={() => ReactGa.event("go_socials", {social_platform: "Linkedin"})}><img src={linkedin} alt="linkedin" loading='lazy' /></a>
                </div>
            </div>
        </div>
    )
}

export default Footer