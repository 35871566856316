import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/agile-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/phone-booth.png"
import img1Small from "../../images/phone-booth-small.png"
import img2 from "../../images/blackman.jpg"
import img2Small from "../../images/blackman-small.jpg"
import img3 from "../../images/hero-woman.png"
import img3Small from "../../images/hero-woman-small.png"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const AgileCoaching = () => {

    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const goContact = () => {
        console.log("Go Enquiry Once or twice")
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Agile Coaching"})
        navigate('/contact_us?subject=Agile Coaching')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Agile Coaching</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Agile Coaching</h1>
                            <p className="solution-p appear-right">ICT Mentors Solutions will partner with organizations to imagine and drive strategies that will deliver accelerated innovation and time to market. Our adaptive portfolio management and seamless delivery expertise, enabled bythe top leadership in the organization and talent and leveraged through competitive intelligence and technical capability will ensure delivery of high impact results.</p>
                            <p className="solution-p appear-right">ICT Mentors Solutions will work with organizations to adopt agile practices and methods while embracing agile values and mindsets. Our goal is to foster more effective, transparent, and cohesive teams, and to enable better outcomes, solutions, and services for our customers.</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Improved Flexibility and Adaptability</strong> - Agile methodologies enable businesses to respond quickly to changing market conditions, customer preferences, and emerging opportunities. This adaptability is especially valuable in fast-paced industries.</li>
                                <li className="values-li appear-right"><strong>Faster Time-to-Market</strong> - Agile development and project management practices emphasize delivering working products or features incrementally and frequently. This results in shorter development cycles and quicker time-to-market for products and services.</li>
                                <li className="values-li appear-left"><strong>Enhanced Customer Satisfaction</strong> - Agile places a strong emphasis on customer collaboration and feedback. By involving customers throughout the development process, businesses can better meet customer needs and expectations, leading to higher satisfaction levels.</li>
                                <li className="values-li appear-right"><strong>Higher Quality Deliverables</strong> - Agile practices, such as continuous testing and integration, help identify and address issues early in the development process. This leads to higher-quality deliverables and fewer defects in the final product.</li>
                                <li className="values-li appear-left"><strong>Increased Transparency</strong> - Agile methodologies promote transparency through practices like daily stand-up meetings, regular demonstrations, and open communication channels. This transparency helps stakeholders stay informed about project progress and potential challenges.</li>
                                <li className="values-li appear-right"><strong>Better Risk Management</strong> - Agile encourages risk identification and mitigation throughout the project. By addressing risks early and continuously, businesses can minimize the impact of unforeseen issues on project outcomes.</li>
                                <li className="values-li appear-left"><strong>Empowered Teams</strong> - Agile teams are typically self-organizing and cross-functional, allowing team members to make decisions and collaborate effectively. This empowerment can boost team morale and productivity.</li>
                                <li className="values-li appear-right"><strong>Optimized Resource Allocation</strong> - Agile practices like prioritization and backlog management help businesses allocate resources to the most valuable tasks and features, maximizing return on investment.</li>
                                <li className="values-li appear-left"><strong>Continuous Improvement Culture</strong> - Agile promotes a culture of continuous improvement through retrospectives and feedback loops. Teams and organizations can learn from their experiences and make iterative improvements to processes and products.</li>
                                <li className="values-li appear-right"><strong>Increased Productivity</strong> - Agile practices emphasize minimizing waste, eliminating bottlenecks, and focusing on value-added activities. This can lead to increased productivity and efficiency across the organization.</li>
                                <li className="values-li appear-left"><strong>Enhanced Communication</strong> - Agile encourages frequent communication among team members, stakeholders, and customers. This reduces misunderstandings and ensures that everyone is on the same page.</li>
                                <li className="values-li appear-right"><strong>Better Alignment with</strong> Business Goals - Agile practices help ensure that projects and initiatives are aligned with the strategic goals of the business. This alignment increases the likelihood of achieving desired outcomes.</li>
                                <li className="values-li appear-left"><strong>Competitive Advantage</strong> - Businesses that adopt Agile practices can gain a competitive edge by being more responsive to customer needs and market changes. They can innovate and deliver value more rapidly than competitors using traditional methods.</li>
                                <li className="values-li appear-right"><strong>Employee Engagement</strong> - Agile methodologies often foster a sense of ownership and purpose among team members. Engaged employees are more motivated and committed to the success of the organization.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Agile Coaching' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>

                            <div className="square img6 secondary"></div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default AgileCoaching