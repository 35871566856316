import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/businesscontinuity-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/people-office2.jpg"
import img1Small from "../../images/people-office2-small.jpg"
import img2 from "../../images/sports.jpg"
import img2Small from "../../images/sports-small.jpg"
import img3 from "../../images/system3.jpg"
import img3Small from "../../images/system3-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const BusinessContinuity = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Business Continuity"})
        navigate('/contact_us?subject=Business Continuity and Disaster Recovery')
    }

    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Business Continuity & Disaster Recovery</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Business Continuity & Disaster Recovery</h1>
                            <p className="solution-p appear-right">Every year, management of companies report that they are not confident in their organization's ability to recover following a disaster. An effective business continuity and disaster recovery planning needs to be integrated with the day-to-day operations. ICT Mentors Solutions will work with you to ensure you have an effective BCDRP in place. We will also walk with you through the ISO 22301 certification journey. </p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Risk Mitigation</strong> - ISO 22301 helps businesses identify and assess potential risks and vulnerabilities. By implementing preventive measures and developing a robust continuity plan, organizations can reduce the impact of disasters and unforeseen events, thus minimizing financial and operational losses.</li>
                                <li className="values-li appear-right"><strong>Business Resilience</strong> - ISO 22301 promotes the development of a comprehensive business continuity management system (BCMS). This enhances the organization's ability to withstand disruptions, ensuring the continuity of critical business operations during and after adverse events.</li>
                                <li className="values-li appear-left"><strong>Enhanced Reputation</strong> - A well-structured BCMS demonstrates commitment to resilience and preparedness. This can boost the organization's reputation and build trust among customers, partners, and stakeholders, which is crucial in today's competitive business landscape.</li>
                                <li className="values-li appear-right"><strong>Regulatory Compliance</strong> - ISO 22301 helps companies align with regulatory requirements and industry standards related to business continuity and disaster recovery. Compliance can help avoid legal issues and penalties while ensuring that the organization meets its obligations.</li>
                                <li className="values-li appear-left"><strong>Cost Reduction</strong> -By proactively identifying risks and addressing them, businesses can reduce the financial impact of disruptions. This can include lower insurance premiums, decreased downtime-related costs, and more efficient resource allocation.</li>
                                <li className="values-li appear-right"><strong>Improved Recovery Time</strong> - ISO 22301 emphasizes the need for well-defined recovery strategies and plans. This leads to faster recovery times, minimizing the disruption to operations and reducing the associated financial losses.</li>
                                <li className="values-li appear-left"><strong>Competitive Advantage</strong> - Businesses that have a certified BCMS may gain a competitive advantage, as it can become a differentiator in the eyes of customers and partners who prioritize business continuity when choosing vendors or collaborators.</li>
                                <li className="values-li appear-right"><strong>Improved Customer Confidence</strong> - Knowing that a business has a robust continuity plan in place can instill confidence in customers. They are more likely to continue doing business with an organization that can assure them of minimal disruption in services or products.</li>
                                <li className="values-li appear-left"><strong>Improved Stakeholder Confidence</strong> - Investors, shareholders, and other stakeholders are more likely to invest in or support a business with a strong BCMS, as it demonstrates a commitment to long-term sustainability.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Business Continuity' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default BusinessContinuity