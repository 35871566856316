import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/project-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/vulnerability3-blog.jpg"
import img1Small from "../../images/vulnerability3-blog-small.jpg"
import img2 from "../../images/blog3.jpg"
import img2Small from "../../images/blog3-small.jpg"
import img3 from "../../images/digitalvsit-transformation.jpg"
import img3Small from "../../images/digitalvsit-transformation-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"


const ProjectAdvisory = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Project Advisory"})
        navigate('/contact_us?subject=Project Advisory Services')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Project Advisory Services</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Project Advisory Services</h1>
                            <p className="solution-p appear-right">At ICT Mentors Solutions we undertake Project Advisory Services to our clients including business process analysis, financial analysis, performance assessment, project management and risk assessment for the assigned project to ensure cost, time, quality and scope are achieved in a changing organization’s environment.</p>
                            <p className="solution-p appear-right">We manage our projects in alignment to PRINCE2 Agile, providing our clients with the required agility.</p>
                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Risk Mitigation</strong> - Project advisors can identify potential risks and challenges early in the implementation process. By doing so, they help businesses take proactive measures to mitigate these risks, reducing the likelihood of costly delays or failures.</li>
                                <li className="values-li appear-right"><strong>Cost Control</strong> - Advisors can help businesses establish and adhere to budgets, ensuring that projects stay on track financially. This can lead to cost savings and prevent overruns.</li>
                                <li className="values-li appear-left"><strong>Efficiency Improvement</strong> - Project advisors often have expertise in best practices and industry standards. They can help streamline processes and workflows, leading to improved efficiency and productivity.</li>
                                <li className="values-li appear-right"><strong>Quality Assurance</strong> - Advisors can monitor the quality of work throughout the implementation, ensuring that the end product meets the required standards and specifications. This can enhance the company's reputation and customer satisfaction.</li>
                                <li className="values-li appear-left"><strong>Scope Management</strong> - Advisors can help manage project scope, preventing scope creep (the tendency for project requirements to expand beyond the initial plan). This keeps projects focused and ensures they stay on schedule.</li>
                                <li className="values-li appear-right"><strong>Stakeholder Communication</strong> - Effective communication with stakeholders is crucial during project implementations. Advisors can facilitate communication between different teams, ensuring that everyone is on the same page and aligned with project objectives.</li>
                                <li className="values-li appear-left"><strong>Change Management</strong> - Implementing new systems or processes often involves changes for employees. Advisors can assist in change management strategies to minimize resistance and facilitate a smoother transition.</li>
                                <li className="values-li appear-right"><strong>Knowledge Transfer</strong> - Advisors can help transfer knowledge and skills to internal teams, ensuring that employees are well-prepared to manage and maintain the implemented solution once the project is complete.</li>
                                <li className="values-li appear-left"><strong>ROI Optimization</strong> - Project advisors can help businesses maximize the return on investment (ROI) by ensuring that the project's goals align with the company's strategic objectives.</li>
                                <li className="values-li appear-right"><strong>Timely Delivery</strong> - Advisors can help set realistic timelines and milestones, ensuring that projects are delivered on schedule. This can be particularly important for businesses facing market pressures or regulatory deadlines.</li>
                                <li className="values-li appear-left"><strong>Adaptation to Change</strong> - In today's rapidly evolving business landscape, adaptability is crucial. Project advisors can help businesses stay agile and adjust their strategies as needed to respond to changing market conditions.</li>
                                <li className="values-li appear-right"><strong>Competitive Advantage</strong> - Successfully implemented projects can provide a competitive advantage by improving product offerings, customer service, or operational efficiency.</li>
                                <li className="values-li appear-left"><strong>Customer Satisfaction</strong> - Ultimately, the successful implementation of projects often leads to improved customer satisfaction, which can result in increased loyalty and revenue.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Project Advisory' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default ProjectAdvisory