import { useState, useEffect } from 'react'
import "./BlogPage.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import blog3Img from "../../images/blog3.jpg"
import blog3ImgSmall from "../../images/blog3-small.png"
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useNavigate } from 'react-router-dom';
import dotGrid from "../../images/dot-grid.png"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useDispatch } from "react-redux"
import axios from 'axios';
import TextField from '@mui/material/TextField';
import systemMigration2Blog from "../../images/system-migration2-blog.jpg"
import systemMigration2BlogSmall from "../../images/system-migration2-blog-small.jpg"
import systemMigration3Blog from "../../images/system3.jpg"
import systemMigration3BlogSmall from "../../images/system3-small.jpg"
import digitalTransformation from "../../images/digital-transformation.jpg"
import digitalTransformationSmall from "../../images/digital-transformation-small.jpg"
import digitalvsitTransformation from "../../images/digitalvsit-transformation.jpg"
import digitalvsitTransformationSmall from "../../images/digitalvsit-transformation-small.jpg"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const DTEBlog = () => {

    // USESTATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [ads, setAds] = useState([])


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');


    // USEEFFECTS

    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')


        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    // LOAD BLOG ADS

    useEffect(() => {
        axios.get('https://www.ict-mentors.com/api/get_blog_ads')
            .then(res => {
                setAds(res.data)
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }, [])

    ////////////////////////////////////////////////////////////////

    // LOAD DISAPPEAR USEEFFECT

    useEffect(() => {
        const loader = document.querySelector('.loader-wrapper')

        function vanish() {
            loader.classList.add('disappear')
        }


        window.addEventListener('load', vanish)
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.blog-p')
        const appearLeftImg = document.querySelectorAll('.img-container')
        const appearLeftAuthor = document.querySelectorAll('.author-date')
        const appearRightAd = document.querySelectorAll('.ad')
        const appearRights = document.querySelectorAll('.section-title')
        const appearRightSocials = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .socials')
        const appearRightForm = document.querySelectorAll('.blogpage-container .blog-right .form-container form')
        const appearLeftShare = document.querySelectorAll('.blogpage-container .share-div')
        const appearDowns = document.querySelectorAll('.blogpage-container .blog-ul .blog-li')
        const appearUps = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .blog-title')



        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftImgOptions = {
            threshold: 0.2,
        }

        const appearLeftImgObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftImgObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftImgOptions)

        appearLeftImg.forEach(appearLeft => {
            appearLeftImgObserver.observe(appearLeft)
        })

        const appearLeftAuthorOptions = {
            threshold: 0.2,
        }

        const appearLeftAuthorObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftAuthorObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftAuthorOptions)

        appearLeftAuthor.forEach(appearLeft => {
            appearLeftAuthorObserver.observe(appearLeft)
        })

        const appearLeftShareOptions = {
            threshold: 0.2,
        }

        const appearLeftShareObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftShareObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftShareOptions)

        appearLeftShare.forEach(appearLeft => {
            appearLeftShareObserver.observe(appearLeft)
        })

        const appearRightAdOptions = {
            threshold: 0.2,
        }

        const appearRightAdObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightAdObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightAdOptions)

        appearRightAd.forEach(appearRight => {
            appearRightAdObserver.observe(appearRight)
        })

        const appearRightFormOptions = {
            threshold: 0.2,
        }

        const appearRightFormObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFormObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFormOptions)

        appearRightForm.forEach(appearRight => {
            appearRightFormObserver.observe(appearRight)
        })

        const appearRightSocialsOptions = {
            threshold: 0.2,
        }

        const appearRightSocialsObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightSocialsObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightSocialsOptions)

        appearRightSocials.forEach(appearRight => {
            appearRightSocialsObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearUpOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS


    // SEND ENQUIRY

    const onBeforeMsgSubmit = (e) => {
        e.preventDefault()


        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (msg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "msg": msg,
            }
    
    
            onSubmitMsg(payload)
        }

        
    }


    const onSubmitMsg = (payload) => {

        window.dataLayer.push({event: 'inquiry_submission'})

        ReactGa.event("inquiry_submission", {form_id: "DTE Blog Form"})

        axios.post("https://www.ict-mentors.com/api/send_mail_blog", { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setLoading(false)

                setName('')
                setEmail('')
                setPhone('')
                setMsg('')
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }



    ///////////////////////////////////////////////////////



    // SHARE iN SOCIALS

    const shareFb = () => {
        // const blogLink = encodeURI(window.location.href)
        const blogLink = encodeURI('https://ict-mentors.com/dte_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.facebook.com/share.php?u=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DTE", social_platform: "Facebook"})

    }

    const shareTwitter = () => {
        const blogLink = encodeURI('https://ict-mentors.com/dte_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.twitter.com/share?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DTE", social_platform: "Twitter"})

    }

    const shareLinkedin = () => {
        const blogLink = encodeURI('https://ict-mentors.com/dte_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DTE", social_platform: "Linkedin"})

    }

    const shareWhatsapp = () => {
        const blogLink = encodeURI('https://ict-mentors.com/dte_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article:')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)


        var message = `${msg} \n ${blogLink}`

        // Opening URL
        window.open(`https://api.whatsapp.com/send?text=${message}`);
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DTE", social_platform: "Whatsapp"})

    }


    //////////////////////////////////////////////////////////

    // Navigate to contact us page

    const goContact = (subject) => {
        navigate(`/contact_us?subject=${subject}`)
    }

    /////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        <ThemeProvider theme={theme}>
            <div className='blogpage-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>
                <Helmet>
                    <title>Demystifying Digital Transformation for Businesses</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="blog-section">
                    <div className="blog-top">
                        <div className="title-socials">
                            <h1 className="blog-title">Demystifying Digital Transformation for Businesses</h1>

                            <div className="socials">
                                <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="DTE-fb" />
                                <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="DTE-twitter" />
                                <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="DTE-linkedin" />
                                <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="DTE-whatsapp" />

                            </div>
                        </div>

                        <div className="author-date">
                            <p className="author">By: John Mathenge</p>
                            <p className="date">Published: 28th August 2023</p>
                        </div>

                    </div>

                    <div className="blog-body">
                        <div className="blog-left">
                            <div className="img-container blur-div" style={{ backgroundImage: `url(${digitalTransformationSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={digitalTransformation} alt="" className="main-img" loading='lazy' />
                            </div>

                            <p className="blog-p">Digital transformation in the context of businesses refers to the process of integrating digital technology into all aspects of an organization's operations, fundamentally changing how they operate and deliver value to their customers. It involves leveraging technology to improve business processes, enhance customer experiences, and drive innovation.</p>

                            <div className="blog-div">
                                <h1 className="section-title">Key aspects of Digital Transformation</h1>

                                <p className="blog-p">The following are key aspects that come into play when working to transform an organization's digital landscape:</p>
                                <ul className="blog-ul">
                                    <li className="blog-li">Technology Adoption: Embracing technologies like cloud computing, data analytics, artificial intelligence, Internet of Things (IoT), and automation to streamline operations and gain a competitive edge.</li>
                                    <li className="blog-li">Process Optimization: Reevaluating and reengineering existing business processes to make them more efficient and data-driven. This often involves eliminating manual or redundant tasks and leveraging automation.</li>
                                    <li className="blog-li">Data Utilization: Harnessing the power of data through analytics to make informed decisions, understand customer behavior, and predict trends. Big data and analytics play a crucial role in digital transformation.</li>
                                    <li className="blog-li">Customer-Centric Approach: Focusing on improving customer experiences by using technology to provide personalized services, seamless interactions, and responsive support.</li>
                                    <li className="blog-li">Cultural Shift: Encouraging a digital-first mindset and fostering a culture of innovation within the organization. This might involve upskilling employees and promoting a more agile and collaborative work environment.</li>
                                    <li className="blog-li">Agility and Flexibility: Being adaptable to changes in the business environment and rapidly responding to market shifts and customer demands. This often means adopting agile methodologies.</li>
                                    <li className="blog-li">Security and Compliance: Ensuring that digital transformation efforts prioritize cybersecurity and compliance with data protection regulations, as the increased use of technology also brings increased security risks.</li>
                                    <li className="blog-li">Ecosystem Collaboration: Collaborating with external partners, startups, and tech companies to leverage their expertise and technologies for mutual benefit.</li>
                                    <li className="blog-li">Cost Efficiency: Although digital transformation requires investments, it aims to reduce long-term operational costs through automation and efficiency gains.</li>
                                    <li className="blog-li">Innovation: Creating a culture of continuous innovation to develop new products, services, and business models that leverage digital capabilities.</li>
                                </ul>
                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${digitalvsitTransformationSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={digitalvsitTransformation} alt="" className='normal-img' loading='lazy' />
                                </div>

                                <h1 className="section-title">Digital transformation vs IT transformation</h1>

                                <p className="blog-p">Digital transformation and IT transformation are related concepts, but they focus on different aspects of an organization's evolution in the digital age. Here are the key differences between the two:</p>

                                <ul className="blog-ul">
                                    <li className="blog-li">Scope and Focus: <p className='blog-p'>Digital transformation encompasses a broader scope and focuses on using digital technology to fundamentally change the entire organization. It includes not only IT but also changes in business processes, customer experiences, culture, and business models. Digital transformation is about reimagining the entire business strategy with a digital-first mindset. IT Transformation: IT transformation, on the other hand, is more narrowly focused on modernizing and optimizing the IT infrastructure and services within an organization. It primarily deals with upgrading hardware, software, networks, and data centers to improve efficiency and agility within the IT department</p></li>
                                    <li className="blog-li">Business-Centric vs. IT-Centric: <p className='blog-p'>Digital transformation is business-centric. It aims to align technology with overall business goals, enhance customer experiences, and drive innovation across all departments. It involves changes in how an organization interacts with customers, delivers products and services, and creates value. IT transformation on the other hand is IT-centric. It primarily revolves around improving the IT department's capabilities and operations, such as enhancing data center efficiency, adopting cloud technologies, or implementing DevOps practices</p></li>
                                    <li className="blog-li">Impact on Culture and Processes: <p className='blog-p'>Digital transformation often requires a significant cultural shift within the organization. It encourages a more agile, collaborative, and customer-focused culture. It also involves reengineering business processes to be more data-driven and efficient. IT transformation may impact the culture within the IT department but typically has a more limited influence on the broader organizational culture and business processes</p></li>
                                    <li className="blog-li">Primary Objectives: <p className='blog-p'>The primary objectives of digital transformation are to stay competitive in the digital age, improve customer experiences, and drive innovation to create new revenue streams and business models. On the other hand, the main objectives of IT transformation are to increase IT efficiency, reduce operational costs, enhance scalability, and improve the delivery of IT services.</p></li>
                                    <li className="blog-li">Timeline and Evolution: <p className='blog-p'>Digital transformation is an ongoing and evolving process that extends beyond IT projects. It adapts to changing technology trends, customer expectations, and market dynamics. On the flip side, IT transformation projects typically have a defined timeline and are focused on achieving specific IT-related goals, such as upgrading infrastructure or implementing new software.</p></li>
                                </ul>
                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Conclusion</h1>

                                <p className='blog-p'>In summary, digital transformation is a strategic, organization-wide initiative aimed at reimagining the entire business in the digital age, while IT transformation is a more tactical effort focused on optimizing IT operations and infrastructure. Both are important in the modern business landscape, and they can complement each other when pursued together as part of a broader digital strategy.</p>
                            </div>

                            <div className="share-div">
                                <p className="share-p">Share on:</p>

                                <div className="socials">
                                    <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="DTE-fb" />
                                    <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="DTE-twitter" />
                                    <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="DTE-linkedin" />
                                    <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="DTE-whatsapp" />

                                </div>
                            </div>
                        </div>

                        <div className="blog-right">
                            <div className="form-container">
                                <h1 className="section-title">Enquire Now</h1>

                                <form onSubmit={onBeforeMsgSubmit} id='blog-form'>
                                    <TextField label="Name" type='text' variant="outlined" name='name' value={name} onChange={e => setName(e.target.value)} />
                                    <TextField label="Email" type='email' variant="outlined" name='email' value={email} onChange={e => setEmail(e.target.value)} />
                                    <TextField label="Phone" type='number' variant="outlined" name='phone' value={phone} onChange={e => setPhone(e.target.value)} />
                                    <TextField label="Message" type='text' multiline rows={4} variant="outlined" name='name' value={msg} onChange={e => setMsg(e.target.value)} />
                                    <LoadingButton
                                        className='contact-btn'
                                        type='submit' variant='contained'
                                        color='primary'
                                        style={{ color: "#ffffff" }}
                                        endIcon={<SendRoundedIcon className='send-icon' />}
                                        loading={loading}
                                        loadingPosition="end"
                                    >
                                        <span>Submit</span>

                                    </LoadingButton>
                                </form>
                            </div>

                            {ads.length > 0 ?
                                <>
                                    {ads.map(ad => (
                                        <>
                                            {ad.takeToContact ?
                                                <div className="ad" style={{ cursor: "pointer" }} onClick={() => goContact(ad.contactSubject)}>
                                                    <img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' />
                                                </div>
                                                :
                                                <>
                                                    {ad.link ?
                                                        <div className="ad">
                                                            <Link to={ad.link}><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                        :
                                                        <div className="ad">
                                                            <Link to='/'><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                    }
                                                </>

                                            }
                                        </>
                                    ))}
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default DTEBlog