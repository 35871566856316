import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/baseline-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/happy-woman.jpg"
import img1Small from "../../images/happy-woman-small.jpg"
import img2 from "../../images/hero-woman.png"
import img2Small from "../../images/hero-woman-small.png"
import img3 from "../../images/people-office1.jpg"
import img3Small from "../../images/people-office1-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const BaselineMaturity = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Baseline Maturity"})
        navigate('/contact_us?subject=Baseline and Maturity Assessment')
    }

    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Baseline & Maturity Assessment</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Baseline & Maturity Assessment</h1>
                            <p className="solution-p appear-right">ICT Mentors Solutions’ team of experts will apply global best practices to assess your organization’s IT environment (in IT Service Management, Information Security, Governance and business continuity). This exercise will provide a snapshot of the current organization’s IT environment and identify the gaps to achieve the desired state in alignment to the business goals and global best practices. The output provides a detailed assessment report and a roadmap on how to close the gaps identified. This exercise is intended to answer the questions, “Where Are We Now?” and “What Do We Need To Do?”</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Identifying Gaps</strong> - One of the primary benefits is the ability to identify gaps and shortcomings in the existing IT environment. This includes areas where technology may be outdated, security vulnerabilities, or inefficiencies in IT processes. By identifying these gaps, businesses can take steps to address them proactively.</li>
                                <li className="values-li appear-right"><strong>Risk Mitigation</strong> - Assessments can help in identifying security risks and compliance issues. Understanding these risks enables the organization to take corrective actions to mitigate potential threats, reducing the likelihood of data breaches, legal issues, and financial losses.</li>
                                <li className="values-li appear-left"><strong>Cost Optimization</strong> - An IT baseline assessment can reveal areas where costs can be optimized. This might involve consolidating redundant systems, optimizing resource utilization, or renegotiating vendor contracts to reduce expenses.</li>
                                <li className="values-li appear-right"><strong>Improved Efficiency</strong> - By assessing IT processes and workflows, businesses can identify areas where efficiency can be improved. This can lead to cost savings, faster project delivery, and better customer service.</li>
                                <li className="values-li appear-left"><strong>Alignment with Business Goals</strong> - Evaluating the maturity of IT processes can help ensure that IT aligns with the broader business objectives. This alignment ensures that IT investments and initiatives contribute to the company's overall strategic goals.</li>
                                <li className="values-li appear-right"><strong>Resource Allocation</strong> - Assessments can help organizations allocate budget/resources more effectively. By understanding the current state of IT and its maturity, companies can make informed decisions about where to invest resources, whether in training, new technology, or process improvements.</li>
                                <li className="values-li appear-left"><strong>Competitive Advantage</strong> - A mature and efficient IT environment can provide a competitive advantage. It enables organizations to adapt more quickly to changing market conditions, innovate faster, and meet customer expectations more effectively.</li>
                                <li className="values-li appear-right"><strong>Compliance and Governance</strong> - Assessments help ensure that the organization complies with industry regulations and internal governance standards. This is crucial for avoiding legal and regulatory issues.</li>
                                <li className="values-li appear-left"><strong>Strategic Planning</strong> - An IT baseline and maturity assessment can inform strategic planning efforts. It helps leadership make data-driven decisions about the direction of IT investments and initiatives.</li>
                                <li className="values-li appear-right"><strong>Communication and Accountability</strong> - By having a clear understanding of the IT baseline and maturity, organizations can establish accountability within the IT department. This can improve communication and collaboration among IT teams and with other departments.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Baseline & Maturity' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default BaselineMaturity