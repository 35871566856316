import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/global-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/blackman.jpg"
import img1Small from "../../images/blackman-small.jpg"
import img2 from "../../images/blog4.jpg"
import img2Small from "../../images/blog4-small.png"
import img3 from "../../images/happy-family.jpg"
import img3Small from "../../images/happy-family-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const GlobalFrameworks = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Global Frameworks"})
        navigate('/contact_us?subject=Implementation of Global Best Frameworks')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Implementation of Global Best Frameworks</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Implementation of Global Best Frameworks (COBIT, ITILV4, ISO27001,SO22301 etc.)</h1>
                            <p className="solution-p appear-right">Businesses globally recognize that effective and efficient IT Services Provision is underpinned by globally defined frameworks that ensure these services are aligned to the business and customer demands while reducing the costs through improved utilization of resources. ICT Mentors Solutions will walk with you to realize effective implementation of these frameworks</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Improved Efficiency</strong> - Governance best practices help streamline decision-making processes, reducing bureaucracy and enabling faster responses to market changes.</li>
                                <li className="values-li appear-right"><strong>Enhanced Compliance</strong> - Adhering to global best practices in governance, ITSM, security, and business continuity can help businesses meet regulatory requirements more effectively, avoiding legal and financial penalties.</li>
                                <li className="values-li appear-left"><strong>Risk Mitigation</strong> - Implementing security best practices helps identify and mitigate potential security threats, reducing the risk of data breaches and reputational damage.</li>
                                <li className="values-li appear-right"><strong>Increased Resilience</strong> - Business continuity best practices enable organizations to plan for and recover from various disruptions, including natural disasters, cyberattacks, and economic downturns, enhancing overall resilience.</li>
                                <li className="values-li appear-left"><strong>Cost Reduction</strong> - Efficient governance can help reduce administrative costs by optimizing decision-making processes, improving resource allocation and reducing IT-related downtime. Security best practices help prevent costly data breaches and their associated expenses, such as legal fees and reputation management costs.</li>
                                <li className="values-li appear-right"><strong>Competitive Advantage</strong> - Implementing best practices can differentiate a business from competitors, demonstrating a commitment to quality, security, and resilience. Organizations that adhere to best practices are often seen as more reliable partners and suppliers.</li>
                                <li className="values-li appear-left"><strong>Improved Customer Trust</strong> - Demonstrating a commitment to security and business continuity reassures customers that their data and services are in safe hands, building trust and loyalty.</li>
                                <li className="values-li appear-right"><strong>Better Decision-Making</strong> - Governance best practices provide a framework for strategic decision-making, ensuring that decisions align with organizational goals and objectives.</li>
                                <li className="values-li appear-left"><strong>Scalability</strong> - Implementing best practices can make it easier for businesses to scale their operations and IT infrastructure as they grow, without sacrificing efficiency or security.</li>
                                <li className="values-li appear-right"><strong>Innovation Enablement</strong> - Strong governance can foster innovation by providing clear guidelines and processes for implementing new ideas and technologies.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Global Frameworks' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default GlobalFrameworks