import { useState, useEffect } from 'react'
import "./GroupOffers.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import itilOffer from "../../images/itilfoundation-offer.svg"
import itilOfferSmall from "../../images/itilfoundation-offer-small.png"
import cbapOffer from "../../images/cbap-offer.svg"
import cbapOfferSmall from "../../images/cbap-offer-small.png"
import prince2Offer from "../../images/prince2foundation-offer.svg"
import prince2OfferSmall from "../../images/prince2foundation-offer-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Button } from '@mui/material'
import offerAbstract from "../../images/offer-abstract.png"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import { Link, useNavigate } from "react-router-dom"
import dotGrid from "../../images/dot-grid.png"
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from "react-redux"
import TextField from '@mui/material/TextField';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import axios from 'axios'
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import Tooltip from '@mui/material/Tooltip';
import baloon from "../../images/baloon.png"
import solutionHeroSmall from "../../images/solution-hero-small.png"
import ReactGa from "react-ga4"





const GroupOffers = () => {


    // USESTATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [peopleCount, setPeopleCount] = useState('')
    const [product, setProduct] = useState('ITIL 4 Foudation')

    const [loading, setLoading] = useState(false)




    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate()
    const dispatch = useDispatch()




    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');

    // USEEFFFECTS


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')
        const appearDowns = document.querySelectorAll('.appear-down')
        const appearUps = document.querySelectorAll('.appear-up')


        const appearLeftsFull = document.querySelectorAll('.appear-left-full')
        const appearRightsFull = document.querySelectorAll('.appear-right-full')
        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftFullOptions = {
            threshold: 0.2,
        }

        const appearLeftFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftFullOptions)

        appearLeftsFull.forEach(appearLeft => {
            appearLeftFullObserver.observe(appearLeft)
        })

        const appearRightFullOptions = {
            threshold: 0.2,
        }

        const appearRightFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFullOptions)

        appearRightsFull.forEach(appearRight => {
            appearRightFullObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])


    ////////////////////////////////////////////////////////

    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // FUNCTIONS

    // Form Submit Functionality

    const onBeforeSubmit = (e) => {
        e.preventDefault()



        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter the subject of the call"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (peopleCount.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter the number of individuals to be trained. If just yourself, put 1"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "peopleCount": peopleCount,
                "product": product
            }

            onSubmit(payload)
        }
    }

    const onSubmit = (payload) => {

        window.dataLayer.push({ event: 'claim_offer' })


        // Send Email
        axios.post("https://www.ict-mentors.com/api/claim_offer", payload, { headers: { 'X-CSRFToken': csrftoken } })
        .then(res => {

            dispatch({
                type: "GET_MESSAGES",
                payload: res.data
            })

            setName('')
            setEmail('')
            setPhone('')
            setPeopleCount('')
            setProduct('')

            setLoading(false)
            closeOffer()
        })
        .catch(err => {


            const error = {
                message: err.response.data,
                status: err.response.status
            }

            dispatch({
                type: 'GET_ERRORS',
                payload: error
            })

            setLoading(false)
            closeOffer()

        })


        // // Send a custom Google Analytics event
        ReactGa.event("claim_offer", { product_name: product })


        // Whatsapp Headers
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer EAAE6t5VJQ3kBO5i9ZAfPDeuu6KfRlPp6UXJXA7sRVv9IyK12I5NlBgQP1lrjoN64P2pF5q3EO0m2XARA7wYOGI16vZAYObEK4XR7MPwv8ZASSAlRtzvHGbzSxNZAcb1Qv2t5hfExi0Hp7lqPZA00rQMo2OUXc4k7X3rjn3TYzf3KnwM2JoHhfECkxGAZCmN5OE'
            }
        }


        // Mutiso Msg
        const waMsg = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "254759161361",
            "type": "text",
            "text": {
                "preview_url": false,
                "body": `Customer is claming Black Friday Offer\nName: ${name} \nEmail: ${email}\nPhone: ${phone} \nNumber of People: ${peopleCount} \nProduct: ${product}`
            }
        }

        // John Msg
        const waMsg2 = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "254721574254",
            "type": "text",
            "text": {
                "preview_url": false,
                "body": `User is claming Black Friday Offer\nName: ${name} \nEmail: ${email}\nPhone: ${phone} \nNumber of People: ${peopleCount} \nProduct: ${product}`
            }
        }

        // Jerioth Msg
        const waMsg3 = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "254727173773",
            "type": "text",
            "text": {
                "preview_url": false,
                "body": `User is claming Black Friday Offer\nName: ${name} \nEmail: ${email}\nPhone: ${phone} \nNumber of People: ${peopleCount} \nProduct: ${product}`
            }
        }

        

        // Send Me Mutiso a notification
        axios.post('https://graph.facebook.com/v17.0/174669262396800/messages', waMsg, headers)
        .then(res => {
            console.log("res")
        })
        .catch(err => {

            console.log("err")

        })


        // Send John a notification
        axios.post('https://graph.facebook.com/v17.0/174669262396800/messages', waMsg2, headers)
        .then(res => {
            console.log("res")
        })
        .catch(err => {

            console.log("err")

        })


        // Send Jerioth a notification
        axios.post('https://graph.facebook.com/v17.0/174669262396800/messages', waMsg3, headers)
        .then(res => {
            console.log("res")
        })
        .catch(err => {

            console.log("err")

        })

    }

    //////////////////////////////////////////////////////

    const closeOffer = () => {
        const offerPopup = document.querySelector('.groupoffers-container .popup-wrapper')

        offerPopup.classList.add('disappear')
    }

    const openForm = (product) => {
        setProduct(product)

        const offerPopup = document.querySelector('.groupoffers-container .popup-wrapper')

        offerPopup.classList.remove('disappear')

    }


    // Remove Height 100% on Img Container once image loads

    const img1Loaded = () => {

        console.log("Image1 has loaded")

        const offerLeft = document.querySelector('.groupoffers-container .offers-section .offer-wrapper .offer.offer1 .offer-left')

        offerLeft.style.height = "unset"
    }

    const img2Loaded = () => {

        console.log("Image2 has loaded")

        const offerLeft = document.querySelector('.groupoffers-container .offers-section .offer-wrapper .offer.offer2 .offer-left')

        offerLeft.style.height = "unset"
    }

    const img3Loaded = () => {

        console.log("Image3 has loaded")

        const offerLeft = document.querySelector('.groupoffers-container .offers-section .offer-wrapper .offer.offer3 .offer-left')

        offerLeft.style.height = "unset"
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    return (

        <ThemeProvider theme={theme}>
            <div className='groupoffers-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>

                <Helmet>
                    <title>ICT Mentors | Offers</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="popup-wrapper disappear">

                    <div className="illustrations">
                        <img src={baloon} alt="" className='img1' />
                        <img src={solutionHeroSmall} alt="" className='img2' />
                    </div>

                    <Tooltip title="minimise"><FullscreenExitRoundedIcon className='exit-icon' onClick={closeOffer} /></Tooltip>

                    <form onSubmit={onBeforeSubmit} id='offer-form'>
                        <h3>{product}</h3>
                        <TextField label="Name" type='text' variant="outlined" name='name' value={name} onChange={e => setName(e.target.value)} size='small' />
                        <TextField label="Email" type='email' variant="outlined" name='email' value={email} onChange={e => setEmail(e.target.value)} size='small' />
                        <TextField label="Phone" type='number' variant="outlined" name='phone' value={phone} onChange={e => setPhone(e.target.value)} size='small' />
                        <TextField label="Number of People" type='number' variant="outlined" name='people-count' value={peopleCount} onChange={e => setPeopleCount(e.target.value)} size='small' />
                        <LoadingButton
                            className='contact-btn'
                            type='submit' variant='contained'
                            color='primary'
                            style={{ color: "#ffffff" }}
                            endIcon={<SendRoundedIcon className='send-icon' />}
                            loading={loading}
                            loadingPosition="end"
                        >
                            <span>Submit</span>

                        </LoadingButton>

                        <p className="disclaimer">** Rest assured that any contact or details you provide, will be kept strictly confidential **</p>
                    </form>
                </div>

                <section className="offers-section">

                    <img src={offerAbstract} alt="" className="abstract" />

                    <div className="offer-wrapper">
                        <div className="offer offer1 appear-left">
                            <div className="offer-left">
                                <div className="img-container blur-div" style={{ background: `url(${itilOfferSmall}), repeat`, backgroundSize: "200px" }}>
                                    <img src={itilOffer} alt="" onLoad={img1Loaded} />
                                </div>
                                <p className="discount-tag"><span>30% Off</span></p>
                            </div>

                            <div className="offer-right">
                                <h2 className="offer-h2">ITIL 4 Foundation</h2>

                                <p className="offer-p">Attain in-depth knowledge to manage IT-enabled services in the digital economy</p>

                                <Button className='offer-btn' variant='outlined' onClick={() => openForm('ITIL 4 Foundation')}>Claim Offer</Button>

                            </div>
                        </div>

                        <div className="offer offer2 appear-right">
                            <div className="offer-left">
                                <div className="img-container blur-div" style={{ background: `url(${cbapOfferSmall}), repeat`, backgroundSize: "200px" }}>
                                    <img src={cbapOffer} alt="" onLoad={img2Loaded} />
                                </div>
                                <p className="discount-tag"><span>20% Off</span></p>
                            </div>

                            <div className="offer-right">
                                <h2 className="offer-h2">Certified Business Analysis professional (CBAP)</h2>

                                <p className="offer-p">Ensure there’s alignment between your IT Infrastructure and your Business Goals</p>

                                <Button className='offer-btn' variant='outlined' onClick={() => openForm('CBAP')}>Claim Offer</Button>

                            </div>
                        </div>

                        <div className="offer offer3 appear-left">
                            <div className="offer-left">
                                <div className="img-container blur-div" style={{ background: `url(${itilOfferSmall}), repeat`, backgroundSize: "200px" }}>
                                    <img src={prince2Offer} alt="" onLoad={img3Loaded} />
                                </div>
                                <p className="discount-tag"><span>30% Off</span></p>
                            </div>

                            <div className="offer-right">
                                <h2 className="offer-h2">PRINCE2 Agile Foundation</h2>

                                <p className="offer-p">Apply project management in an effective, efficient and agile way.</p>

                                <Button className='offer-btn' variant='outlined' onClick={() => openForm('PRINCE2 Agile Foundation')}>Claim Offer</Button>

                            </div>
                        </div>

                        {/* <div className="offer offer4 appear-right">
                            <div className="offer-left">
                                <img src={itilOffer} alt="" />
                                <p className="discount-tag"><span>30% Off</span></p>
                            </div>

                            <div className="offer-right">
                                <h2 className="offer-h2">ITIL 4 Foundation</h2>

                                <p className="offer-p">Ensure there’s alignment between your IT Infrastructure and your Business Goals</p>

                                <Button className='offer-btn' variant='outlined' onClick={() => openForm('ITIL 4 Foundation')}>Claim Offer</Button>

                            </div>
                        </div> */}
                    </div>

                    <p className="warning-p appear-down">** The following offers will run only for a limited time period, due to demand, we’re forced to shut the doors pretty soon, so hurry and claim your offer today **</p>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default GroupOffers