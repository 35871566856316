import React, { useState, useEffect } from 'react'
import Nav from '../nav/Nav'
import Footer from '../footer/Footer'
import "./Home.css"
import rightArrow from "../../images/right-arrow.svg"
import rightArrowWhite from "../../images/right-arrow-white.svg"
import clarifyPerspective from "../../images/clarify-perspective.svg"
import kra from "../../images/kra-logo.png"
import hf from "../../images/hf-logo.webp"
import kpmg from "../../images/kpmg-logo.png"
import naivas from "../../images/naivas-logo.png"
import ncba from "../../images/ncba-logo.png"
import kenyaSeedCompany from "../../images/kenya-seed-company.png"
import kentrade from "../../images/kentrade.png"
import kerra from "../../images/kerra.png"
import kengen from "../../images/kengen.png"
import kemri from "../../images/kemri.png"
import ictAuthority from "../../images/ict-authority.png"
import zemen from "../../images/zemen.png"
import stanbic from "../../images/stanbic.png"
import amica from "../../images/amica.jpg"
import kimisitu from "../../images/kimisitu.png"
import pioneer from "../../images/pioneer.png"
import finlays from "../../images/finlays.png"
import impax from "../../images/impax.png"
import kenyaAirways from "../../images/kenya-airways.png"
import manStairs from "../../images/man-stairs.png"
import soc from "../../images/soc.jpg"
import socSmall from "../../images/soc-small.jpg"
import { Link, useNavigate } from "react-router-dom"
import rocket from "../../images/rocket.png"
import happyWoman from "../../images/happy-woman.jpg"
import happyWomanSmall from "../../images/happy-woman-small.jpg"
import happyBlackman1 from "../../images/happy-blackman1.jpg"
import happyBlackman1Small from "../../images/happy-blackman1-small.jpg"
import happyBlackman2 from "../../images/happy-blackman2.jpg"
import happyBlackman2Small from "../../images/happy-blackman2-small.jpg"
import PlayBtn from '@mui/icons-material/PlayArrowRounded';
import blog1Img from "../../images/ddos.jpg"
import blog1ImgSmall from "../../images/ddos-small.png"
import blog2Img from "../../images/blog2.jpg"
import blog2ImgSmall from "../../images/blog2-small.jpg"
import blog3Img from "../../images/blog3.jpg"
import blog3ImgSmall from "../../images/blog3-small.png"
import blog4Img from "../../images/blog4.jpg"
import blog4ImgSmall from "../../images/blog4-small.png"
import heroImg from "../../images/hero-woman.png"
import heroImgSmall from "../../images/hero-woman-small.png"
import heroCircles from "../../images/hero-circles.png"
import smallHeroCircle from "../../images/small-hero-circle.png"
import rocket3d from "../../images/rocket-3d.png"
import { Button } from '@mui/material'
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"


const Home = () => {

  // INITIALIZATIONS

  const navigate = useNavigate()

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // USEEFFECTS

  // Change Slides
  useEffect(() => {
    const slides = document.querySelectorAll('.slider');
    const markers = document.querySelectorAll('.marker')
    const intervalTime = 7000;

    const nextSlide = () => {

      const heroLeft = document.querySelector('.home-container .hero-section .hero-left')
      heroLeft.classList.add('active')

      setTimeout(() => heroLeft.classList.remove('active'), 500)

      // Get current class
      const current = document.querySelector('.slider.active');
      // Remove current class
      current.classList.remove('active');

      const currentMarker = document.querySelector('.marker.active')
      currentMarker.classList.remove('active')

      // Check for next slide
      if (current.nextElementSibling) {
        // Add current to next sibling
        current.nextElementSibling.classList.add('active');
      } else {
        // Add current to start
        slides[0].classList.add('active');
      }

      if (currentMarker.nextElementSibling) {
        currentMarker.nextElementSibling.classList.add('active');
      } else {
        markers[0].classList.add('active')
      }
      setTimeout(() => { });
    };


    // Run next slide at interval time  
    const interval = setInterval(nextSlide, intervalTime);

    return () => clearInterval(interval)
  }, [])





  ///////////////////////////////////

  // INTERSECTION OBSERVERS

  useEffect(() => {
    const appearLefts = document.querySelectorAll('.appear-left')
    const appearLeftBtn = document.querySelector('.home-container .hero-section .hero-left .learn-btn')
    const appearLeftSoc = document.querySelector('.home-container .soc-section img')
    const appearLeftRocket = document.querySelector('.appear-rocket')
    const appearRights = document.querySelectorAll('.appear-right')
    const appearDowns = document.querySelectorAll('.appear-down')
    const appearUps = document.querySelectorAll('.appear-up')


    const appearLeftsFull = document.querySelectorAll('.appear-left-full')
    const appearRightsFull = document.querySelectorAll('.appear-right-full')
    const appearDownsFull = document.querySelectorAll('.appear-down-full')


    const appearLeftOptions = {
      threshold: 0.2,
    }

    const appearLeftObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftOptions)

    appearLefts.forEach(appearLeft => {
      appearLeftObserver.observe(appearLeft)
    })

    const appearLeftBtnOptions = {
      threshold: 0.2,
    }

    const appearLeftBtnObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftBtnObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftBtnOptions)

    appearLeftBtnObserver.observe(appearLeftBtn)

    const appearLeftRocketOptions = {
      threshold: 0.2,
    }

    const appearLeftRocketObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftRocketObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftRocketOptions)

    appearLeftRocketObserver.observe(appearLeftRocket)

    const appearLeftSocOptions = {
      threshold: 0.2,
    }

    const appearLeftSocObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftSocObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftSocOptions)

    appearLeftSocObserver.observe(appearLeftSoc)





    const appearRightOptions = {
      threshold: 0.2,
    }

    const appearRightObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightOptions)

    appearRights.forEach(appearRight => {
      appearRightObserver.observe(appearRight)
    })

    const appearDownOptions = {
      threshold: 0.2,
    }

    const appearDownObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownOptions)

    appearDowns.forEach(appearRight => {
      appearDownObserver.observe(appearRight)
    })




    const appearLeftFullOptions = {
      threshold: 0.2,
    }

    const appearLeftFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftFullOptions)

    appearLeftsFull.forEach(appearLeft => {
      appearLeftFullObserver.observe(appearLeft)
    })

    const appearRightFullOptions = {
      threshold: 0.2,
    }

    const appearRightFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightFullOptions)

    appearRightsFull.forEach(appearRight => {
      appearRightFullObserver.observe(appearRight)
    })

    const appearDownFullOptions = {
      threshold: 0.2,
    }

    const appearDownFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearDownsFull.forEach(appearRight => {
      appearDownFullObserver.observe(appearRight)
    })

    const appearUpOptions = {
      threshold: 0.2,
    }

    const appearUpObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearUps.forEach(appearUp => {
      appearUpObserver.observe(appearUp)
    })
  }, [])

  /////////////////////////////////////////////////////////////////


  // LAZY LOAD USEEFFECT

  useEffect(() => {
    const blurDivs = document.querySelectorAll('.blur-div')

    blurDivs.forEach(div => {
      let img = div.querySelector('img')

      const loaded = () => {
        div.classList.add('loaded')
      }

      if (img.complete) {
        loaded()
      } else {
        img.addEventListener("load", loaded)
      }
    })
  }, [])


  /////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // FUNCTIONS






  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  return (
    <div className='home-container'>

      <Helmet>
        <title>ICT Mentors Solutions</title>
        <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
      </Helmet>


      <Nav />

      <section className="hero-section">
        <div className="triangle"></div>
        <div className="fill-space">
          <img src={rocket3d} alt="" className='rocket-3d appear-rocket' loading='lazy' />
        </div>

        <div className="hero-left">
          <div className="marker-container  appear-left">
            <div className="marker marker1 active"></div>
            <div className="marker marker2"></div>
            <div className="marker marker3"></div>
            <div className="marker marker4"></div>
            <div className="marker marker5"></div>
          </div>

          <div className="slides-container">
            <div className="slider slider1 active">

              <div className="solutions-title-div">
                <h1 className="slider-title appear-left">STRATEGY <span className='highlight'>FORMULATION</span>

                  <svg width="307" height="20" viewBox="0 0 307 20" fill="none" className='hero-line'>
                    <path d="M307 2.9057C305.437 2.77362 304.004 2.77362 302.441 2.77362C300.878 2.77362 299.445 2.64154 297.882 2.50946C294.496 2.24531 291.24 1.84908 287.853 1.58493C281.471 1.05662 274.958 0.528308 268.576 0.396231C262.715 0.132077 256.723 0 250.862 0C249.299 0 247.736 0 246.303 0C240.442 0.132077 234.581 0.132077 228.72 0.264154C215.043 0.660385 201.237 1.05662 187.56 1.717C174.145 2.37739 160.729 3.03777 147.443 3.83024C140.931 4.22647 134.288 4.6227 127.776 5.01893C120.612 5.67932 113.448 6.07555 106.415 6.60386C91.6962 7.79255 77.1082 8.84917 62.3899 10.0379C49.6254 11.0945 36.9911 12.5473 24.3568 14.3964C16.2813 15.7172 8.07552 17.17 0 18.6229C9.24777 19.2833 18.6258 19.5474 28.0038 19.6795C34.1256 19.6795 40.2473 19.5474 46.2389 19.4153C50.1464 19.2833 54.0539 19.0191 58.0916 18.7549C60.5664 18.6229 62.9109 18.3587 65.2554 18.0946C71.6377 17.4342 78.1502 16.9059 84.5325 16.2455C91.9567 15.5851 99.2507 14.7926 106.675 14.1322C114.23 13.4719 121.914 12.8115 129.469 12.1511C136.242 11.6228 143.145 11.0945 150.048 10.5662C156.691 10.0379 163.464 9.64163 170.107 9.2454C176.88 8.84917 183.523 8.45293 190.296 8.0567C197.199 7.66047 203.972 7.52839 210.875 7.26424C223.379 6.73593 235.883 6.60386 248.518 6.47178C255.551 6.47178 262.454 6.20762 269.488 5.94347C276.391 5.67932 283.294 5.41516 290.067 5.01893C292.803 4.88685 295.668 4.75478 298.404 4.49062C299.706 4.35854 301.139 4.22647 302.441 3.96231C303.613 3.69816 304.786 3.30193 305.958 3.16985C306.349 3.03777 306.609 2.9057 307 2.9057Z" fill="#27AAE1" />
                  </svg>

                </h1>
              </div>

              <p className="solutions-p appear-up">Align technology investments with business goals, optimizing costs, and managing risks</p>

              <Button variant='contained' className='learn-btn' onClick={() => { navigate('/strategy_formulation'); ReactGa.event("learn_more", { subject: "Strategy Formulation" }) }} id='Strategy Formulation'>
                <span>Learn More</span>
                <img src={rightArrowWhite} alt="" className='right-arrow-white' loading='lazy' />
              </Button>
            </div>

            <div className="slider slider2">

              <div className="solutions-title-div">
                <h1 className="slider-title">MATURITY <span className='highlight'>ASSESSMENT</span>

                  <svg width="307" height="20" viewBox="0 0 307 20" fill="none" className='hero-line'>
                    <path d="M307 2.9057C305.437 2.77362 304.004 2.77362 302.441 2.77362C300.878 2.77362 299.445 2.64154 297.882 2.50946C294.496 2.24531 291.24 1.84908 287.853 1.58493C281.471 1.05662 274.958 0.528308 268.576 0.396231C262.715 0.132077 256.723 0 250.862 0C249.299 0 247.736 0 246.303 0C240.442 0.132077 234.581 0.132077 228.72 0.264154C215.043 0.660385 201.237 1.05662 187.56 1.717C174.145 2.37739 160.729 3.03777 147.443 3.83024C140.931 4.22647 134.288 4.6227 127.776 5.01893C120.612 5.67932 113.448 6.07555 106.415 6.60386C91.6962 7.79255 77.1082 8.84917 62.3899 10.0379C49.6254 11.0945 36.9911 12.5473 24.3568 14.3964C16.2813 15.7172 8.07552 17.17 0 18.6229C9.24777 19.2833 18.6258 19.5474 28.0038 19.6795C34.1256 19.6795 40.2473 19.5474 46.2389 19.4153C50.1464 19.2833 54.0539 19.0191 58.0916 18.7549C60.5664 18.6229 62.9109 18.3587 65.2554 18.0946C71.6377 17.4342 78.1502 16.9059 84.5325 16.2455C91.9567 15.5851 99.2507 14.7926 106.675 14.1322C114.23 13.4719 121.914 12.8115 129.469 12.1511C136.242 11.6228 143.145 11.0945 150.048 10.5662C156.691 10.0379 163.464 9.64163 170.107 9.2454C176.88 8.84917 183.523 8.45293 190.296 8.0567C197.199 7.66047 203.972 7.52839 210.875 7.26424C223.379 6.73593 235.883 6.60386 248.518 6.47178C255.551 6.47178 262.454 6.20762 269.488 5.94347C276.391 5.67932 283.294 5.41516 290.067 5.01893C292.803 4.88685 295.668 4.75478 298.404 4.49062C299.706 4.35854 301.139 4.22647 302.441 3.96231C303.613 3.69816 304.786 3.30193 305.958 3.16985C306.349 3.03777 306.609 2.9057 307 2.9057Z" fill="#27AAE1" />
                  </svg>

                </h1>
              </div>

              <p className="solutions-p">Assess your IT infrastructure, processes, and practices; compared to desired maturity levels</p>

              <Button variant='contained' className='learn-btn' onClick={() => { navigate('/baseline_maturity'); ReactGa.event("learn_more", { subject: "Baseline Maturity" }) }} id='Maturity Assessment'>
                <span>Learn More</span>
                <img src={rightArrowWhite} alt="" className='right-arrow-white' loading='lazy' />
              </Button>
            </div>

            <div className="slider slider3">

              <div className="solutions-title-div">
                <h1 className="slider-title">SKILLS <span className='highlight'>DEVELOPMENT</span>

                  <svg width="307" height="20" viewBox="0 0 307 20" fill="none" className='hero-line'>
                    <path d="M307 2.9057C305.437 2.77362 304.004 2.77362 302.441 2.77362C300.878 2.77362 299.445 2.64154 297.882 2.50946C294.496 2.24531 291.24 1.84908 287.853 1.58493C281.471 1.05662 274.958 0.528308 268.576 0.396231C262.715 0.132077 256.723 0 250.862 0C249.299 0 247.736 0 246.303 0C240.442 0.132077 234.581 0.132077 228.72 0.264154C215.043 0.660385 201.237 1.05662 187.56 1.717C174.145 2.37739 160.729 3.03777 147.443 3.83024C140.931 4.22647 134.288 4.6227 127.776 5.01893C120.612 5.67932 113.448 6.07555 106.415 6.60386C91.6962 7.79255 77.1082 8.84917 62.3899 10.0379C49.6254 11.0945 36.9911 12.5473 24.3568 14.3964C16.2813 15.7172 8.07552 17.17 0 18.6229C9.24777 19.2833 18.6258 19.5474 28.0038 19.6795C34.1256 19.6795 40.2473 19.5474 46.2389 19.4153C50.1464 19.2833 54.0539 19.0191 58.0916 18.7549C60.5664 18.6229 62.9109 18.3587 65.2554 18.0946C71.6377 17.4342 78.1502 16.9059 84.5325 16.2455C91.9567 15.5851 99.2507 14.7926 106.675 14.1322C114.23 13.4719 121.914 12.8115 129.469 12.1511C136.242 11.6228 143.145 11.0945 150.048 10.5662C156.691 10.0379 163.464 9.64163 170.107 9.2454C176.88 8.84917 183.523 8.45293 190.296 8.0567C197.199 7.66047 203.972 7.52839 210.875 7.26424C223.379 6.73593 235.883 6.60386 248.518 6.47178C255.551 6.47178 262.454 6.20762 269.488 5.94347C276.391 5.67932 283.294 5.41516 290.067 5.01893C292.803 4.88685 295.668 4.75478 298.404 4.49062C299.706 4.35854 301.139 4.22647 302.441 3.96231C303.613 3.69816 304.786 3.30193 305.958 3.16985C306.349 3.03777 306.609 2.9057 307 2.9057Z" fill="#27AAE1" />
                  </svg>

                </h1>
              </div>

              <p className="solutions-p">Build capacity in employees, to successfully grow the business.</p>

              <Button variant='contained' className='learn-btn' onClick={() => { navigate('/trainings'); ReactGa.event("learn_more", { subject: "Skills Slider" }) }} id='Skills Development'>
                <span>Learn More</span>
                <img src={rightArrowWhite} alt="" className='right-arrow-white' loading='lazy' />
              </Button>
            </div>

            <div className="slider slider4 ">

              <div className="solutions-title-div">
                <h1 className="slider-title">MANAGED <span className='highlight'>SECURITY</span>

                  <svg width="307" height="20" viewBox="0 0 307 20" fill="none" className='hero-line'>
                    <path d="M307 2.9057C305.437 2.77362 304.004 2.77362 302.441 2.77362C300.878 2.77362 299.445 2.64154 297.882 2.50946C294.496 2.24531 291.24 1.84908 287.853 1.58493C281.471 1.05662 274.958 0.528308 268.576 0.396231C262.715 0.132077 256.723 0 250.862 0C249.299 0 247.736 0 246.303 0C240.442 0.132077 234.581 0.132077 228.72 0.264154C215.043 0.660385 201.237 1.05662 187.56 1.717C174.145 2.37739 160.729 3.03777 147.443 3.83024C140.931 4.22647 134.288 4.6227 127.776 5.01893C120.612 5.67932 113.448 6.07555 106.415 6.60386C91.6962 7.79255 77.1082 8.84917 62.3899 10.0379C49.6254 11.0945 36.9911 12.5473 24.3568 14.3964C16.2813 15.7172 8.07552 17.17 0 18.6229C9.24777 19.2833 18.6258 19.5474 28.0038 19.6795C34.1256 19.6795 40.2473 19.5474 46.2389 19.4153C50.1464 19.2833 54.0539 19.0191 58.0916 18.7549C60.5664 18.6229 62.9109 18.3587 65.2554 18.0946C71.6377 17.4342 78.1502 16.9059 84.5325 16.2455C91.9567 15.5851 99.2507 14.7926 106.675 14.1322C114.23 13.4719 121.914 12.8115 129.469 12.1511C136.242 11.6228 143.145 11.0945 150.048 10.5662C156.691 10.0379 163.464 9.64163 170.107 9.2454C176.88 8.84917 183.523 8.45293 190.296 8.0567C197.199 7.66047 203.972 7.52839 210.875 7.26424C223.379 6.73593 235.883 6.60386 248.518 6.47178C255.551 6.47178 262.454 6.20762 269.488 5.94347C276.391 5.67932 283.294 5.41516 290.067 5.01893C292.803 4.88685 295.668 4.75478 298.404 4.49062C299.706 4.35854 301.139 4.22647 302.441 3.96231C303.613 3.69816 304.786 3.30193 305.958 3.16985C306.349 3.03777 306.609 2.9057 307 2.9057Z" fill="#27AAE1" />
                  </svg>

                </h1>
              </div>

              <p className="solutions-p">Enhance cybersecurity resilience, protect sensitive data, and guard against threats effectively.</p>

              <Button variant='contained' className='learn-btn' onClick={() => { navigate('/soc'); ReactGa.event("learn_more", { subject: "SOC Slider" }) }} id='Managed Security'>
                <span>Learn More</span>
                <img src={rightArrowWhite} alt="" className='right-arrow-white' loading='lazy' />
              </Button>
            </div>

            <div className="slider slider5 ">

              <div className="solutions-title-div">
                <h1 className="slider-title">DIGITAL <span className='highlight'>TRANSFORMATION</span>

                  <svg width="307" height="20" viewBox="0 0 307 20" fill="none" className='hero-line'>
                    <path d="M307 2.9057C305.437 2.77362 304.004 2.77362 302.441 2.77362C300.878 2.77362 299.445 2.64154 297.882 2.50946C294.496 2.24531 291.24 1.84908 287.853 1.58493C281.471 1.05662 274.958 0.528308 268.576 0.396231C262.715 0.132077 256.723 0 250.862 0C249.299 0 247.736 0 246.303 0C240.442 0.132077 234.581 0.132077 228.72 0.264154C215.043 0.660385 201.237 1.05662 187.56 1.717C174.145 2.37739 160.729 3.03777 147.443 3.83024C140.931 4.22647 134.288 4.6227 127.776 5.01893C120.612 5.67932 113.448 6.07555 106.415 6.60386C91.6962 7.79255 77.1082 8.84917 62.3899 10.0379C49.6254 11.0945 36.9911 12.5473 24.3568 14.3964C16.2813 15.7172 8.07552 17.17 0 18.6229C9.24777 19.2833 18.6258 19.5474 28.0038 19.6795C34.1256 19.6795 40.2473 19.5474 46.2389 19.4153C50.1464 19.2833 54.0539 19.0191 58.0916 18.7549C60.5664 18.6229 62.9109 18.3587 65.2554 18.0946C71.6377 17.4342 78.1502 16.9059 84.5325 16.2455C91.9567 15.5851 99.2507 14.7926 106.675 14.1322C114.23 13.4719 121.914 12.8115 129.469 12.1511C136.242 11.6228 143.145 11.0945 150.048 10.5662C156.691 10.0379 163.464 9.64163 170.107 9.2454C176.88 8.84917 183.523 8.45293 190.296 8.0567C197.199 7.66047 203.972 7.52839 210.875 7.26424C223.379 6.73593 235.883 6.60386 248.518 6.47178C255.551 6.47178 262.454 6.20762 269.488 5.94347C276.391 5.67932 283.294 5.41516 290.067 5.01893C292.803 4.88685 295.668 4.75478 298.404 4.49062C299.706 4.35854 301.139 4.22647 302.441 3.96231C303.613 3.69816 304.786 3.30193 305.958 3.16985C306.349 3.03777 306.609 2.9057 307 2.9057Z" fill="#27AAE1" />
                  </svg>

                </h1>
              </div>

              <p className="solutions-p">Identify opportunities, and the path forward, to drive innovation and growth</p>

              <Button variant='contained' className='learn-btn' onClick={() => { navigate('/digital_transformation'); ReactGa.event("learn_more", { subject: "Digital Transformation" }) }} id='Digital Transformation'>
                <span>Learn More</span>
                <img src={rightArrowWhite} alt="" className='right-arrow-white' loading='lazy' />
              </Button>
            </div>


          </div>

          <div className='hero-circles-div'>
            <div style={{ position: "relative" }}>
              <img src={heroCircles} alt="" className='hero-circles' loading='lazy' />
              <img src={smallHeroCircle} alt="" className='small-circle small-circle1 appear-down' loading='lazy' />
              <img src={smallHeroCircle} alt="" className='small-circle small-circle2 appear-up' loading='lazy' />
            </div>

          </div>


        </div>

        <div className="hero-right appear-right">
          <img src={clarifyPerspective} alt="" className='watermark watermark1' loading='lazy' />

          <div className="hero-img-container blur-div" style={{ backgroundImage: `url(${heroImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
            <img src={heroImg} loading='lazy' />
          </div>
        </div>
      </section>

      <section className="clients-section">
        <h1 className="clients-h1 appear-left">Our Clientele</h1>

        <p className="clients-p appear-right">Partnering for digital transformation</p>

        <div className="logos">
          <div className="logos-slide">
            <img src={kra} alt="KRA" className='kra' loading='lazy' />
            <img src={kenyaSeedCompany} alt="Kenya Seed Company" className='ksc' loading='lazy' />
            <img src={kemri} alt="Kemri" className='kemri' loading='lazy' />
            <img src={kengen} alt="KenGen" className='kengen' loading='lazy' />
            <img src={kerra} alt="Kenya Rural Roads Authority" className='kerra' loading='lazy' />
            <img src={kentrade} alt="The Kenya TradeNet" className='kentrade' loading='lazy' />
            <img src={ictAuthority} alt="ICT Authority" className='ict-authority' loading='lazy' />
            <img src={zemen} alt="Zemen Bank" className="zemen" />
            <img src={stanbic} alt="Stanbic Bank" className="stanbic" />
            <img src={kimisitu} alt="Kimisitu Sacco" className="kimisitu" />
            <img src={amica} alt="Amica" className="amica" />
            <img src={pioneer} alt="Pioneer Insurance" className="pioneer" />
            <img src={finlays} alt="Finlays" className="finlays" />
            <img src={impax} alt="Impax" className="impax" />
            <img src={kenyaAirways} alt="Kenya Airways" className="kenya-airways" />
            <img src={hf} alt="HFC Bank" className='hf' loading='lazy' />
            <img src={kpmg} alt="KPMG" className='kpmg' loading='lazy' />
            <img src={naivas} alt="Naivas" className='naivas' loading='lazy' />
            <img src={ncba} alt="NCBA" className='ncba' loading='lazy' />
          </div>

          <div className="logos-slide">
            <img src={kra} alt="KRA" className='kra' loading='lazy' />
            <img src={kenyaSeedCompany} alt="Kenya Seed Company" className='ksc' loading='lazy' />
            <img src={kemri} alt="Kemri" className='kemri' loading='lazy' />
            <img src={kengen} alt="KenGen" className='kengen' loading='lazy' />
            <img src={kerra} alt="Kenya Rural Roads Authority" className='kerra' loading='lazy' />
            <img src={kentrade} alt="The Kenya TradeNet" className='kentrade' loading='lazy' />
            <img src={ictAuthority} alt="ICT Authority" className='ict-authority' loading='lazy' />
            <img src={zemen} alt="Zemen Bank" className="zemen" />
            <img src={stanbic} alt="Stanbic Bank" className="stanbic" />
            <img src={kimisitu} alt="Kimisitu Sacco" className="kimisitu" />
            <img src={amica} alt="Amica" className="amica" />
            <img src={pioneer} alt="Pioneer Insurance" className="pioneer" />
            <img src={finlays} alt="Finlays" className="finlays" />
            <img src={impax} alt="Impax" className="impax" />
            <img src={kenyaAirways} alt="Kenya Airways" className="kenya-airways" />
            <img src={hf} alt="HFC Bank" className='hf' loading='lazy' />
            <img src={kpmg} alt="KPMG" className='kpmg' loading='lazy' />
            <img src={naivas} alt="Naivas" className='naivas' loading='lazy' />
            <img src={ncba} alt="NCBA" className='ncba' loading='lazy' />
          </div>
        </div>
      </section>

      <section className="growth-section">
        <div className="content">
          <h1 className="section-title appear-left">We Initiate Growth</h1>
          <p className="growth-p appear-up">ICT Mentors Solutions works alongside organizations in various aspects of their business: from implementing solutions that give them a competitive advantage, to training their employees ensuring they are well capable to execute in business</p>
        </div>

        <div className="right-growth appear-right">
          <img src={manStairs} alt="Growth Image" loading='lazy' />

          <div className="squares-div">
            <div className="square square1"></div>
            <div className="square square2"></div>
          </div>
        </div>
      </section>

      <section className="soc-section">
        <div className="blur-div" style={{ backgroundImage: `url(${socSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
          <img src={soc} alt="SOC Image" className='appear-left-soc' loading='lazy' />
        </div>

        <div className="content">
          <h1 className="section-title apper-right">Meet Mr. SOC</h1>

          <p className="soc-p appear-up">Mr. SOC is a security expert developed by ICT Mentors Solutions to thoroughly monitor and mitigate security threats. It is a Security Operations Centre (SOC) that will ensure your organization's data stays safe and that any cyber attack is dealt with before it escalates.</p>

          <span onClick={() => { navigate('/soc'); ReactGa.event("learn_more", { subject: "SOC Section" }) }} className='learn-span appear-left'>Learn More</span>

        </div>
      </section>

      <section className="skills-section">
        <div className="content">
          <h1 className="skills-title appear-left">Build your skills</h1>

          <p className="skills-p appear-up">Looking for a workforce that delivers efficiency, agility, and a transformed culture? Our team of experts will walk with you, to realise this critical objective.</p>


          <Button variant='contained' className="skills-btn appear-down" onClick={() => {navigate('/trainings'); ReactGa.event("learn_more", {subject: "Skills Section"})}}>VIEW CERTIFICATIONS</Button>

        </div>

        <img src={rocket} alt="Rocket Image" className='skills-img appear-down' loading='lazy' />
      </section>

      {/* <section className="testimonials-section">
        <h1 className="section-title appear-left">We Have The Happiest Clients</h1>

        <div className="testimonials-wrapper">
          <div className="single-testimonial appear-left">
            <div className="video blur-div" style={{ backgroundImage: `url(${happyWomanSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
              <img src={happyWoman} alt="Happy Client" className='person-img' loading='lazy' />
              <div className="overlay"></div>
              <PlayBtn className='play-btn' />
            </div>

            <div className="content">
              <p className="test-p">I was delighted with the experience I got at ICT Mentors Solutions, everything was smooth and well thought of before we arrived for the training...</p>

              <p className="name-p">~Rose Mutiso</p>
            </div>
          </div>

          <div className="single-testimonial appear-up">
            <div className="video blur-div" style={{ backgroundImage: `url(${happyBlackman1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
              <img src={happyBlackman1} alt="Happy Client" className='person-img' loading='lazy' />
              <div className="overlay"></div>
              <PlayBtn className='play-btn' />
            </div>

            <div className="content">
              <p className="test-p">The team at ICT Mentors Solutions know exactly what is needed to implement success. Not only that, they are proficient enough to explain the technical stuff to a newbie like me, till I understood..</p>

              <p className="name-p">~Brandon Chisika, CEO Alliance LTD</p>
            </div>
          </div>

          <div className="single-testimonial appear-right">
            <div className="video blur-div" style={{ backgroundImage: `url(${happyBlackman2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
              <img src={happyBlackman2} alt="Happy Client" className='person-img' loading='lazy' />
              <div className="overlay"></div>
              <PlayBtn className='play-btn' />
            </div>

            <div className="content">
              <p className="test-p">We just love this company. We’ve worked with them on several occasions, they always surpass our expectations and deliver value  accurately...</p>

              <p className="name-p">~HFC Bank</p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="informed-section">
        <h1 className="section-title">Stay Informed</h1>

        <div className="blogs-wrapper">
          <Link to="/baseline_blog">
            <div className="single-blog appear-left">
              <div className="img-container blur-div" style={{ backgroundImage: `url(${blog4ImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }} >
                <img src={blog4Img} alt="Sports" loading='lazy' />
                <div className="overlay"></div>
              </div>

              <div className="content">
                <p className="date-p">11/8/2023</p>
                <h3 className="blog-title">Why Perform a Baseline & Maturity Assessment</h3>
                <p className="blog-p">In the dynamic realm of IT, achieving excellence isn't just a goal—it's a necessity. That's where Baseline Assessment comes into play. A transformative Baseline Assessment, goes beyond traditional IT evaluations...</p>

              </div>
            </div>
          </Link>

          <Link to="/vulnerability_blog">
            <div className="single-blog appear-right">
              <div className="img-container blur-div" style={{ backgroundImage: `url(${blog2ImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                <img src={blog2Img} alt="Sports" loading='lazy' />
                <div className="overlay"></div>
              </div>

              <div className="content">
                <p className="date-p">09/8/2023</p>
                <h3 className="blog-title">Managing vulnerabilities Within an Organization</h3>
                <p className="blog-p">In today's interconnected digital landscape, organizations face an ever-increasing number of cyber threats that can jeopardize sensitive data, disrupt operations, and tarnish reputations...</p>

              </div>
            </div>
          </Link>

          <Link to="/system_migration_blog">
            <div className="single-blog appear-left">
              <div className="img-container blur-div" style={{ backgroundImage: `url(${blog3ImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                <img src={blog3Img} alt="Sports" loading='lazy' />
                <div className="overlay"></div>
              </div>

              <div className="content">
                <p className="date-p">5/8/2023</p>
                <h3 className="blog-title">System Migration Process</h3>
                <p className="blog-p">In today's ever-evolving technological landscape, staying competitive and secure demands a proactive approach to system migration. Whether you're seeking improved performance, enhanced security, or the benefits of new features...</p>

              </div>
            </div>
          </Link>

          <Link to="/ddos_blog">
            <div className="single-blog appear-right">
              <div className="img-container blur-div" style={{ backgroundImage: `url(${blog1ImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                <img src={blog1Img} alt="Sports" loading='lazy' />
                <div className="overlay"></div>
              </div>

              <div className="content">
                <p className="date-p">23/7/2023</p>
                <h3 className="blog-title">Mitigating the impact of recent DDoS attacks on Kenya</h3>
                <p className="blog-p">The recent Distributed Denial of Service (DDOS) attacks have affected critical sectors of the Kenyan economy, disrupting several nationwide services....</p>

              </div>
            </div>
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Home