import { useState, useEffect } from 'react'
import "./BlogPage.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import blog3Img from "../../images/blog3.jpg"
import blog3ImgSmall from "../../images/blog3-small.png"
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import dotGrid from "../../images/dot-grid.png"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useDispatch } from "react-redux"
import axios from 'axios';
import TextField from '@mui/material/TextField';
import systemMigration2Blog from "../../images/system-migration2-blog.jpg"
import systemMigration2BlogSmall from "../../images/system-migration2-blog-small.jpg"
import systemMigration3Blog from "../../images/system3.jpg"
import systemMigration3BlogSmall from "../../images/system3-small.jpg"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const SystemMigration = () => {

    // USESTATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [ads, setAds] = useState([])

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');


    // USEEFFECTS

    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')


        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    // LOAD BLOG ADS

    useEffect(() => {
        axios.get('https://www.ict-mentors.com/api/get_blog_ads')
            .then(res => {
                setAds(res.data)
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }, [])

    ////////////////////////////////////////////////////////////////

    // LOAD DISAPPEAR USEEFFECT

    useEffect(() => {
        const loader = document.querySelector('.loader-wrapper')

        function vanish() {
            loader.classList.add('disappear')
        }


        window.addEventListener('load', vanish)
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.blog-p')
        const appearLeftImg = document.querySelectorAll('.img-container')
        const appearLeftAuthor = document.querySelectorAll('.author-date')
        const appearRightAd = document.querySelectorAll('.ad')
        const appearRights = document.querySelectorAll('.section-title')
        const appearRightSocials = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .socials')
        const appearRightForm = document.querySelectorAll('.blogpage-container .blog-right .form-container form')
        const appearLeftShare = document.querySelectorAll('.blogpage-container .share-div')
        const appearDowns = document.querySelectorAll('.blogpage-container .blog-ul .blog-li')
        const appearUps = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .blog-title')



        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftImgOptions = {
            threshold: 0.2,
        }

        const appearLeftImgObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftImgObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftImgOptions)

        appearLeftImg.forEach(appearLeft => {
            appearLeftImgObserver.observe(appearLeft)
        })

        const appearLeftAuthorOptions = {
            threshold: 0.2,
        }

        const appearLeftAuthorObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftAuthorObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftAuthorOptions)

        appearLeftAuthor.forEach(appearLeft => {
            appearLeftAuthorObserver.observe(appearLeft)
        })

        const appearLeftShareOptions = {
            threshold: 0.2,
        }

        const appearLeftShareObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftShareObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftShareOptions)

        appearLeftShare.forEach(appearLeft => {
            appearLeftShareObserver.observe(appearLeft)
        })

        const appearRightAdOptions = {
            threshold: 0.2,
        }

        const appearRightAdObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightAdObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightAdOptions)

        appearRightAd.forEach(appearRight => {
            appearRightAdObserver.observe(appearRight)
        })

        const appearRightFormOptions = {
            threshold: 0.2,
        }

        const appearRightFormObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFormObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFormOptions)

        appearRightForm.forEach(appearRight => {
            appearRightFormObserver.observe(appearRight)
        })

        const appearRightSocialsOptions = {
            threshold: 0.2,
        }

        const appearRightSocialsObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightSocialsObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightSocialsOptions)

        appearRightSocials.forEach(appearRight => {
            appearRightSocialsObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearUpOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS


    // SEND ENQUIRY

    const onBeforeMsgSubmit = (e) => {
        e.preventDefault()


        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (msg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "msg": msg,
            }
    
    
            onSubmitMsg(payload)
        }

        
    }


    const onSubmitMsg = (payload) => {

        window.dataLayer.push({event: 'inquiry_submission'})

        ReactGa.event("inquiry_submission", {form_id: "System Migration Blog Form"})

        axios.post("https://www.ict-mentors.com/api/send_mail_blog", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setLoading(false)

                setName('')
                setEmail('')
                setPhone('')
                setMsg('')
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }



    ///////////////////////////////////////////////////////


    // SHARE iN SOCIALS

    const shareFb = () => {
        // const blogLink = encodeURI(window.location.href)
        const blogLink = encodeURI('https://ict-mentors.com/system_migration_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.facebook.com/share.php?u=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "System Migration", social_platform: "Facebook"})

    }

    const shareTwitter = () => {
        const blogLink = encodeURI('https://ict-mentors.com/system_migration_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.twitter.com/share?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "System Migration", social_platform: "Twitter"})

    }

    const shareLinkedin = () => {
        const blogLink = encodeURI('https://ict-mentors.com/system_migration_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "System Migration", social_platform: "Linkedin"})

    }

    const shareWhatsapp = () => {
        const blogLink = encodeURI('https://ict-mentors.com/system_migration_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article:')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)


        var message = `${msg} \n ${blogLink}`

        // Opening URL
        window.open(`https://api.whatsapp.com/send?text=${message}`);
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "System Migration", social_platform: "Whatsapp"})

    }


    //////////////////////////////////////////////////////////


    // Navigate to contact us page

    const goContact = (subject) => {
        navigate(`/contact_us?subject=${subject}`)
    }

    /////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        <ThemeProvider theme={theme}>
            <div className='blogpage-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>
                <Helmet>
                    <title>System Migration Process</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="blog-section">
                    <div className="blog-top">
                        <div className="title-socials">
                            <h1 className="blog-title">System Migration Process</h1>

                            <div className="socials">
                                <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="System Migration-fb" />
                                <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="System Migration-twitter" />
                                <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="System Migration-linkedin" />
                                <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="System Migration-whatsapp" />

                            </div>
                        </div>

                        <div className="author-date">
                            <p className="author">By: John Mathenge</p>
                            <p className="date">Published: 28th August 2023</p>
                        </div>

                    </div>

                    <div className="blog-body">
                        <div className="blog-left">
                            <div className="img-container blur-div" style={{ backgroundImage: `url(${blog3ImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={blog3Img} alt="" className="main-img" loading='lazy' />
                            </div>

                            <p className="blog-p">In today's ever-evolving technological landscape, staying competitive and secure demands a proactive approach to system migration. Whether you're seeking improved performance, enhanced security, or the benefits of new features, navigating a system migration successfully requires a well-structured and methodical approach. This comprehensive guide outlines the key stages of a systematic system migration process. From initial assessment and planning to continuous improvement, each step is designed to ensure a smooth transition, minimise disruptions, and harness the full potential of modern technologies. So, let's embark on this journey, where careful assessment, strategic planning, and meticulous execution pave the way for a seamless system migration that aligns with your organisation's evolving needs and goals.</p>

                            <div className="blog-div">
                                <h1 className="section-title">Why it’s essential</h1>

                                <p className="blog-p">A well-planned approach to system migration is an essential in  today's rapidly changing technology landscape. The intricacies of modern systems, coupled with the criticality of data security, necessitate a structured and methodical process. By following the outlined stages, organisations can gain a holistic understanding of their migration journey, mitigate potential risks, and ensure minimal disruptions to operations.</p>
                                <p className="blog-p">A systematic approach empowers teams to make informed decisions, allocate resources efficiently, and align the migration with overarching business objectives. Furthermore, it instils confidence in stakeholders, from end-users to leadership, that the transition will be smooth, reliable, and ultimately lead to a system that's not only updated, but also optimised for future growth and innovation.</p>
                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${systemMigration2BlogSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={systemMigration2Blog} alt="" className='normal-img' loading='lazy' />
                                </div>

                                <h1 className="section-title">Step 1: Assessment and Planning</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Understand the current state of the application - Document the existing architecture, technologies, dependencies, and any known issues.</li>
                                    <li className="blog-li">Identify the reasons for upgrading - Clearly define the goals and benefits you expect to achieve from the upgrade, such as improved performance, security, scalability, or new features.</li>
                                    <li className="blog-li">Define the scope - Determine which parts of the application will be upgraded and whether any components will be replaced or rewritten.</li>
                                    <li className="blog-li">Create a detailed project plan - Break down the upgrade process into phases or tasks, assign responsibilities, and set realistic timelines.</li>

                                </ul>
                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 2: Requirements Gathering</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Evaluate technology options - Research and choose modern technologies, frameworks, libraries, and tools that align with your upgrade goals and will support the long-term needs of the application.</li>
                                    <li className="blog-li">Consider future scalability and maintenance - Select technologies that are actively maintained, have a strong community, and can accommodate future changes.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 3: Technology and Framework Selection</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Evaluate technology options - Research and choose modern technologies, frameworks, libraries, and tools that align with your upgrade goals and will support the long-term needs of the application.</li>
                                    <li className="blog-li">Consider future scalability and maintenance - Select technologies that are actively maintained, have a strong community, and can accommodate future changes.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 4: Architecture and Design</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Design the upgraded architecture - Create a detailed architectural design that incorporates the chosen technologies and addresses any architectural challenges from the legacy system.</li>
                                    <li className="blog-li">Plan for data migration - Develop a strategy for migrating data from the old system to the new one while ensuring data integrity and minimal disruption.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 5: Development and Testing</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Incremental approach - Break down the upgrade into smaller, manageable increments and upgrade one component/module at a time. This minimises risk and allows for thorough testing at each step.</li>
                                    <li className="blog-li">Develop and test - Rewrite, refactor, or re-implement components according to the new architecture. Rigorously test each component to identify and fix bugs, ensure compatibility, and validate performance.</li>
                                    <li className="blog-li">Automated testing - Implement automated testing (unit tests, integration tests, and end-to-end tests) to ensure code quality and prevent regressions.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${systemMigration3BlogSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={systemMigration3Blog} alt="" className="normal-img" />
                                </div>
                                <h1 className="section-title">Step 6: Data Migration</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Plan and execute data migration - Migrate data from the legacy system to the upgraded one, ensuring data consistency, accuracy, and completeness.</li>
                                    <li className="blog-li">Data validation - Validate the migrated data to ensure it's correctly transferred and transformed in the new system.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 7: User Acceptance Testing (UAT)</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Involve stakeholders - Collaborate with users and stakeholders to conduct thorough testing of the upgraded application in a controlled environment.</li>
                                    <li className="blog-li">Address feedback - Address any issues, bugs, or usability concerns raised during UAT.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 8: Training and Documentation</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Provide training - Ensure that users, administrators, and support teams are trained on the new features and changes introduced by the upgrade.</li>
                                    <li className="blog-li">Update documentation - Revise user manuals, technical documentation, and support resources to reflect the changes in the upgraded application.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 9: Deployment and Go-Live</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Plan deployment - Prepare a deployment plan that outlines the steps for deploying the upgraded application to production.</li>
                                    <li className="blog-li">Rollout strategy - Consider a phased rollout or a gradual transition to minimise disruption and ensure a smooth go-live.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 10: Monitoring and Support</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Monitor performance - Continuously monitor the upgraded application for performance bottlenecks, errors, and other issues.</li>
                                    <li className="blog-li">Provide ongoing support - Offer technical support to address any post-go-live issues, and be prepared to make necessary adjustments based on real-world usage.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Step 11: Continuous Improvement</h1>

                                <ul className="blog-ul">
                                    <li className="blog-li">Gather feedback - Solicit feedback from users and stakeholders after the upgrade to identify areas for improvement and address any remaining concerns.</li>
                                    <li className="blog-li">Iterative approach - Plan for future updates and enhancements based on user feedback, emerging technologies, and changing business needs.Remember that communication, collaboration, and thorough testing are key throughout the entire upgrade process. Make sure to involve relevant stakeholders, maintain clear documentation, and have contingency plans in place to mitigate potential risks and challenges that may arise during the upgrade.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Conclusion</h1>

                                <p className="blog-p">In the realm of technology, adaptability and evolution are key to sustained success. The system migration process outlined here underscores the importance of a structured and strategic approach in navigating the ever-changing IT landscape. By embracing this methodical journey, organizations not only safeguard their operations but also unlock opportunities for growth and innovation. From the meticulous assessment and planning phase to the continuous improvement cycle, every step is designed to ensure a seamless transition that aligns with business goals. In today's digital age, where the only constant is change, a well-planned system migration isn't just an option; it's a necessity. So, as you embark on your migration journey, remember that every careful step taken will lead to a future where your systems are not only updated but also optimized for the challenges and opportunities that lie ahead.</p>

                            </div>

                            <div className="share-div">
                                <p className="share-p">Share on:</p>

                                <div className="socials">
                                    <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="System Migration-fb" />
                                    <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="System Migration-twitter" />
                                    <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="System Migration-linkedin" />
                                    <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="System Migration-whatsapp" />

                                </div>
                            </div>
                        </div>

                        <div className="blog-right">
                            <div className="form-container">
                                <h1 className="section-title">Enquire Now</h1>

                                <form onSubmit={onBeforeMsgSubmit} id='blog-form'>
                                    <TextField label="Name" type='text' variant="outlined" name='name' value={name} onChange={e => setName(e.target.value)} />
                                    <TextField label="Email" type='email' variant="outlined" name='email' value={email} onChange={e => setEmail(e.target.value)} />
                                    <TextField label="Phone" type='number' variant="outlined" name='phone' value={phone} onChange={e => setPhone(e.target.value)} />
                                    <TextField label="Message" type='text' multiline rows={4} variant="outlined" name='name' value={msg} onChange={e => setMsg(e.target.value)} />
                                    <LoadingButton
                                        className='contact-btn'
                                        type='submit' variant='contained'
                                        color='primary'
                                        style={{ color: "#ffffff" }}
                                        endIcon={<SendRoundedIcon className='send-icon' />}
                                        loading={loading}
                                        loadingPosition="end"
                                    >
                                        <span>Submit</span>

                                    </LoadingButton>
                                </form>
                            </div>

                            {ads.length > 0 ?
                                <>
                                    {ads.map(ad => (
                                        <>
                                            {ad.takeToContact ?
                                                <div className="ad" style={{ cursor: "pointer" }} onClick={() => goContact(ad.contactSubject)}>
                                                    <img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' />
                                                </div>
                                                :
                                                <>
                                                    {ad.link ?
                                                        <div className="ad">
                                                            <Link to={ad.link}><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                        :
                                                        <div className="ad">
                                                            <Link to='/'><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                    }
                                                </>

                                            }
                                        </>
                                    ))}
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default SystemMigration