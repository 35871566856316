import Alerts from './Alert';
import Home from './components/home/Home';
import "./App.css"

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ScrollTop from "./ScrollTop";
import About from './components/about/About';
import Trainings from './components/trainings/Trainings';
import Solutions from './components/solutions/Solutions';
import Contact from './components/contact/Contact';
import CreateBlog from './components/create-blog/CreateBlog';
import Work from './components/work/Work';
import ItilFoundationLanding from './components/landing-pages/ItilFoundationLanding';
import GroupOffers from './components/group-offers/GroupOffers';
import { useEffect } from 'react'



// COURSE PAGES
import ItilFoundation from './components/course-page/ItilFoundation';
import ItilCDS from './components/course-page/ItilCDS';
import ItilDSV from './components/course-page/ItilDSV';
import ItilHVIT from './components/course-page/ItilHVIT';
import ItilDPI from './components/course-page/ItilDPI';
import ItilDITS from './components/course-page/ItilDITS';
import Cscu from './components/course-page/CSCU';
import CEH from './components/course-page/CEH';
import CISA from './components/course-page/CISA';
import CISM from './components/course-page/CISM';
import ISO27001Foundation from './components/course-page/ISO27001Foundation';
import ISO27001Implementer from './components/course-page/ISO27001Implementer';
import ISO27001Auditor from './components/course-page/ISO27001Auditor';
import CobitFoundation from './components/course-page/CobitFoundation';
import CobitImplementer from './components/course-page/CobitImplementer';
import CobitAssessor from './components/course-page/CobitAssessor';
import CGEIT from './components/course-page/CGEIT';
import Prince2Foundation from './components/course-page/Prince2Foundation';
import Prince2Practitioner from './components/course-page/Prince2Practitioner';
import CBAP from './components/course-page/CBAP';
import DevopsFundamentals from './components/course-page/DevopsFundamentals';
import DevopsLeadership from './components/course-page/DevopsLeadership';
import Scrum1 from './components/course-page/Scrum1';
import Scrum2 from './components/course-page/Scrum2';
import Togaf from './components/course-page/Togaf';
import ISO22301Auditor from './components/course-page/ISO22301Auditor';
import ISO22301Implementer from './components/course-page/ISO22301Implementer';
import ISTQBCtfl from './components/course-page/ISTQBCtfl';
import ISTQBAgile from './components/course-page/ISTQBAgile';
import ISTQBAdvanced from './components/course-page/ISTQBAdvanced';
import CRISC from './components/course-page/CRISC';
import ISO31000 from './components/course-page/ISO31000';
import GDPR from './components/course-page/GDPR';
import AgileFundamentals from './components/course-page/AgileFundamentals';
import ScrumMaster from './components/course-page/ScrumMaster';
import LeadingSafe from './components/course-page/LeadingSafe';
import ImplementingSafe from './components/course-page/ImplementingSafe';



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// BLOG PAGES

import Ddos from "./components/blog-page/Ddos"
import BaselineBlog from './components/blog-page/BaselineBlog';
import VulnerabilityBlog from './components/blog-page/VulnerabilityBlog';
import SystemMigration from './components/blog-page/SystemMigration';
import DTEBlog from './components/blog-page/DTEBlog';
import BaseBlog from './components/blog-page/BaseBlog';


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// SOLUTION PAGES

import StrategyFormulation from './components/single-solution/StrategyFormulation';
import SOC from './components/soc/SOC';
import GlobalFrameworks from './components/single-solution/GlobalFrameworks';
import BaselineMaturity from './components/single-solution/BaselineMaturity';
import ServiceDesk from './components/single-solution/ServiceDesk';
import BusinessContinuity from './components/single-solution/BusinessContinuity';
import BusinessProcess from './components/single-solution/BusinessProcess';
import DigitalTransformation from './components/single-solution/DigitalTransformation';
import ICTAudits from './components/single-solution/ICTAudits';
import ISO20000 from './components/single-solution/ISO20000';
import ProjectAdvisory from './components/single-solution/ProjectAdvisory';
import AgileCoaching from './components/single-solution/AgileCoaching';
import Vulnerability from './components/single-solution/Vulnerability';
import Penetration from './components/single-solution/Penetration';
import ISO27001 from './components/single-solution/ISO27001';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import loader from "./images/loader.gif"





function App() {


  // LOAD DISAPPEAR USEEFFECT

  useEffect(() => {
    const loader = document.querySelector('.loader-wrapper')

    function vanish() {
      loader.classList.add('disappear')
    }


    window.addEventListener('load', vanish)
  }, [])


  ///////////////////////////////////////////////////////////////////////////////////////////////




  return (
    <div className="">
      <div className="loader-wrapper">
        <img src={loader} alt="" />
      </div>
      <Alerts />
      <Router>
        <ScrollTop />
        <Routes>
          <Route exact path='/strategy_formulation' element={<StrategyFormulation />} />
          <Route exact path='/soc' element={<SOC />} />
          <Route exact path='/global_frameworks' element={<GlobalFrameworks />} />
          <Route exact path='/baseline_maturity' element={<BaselineMaturity />} />
          <Route exact path='/service_desk' element={<ServiceDesk />} />
          <Route exact path='/business_continuity' element={<BusinessContinuity />} />
          <Route exact path='/business_process' element={<BusinessProcess />} />
          <Route exact path='/digital_transformation' element={<DigitalTransformation />} />
          <Route exact path='/ict_audits' element={<ICTAudits />} />
          <Route exact path='/iso_20000' element={<ISO20000 />} />
          <Route exact path='/project_advisory' element={<ProjectAdvisory />} />
          <Route exact path='/agile_coaching' element={<AgileCoaching />} />
          <Route exact path='/vulnerability' element={<Vulnerability />} />
          <Route exact path='/pentest' element={<Penetration />} />
          <Route exact path='/implement_iso27001' element={<ISO27001 />} />
          <Route exact path='/blog_page' element={<BaseBlog />} />
          <Route exact path='/ddos_blog' element={<Ddos />} />
          <Route exact path='/baseline_blog' element={<BaselineBlog />} />
          <Route exact path='/vulnerability_blog' element={<VulnerabilityBlog />} />
          <Route exact path='/system_migration_blog' element={<SystemMigration />} />
          <Route exact path='/dte_blog' element={<DTEBlog />} />
          <Route exact path='/itil_foundation' element={<ItilFoundation />} />
          <Route exact path='/itil_cds' element={<ItilCDS />} />
          <Route exact path='/itil_dsv' element={<ItilDSV />} />
          <Route exact path='/itil_hvit' element={<ItilHVIT />} />
          <Route exact path='/itil_dpi' element={<ItilDPI />} />
          <Route exact path='/itil_dits' element={<ItilDITS />} />
          <Route exact path='/cscu' element={<Cscu />} />
          <Route exact path='/ceh' element={<CEH />} />
          <Route exact path='/cisa' element={<CISA />} />
          <Route exact path='/cism' element={<CISM />} />
          <Route exact path='/iso_27001' element={<ISO27001Foundation />} />
          <Route exact path='/iso_27001_implementer' element={<ISO27001Implementer />} />
          <Route exact path='/iso_27001_auditor' element={<ISO27001Auditor />} />
          <Route exact path='/cobit_foundation' element={<CobitFoundation />} />
          <Route exact path='/cobit_implementer' element={<CobitImplementer />} />
          <Route exact path='/cobit_assessor' element={<CobitAssessor />} />
          <Route exact path='/cgeit' element={<CGEIT />} />
          <Route exact path='/prince2_foundation' element={<Prince2Foundation />} />
          <Route exact path='/prince2_practitioner' element={<Prince2Practitioner />} />
          <Route exact path='/cbap' element={<CBAP />} />
          <Route exact path='/devops' element={<DevopsFundamentals />} />
          <Route exact path='/devops_leadership' element={<DevopsLeadership />} />
          <Route exact path='/scrum_1' element={<Scrum1 />} />
          <Route exact path='/scrum_2' element={<Scrum2 />} />
          <Route exact path='/togaf' element={<Togaf />} />
          <Route exact path='/iso22301' element={<ISO22301Auditor />} />
          <Route exact path='/iso22301_implementer' element={<ISO22301Implementer />} />
          <Route exact path='/istqb_foundation' element={<ISTQBCtfl />} />
          <Route exact path='/istqb_agile' element={<ISTQBAgile />} />
          <Route exact path='/istqb_advanced' element={<ISTQBAdvanced />} />
          <Route exact path='/crisc' element={<CRISC />} />
          <Route exact path='/iso31000' element={<ISO31000 />} />
          <Route exact path='/gdpr' element={<GDPR />} />
          <Route exact path='/agile_fundamentals' element={<AgileFundamentals />} />
          <Route exact path='/csm' element={<ScrumMaster />} />
          <Route exact path='/leading_safe' element={<LeadingSafe />} />
          <Route exact path='/implementing_safe' element={<ImplementingSafe />} />
          <Route exact path='/work_with_us' element={<Work />} />
          <Route exact path='/create_blog' element={<CreateBlog />} />
          <Route exact path='/offers' element={<GroupOffers />} />
          <Route exact path='/itil_foundation_landing' element={<ItilFoundationLanding />} />
          <Route exact path='/contact_us' element={<Contact />} />
          <Route exact path='/solutions' element={<Solutions />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/trainings' element={<Trainings />} />
          <Route exact path='/home' element={<Home />} />
          <Route exact path='/' element={<Home />} />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
