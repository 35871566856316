import { useState, useEffect } from 'react'
import "./BlogPage.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ddos from "../../images/ddos.jpg"
import ddosSmall from "../../images/ddos-small.png"
import recognizeDdos from "../../images/recognize-ddos.jpg"
import recognizeDdosSmall from "../../images/recognize-ddos-small.jpg"
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, useNavigate } from 'react-router-dom';
import dotGrid from "../../images/dot-grid.png"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useDispatch } from "react-redux"
import axios from 'axios';
import TextField from '@mui/material/TextField';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"


const Ddos = () => {

    // USESTATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [ads, setAds] = useState([])


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');



    // USEEFFECTS


    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')


        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    // LOAD BLOG ADS

    useEffect(() => {
        axios.get('https://www.ict-mentors.com/api/get_blog_ads')
            .then(res => {
                setAds(res.data)
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }, [])

    ////////////////////////////////////////////////////////////////

    // LOAD DISAPPEAR USEEFFECT

    useEffect(() => {
        const loader = document.querySelector('.loader-wrapper')

        function vanish() {
            loader.classList.add('disappear')
        }


        window.addEventListener('load', vanish)
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.blog-p')
        const appearLeftImg = document.querySelectorAll('.img-container')
        const appearLeftAuthor = document.querySelectorAll('.author-date')
        const appearRightAd = document.querySelectorAll('.ad')
        const appearRights = document.querySelectorAll('.section-title')
        const appearRightSocials = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .socials')
        const appearRightForm = document.querySelectorAll('.blogpage-container .blog-right .form-container form')
        const appearLeftShare = document.querySelectorAll('.blogpage-container .share-div')
        const appearDowns = document.querySelectorAll('.blogpage-container .blog-ul .blog-li')
        const appearUps = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .blog-title')



        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftImgOptions = {
            threshold: 0.2,
        }

        const appearLeftImgObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftImgObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftImgOptions)

        appearLeftImg.forEach(appearLeft => {
            appearLeftImgObserver.observe(appearLeft)
        })

        const appearLeftAuthorOptions = {
            threshold: 0.2,
        }

        const appearLeftAuthorObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftAuthorObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftAuthorOptions)

        appearLeftAuthor.forEach(appearLeft => {
            appearLeftAuthorObserver.observe(appearLeft)
        })

        const appearLeftShareOptions = {
            threshold: 0.2,
        }

        const appearLeftShareObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftShareObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftShareOptions)

        appearLeftShare.forEach(appearLeft => {
            appearLeftShareObserver.observe(appearLeft)
        })

        const appearRightAdOptions = {
            threshold: 0.2,
        }

        const appearRightAdObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightAdObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightAdOptions)

        appearRightAd.forEach(appearRight => {
            appearRightAdObserver.observe(appearRight)
        })

        const appearRightFormOptions = {
            threshold: 0.2,
        }

        const appearRightFormObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFormObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFormOptions)

        appearRightForm.forEach(appearRight => {
            appearRightFormObserver.observe(appearRight)
        })

        const appearRightSocialsOptions = {
            threshold: 0.2,
        }

        const appearRightSocialsObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightSocialsObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightSocialsOptions)

        appearRightSocials.forEach(appearRight => {
            appearRightSocialsObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearUpOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS


    // SEND ENQUIRY

    const onBeforeMsgSubmit = (e) => {
        e.preventDefault()


        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (msg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "msg": msg,
            }
    
    
            onSubmitMsg(payload)
        }

        
    }


    const onSubmitMsg = (payload) => {

        window.dataLayer.push({event: 'inquiry_submission'})

        ReactGa.event("inquiry_submission", {form_id: "DDOS Blog Form"})

        axios.post("https://www.ict-mentors.com/api/send_mail_blog", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setLoading(false)

                setName('')
                setEmail('')
                setPhone('')
                setMsg('')
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }



    ///////////////////////////////////////////////////////


    // SHARE iN SOCIALS

    const shareFb = () => {
        // const blogLink = encodeURI(window.location.href)
        const blogLink = encodeURI('https://ict-mentors.com/ddos_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.facebook.com/share.php?u=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DDOS", social_platform: "Facebook"})
    }

    const shareTwitter = () => {
        const blogLink = encodeURI('https://ict-mentors.com/ddos_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.twitter.com/share?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DDOS", social_platform: "Twitter"})
    }

    const shareLinkedin = () => {
        const blogLink = encodeURI('https://ict-mentors.com/ddos_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DDOS", social_platform: "Linkedin"})

    }

    const shareWhatsapp = () => {
        const blogLink = encodeURI('https://ict-mentors.com/ddos_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article:')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)


        var message = `${msg} \n ${blogLink}`

        // Opening URL
        window.open(`https://api.whatsapp.com/send?text=${message}`);
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "DDOS", social_platform: "Whatsapp"})

    }


    //////////////////////////////////////////////////////////


    // Navigate to contact us page

    const goContact = (subject) => {
        navigate(`/contact_us?subject=${subject}`)
    }

    /////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        <ThemeProvider theme={theme}>
            <div className='blogpage-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>

                <Helmet>
                    <title>Mitigating the impact of recent DDoS attacks on Kenya</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="blog-section">
                    <div className="blog-top">
                        <div className="title-socials">
                            <h1 className="blog-title">Mitigating the impact of recent DDoS attacks on Kenya</h1>

                            <div className="socials">
                                <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="Ddos-fb" />
                                <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="Ddos-twitter" />
                                <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="Ddos-linkedin" />
                                <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="Ddos-whatsapp" />

                            </div>
                        </div>

                        <div className="author-date">
                            <p className="author">By: John Mathenge</p>
                            <p className="date">Published: 28th August 2023</p>
                        </div>

                    </div>

                    <div className="blog-body">
                        <div className="blog-left">
                            <div className="img-container blur-div" style={{ backgroundImage: `url(${ddosSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={ddos} alt="" className="main-img" loading='lazy' />
                            </div>

                            <p className="blog-p">The recent Distributed Denial of Service (DDOS) attacks have affected critical sectors of the Kenyan economy, disrupting several nationwide services. As a cybersecurity company dedicated to safeguarding your interests, we feel it is essential to share relevant information to help you understand the situation and take proactive measures to protect your assets.</p>

                            <div className="blog-div">
                                <h1 className="section-title">Overview of the DDoS Attacks</h1>

                                <p className="blog-p">In the past 24 hours, Kenya has experienced a series of malicious DDoS attacks aimed at disrupting the operations of banks, government services, and other vital sectors of the economy. These attacks involved overwhelming the targeted systems with a massive influx of traffic, rendering them inaccessible to legitimate users. The motive behind these attacks is yet to be fully determined, but we must remain vigilant as the situation unfolds.DDoS attacks can have severe consequences for businesses and citizens alike. Some potential impacts include:</p>

                                <ul className="blog-ul">
                                    <li className="blog-li">Financial Loss: Businesses may suffer significant financial losses due to downtime and the inability to conduct online transactions.</li>
                                    <li className="blog-li">Service Disruptions: Critical government services may be unavailable, hindering citizens from accessing essential resources.</li>
                                    <li className="blog-li">Reputational Damage: Organizations that experience prolonged outages may face reputational damage, impacting customer trust and loyalty.</li>
                                    <li className="blog-li">Economic Impact: The disruptions to the critical sectors of the economy could have broader economic repercussions.</li>
                                </ul>
                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${recognizeDdosSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={recognizeDdos} alt="" className='normal-img' loading='lazy' />
                                </div>

                                <h1 className="section-title">Recognizing DDoS Attack Signs</h1>

                                <p className="blog-p">As citizens and businesses in Kenya, it is crucial to be aware of the signs of a DDOS attack. Some common indicators include:</p>

                                <ul className="blog-ul">
                                    <li className="blog-li">Sluggish Website Loading Times: Noticeably slower website performance and frequent timeouts.</li>
                                    <li className="blog-li">Inaccessible Services: The inability to access online banking, government portals, or other critical services.</li>
                                    <li className="blog-li">Increased Spam or Phishing Emails: A surge in suspicious emails attempting to trick users into divulging sensitive information.</li>
                                </ul>
                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Protective Measures and Proactive Steps</h1>

                                <p className="blog-p">We urge you to take the following steps to safeguard your assets and minimize the impact of potential DDOS attacks:</p>

                                <ul className="blog-ul">
                                    <li className="blog-li">Implement DDOS Protection Solutions: Invest in reliable DDOS protection services and solutions to detect and mitigate attacks in real-time.</li>
                                    <li className="blog-li">Load Balancing: Distribute incoming traffic evenly across multiple servers to ensure uninterrupted service availability.</li>
                                    <li className="blog-li">Security Audits: Conduct regular security audits to identify and address vulnerabilities in your IT infrastructure.</li>
                                    <li className="blog-li">Incident Response Plan: Develop and practice an incident response plan to handle cyber emergencies efficiently.</li>
                                    <li className="blog-li">Employee Awareness Training: Train your staff to recognize and report suspicious activities, such as phishing attempts.</li>
                                    <li className="blog-li">Deploy instant Detection & Response mechanisms (SIEM) either in-house or managed (SOC) to stay ahead of the threats.</li>
                                </ul>
                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Collaboration and Reporting</h1>

                                <p className="blog-p">We encourage all affected parties to collaborate with relevant authorities and share any information that may aid in the investigation and resolution of these attacks. Reporting such incidents will contribute to the collective effort to strengthen the nation's cybersecurity defenses.</p>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Our Commitment to You</h1>

                                <p className="blog-p">At ICT Mentors Solutions Limited, we stand ready to assist you during these challenging times. Our expert team is equipped with the latest tools, Real-time cyber threats monitoring (SOC),knowledge to protect your digital assets and provide immediate support in the event of an attack.</p>
                            </div>

                            <div className="share-div">
                                <p className="share-p">Share on:</p>

                                <div className="socials">
                                    <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="Ddos-fb" />
                                    <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="Ddos-twitter" />
                                    <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="Ddos-linkedin" />
                                    <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="Ddos-whatsapp" />
                                </div>
                            </div>
                        </div>

                        <div className="blog-right">
                            <div className="form-container">
                                <h1 className="section-title">Enquire Now</h1>

                                <form onSubmit={onBeforeMsgSubmit} id='blog-form'>
                                    <TextField label="Name" type='text' variant="outlined" name='name' value={name} onChange={e => setName(e.target.value)} />
                                    <TextField label="Email" type='email' variant="outlined" name='email' value={email} onChange={e => setEmail(e.target.value)} />
                                    <TextField label="Phone" type='number' variant="outlined" name='phone' value={phone} onChange={e => setPhone(e.target.value)} />
                                    <TextField label="Message" type='text' multiline rows={4} variant="outlined" name='name' value={msg} onChange={e => setMsg(e.target.value)} />
                                    <LoadingButton
                                        className='contact-btn'
                                        type='submit' variant='contained'
                                        color='primary'
                                        style={{ color: "#ffffff" }}
                                        endIcon={<SendRoundedIcon className='send-icon' />}
                                        loading={loading}
                                        loadingPosition="end"
                                    >
                                        <span>Submit</span>

                                    </LoadingButton>
                                </form>
                            </div>

                            {ads.length > 0 ?
                                <>
                                    {ads.map(ad => (
                                        <>
                                            {ad.takeToContact ?
                                                <div className="ad" style={{ cursor: "pointer" }} onClick={() => goContact(ad.contactSubject)}>
                                                    <img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' />
                                                </div>
                                                :
                                                <>
                                                    {ad.link ?
                                                        <div className="ad">
                                                            <Link to={ad.link}><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                        :
                                                        <div className="ad">
                                                            <Link to='/'><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                    }
                                                </>

                                            }
                                        </>
                                    ))}
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default Ddos