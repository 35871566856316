import { useState, useEffect } from 'react'
import "./BlogPage.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import blog4Img from "../../images/blog4.jpg"
import blog4ImgSmall from "../../images/blog4-small.png"
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import dotGrid from "../../images/dot-grid.png"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useDispatch } from "react-redux"
import axios from 'axios';
import TextField from '@mui/material/TextField';
import baseline2Blog from "../../images/baseline2-blog.jpg"
import baseline2BlogSmall from "../../images/baseline2-blog-small.jpg"
import assessmentReport from "../../images/assessment-report.jpg"
import assessmentReportSmall from "../../images/assessment-report-small.jpg"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const BaselineBlog = () => {

    // USESTATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [ads, setAds] = useState([])



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');



    // USEEFFECTS



    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')


        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    // LOAD BLOG ADS

    useEffect(() => {
        axios.get('https://www.ict-mentors.com/api/get_blog_ads')
            .then(res => {
                setAds(res.data)
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }, [])

    ////////////////////////////////////////////////////////////////


    // LOAD DISAPPEAR USEEFFECT

    useEffect(() => {
        const loader = document.querySelector('.loader-wrapper')

        function vanish() {
            loader.classList.add('disappear')
        }


        window.addEventListener('load', vanish)
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.blog-p')
        const appearLeftImg = document.querySelectorAll('.img-container')
        const appearLeftAuthor = document.querySelectorAll('.author-date')
        const appearRightAd = document.querySelectorAll('.ad')
        const appearRights = document.querySelectorAll('.section-title')
        const appearRightSocials = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .socials')
        const appearRightForm = document.querySelectorAll('.blogpage-container .blog-right .form-container form')
        const appearLeftShare = document.querySelectorAll('.blogpage-container .share-div')
        const appearDowns = document.querySelectorAll('.blogpage-container .blog-ul .blog-li')
        const appearUps = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .blog-title')



        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftImgOptions = {
            threshold: 0.2,
        }

        const appearLeftImgObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftImgObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftImgOptions)

        appearLeftImg.forEach(appearLeft => {
            appearLeftImgObserver.observe(appearLeft)
        })

        const appearLeftAuthorOptions = {
            threshold: 0.2,
        }

        const appearLeftAuthorObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftAuthorObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftAuthorOptions)

        appearLeftAuthor.forEach(appearLeft => {
            appearLeftAuthorObserver.observe(appearLeft)
        })

        const appearLeftShareOptions = {
            threshold: 0.2,
        }

        const appearLeftShareObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftShareObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftShareOptions)

        appearLeftShare.forEach(appearLeft => {
            appearLeftShareObserver.observe(appearLeft)
        })

        const appearRightAdOptions = {
            threshold: 0.2,
        }

        const appearRightAdObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightAdObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightAdOptions)

        appearRightAd.forEach(appearRight => {
            appearRightAdObserver.observe(appearRight)
        })

        const appearRightFormOptions = {
            threshold: 0.2,
        }

        const appearRightFormObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFormObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFormOptions)

        appearRightForm.forEach(appearRight => {
            appearRightFormObserver.observe(appearRight)
        })

        const appearRightSocialsOptions = {
            threshold: 0.2,
        }

        const appearRightSocialsObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightSocialsObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightSocialsOptions)

        appearRightSocials.forEach(appearRight => {
            appearRightSocialsObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearUpOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS


    // SEND ENQUIRY

    const onBeforeMsgSubmit = (e) => {
        e.preventDefault()


        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (msg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "msg": msg,
            }
    
    
            onSubmitMsg(payload)
        }

        
    }


    const onSubmitMsg = (payload) => {

        window.dataLayer.push({event: 'inquiry_submission'})

        ReactGa.event("inquiry_submission", {form_id: "Baseline Blog Form"})

        axios.post("https://www.ict-mentors.com/api/send_mail_blog", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setLoading(false)

                setName('')
                setEmail('')
                setPhone('')
                setMsg('')
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }



    ///////////////////////////////////////////////////////


    // SHARE iN SOCIALS

    const shareFb = () => {
        // const blogLink = encodeURI(window.location.href)
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.facebook.com/share.php?u=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Baseline Assessment", social_platform: "Facebook"})
    }

    const shareTwitter = () => {
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.twitter.com/share?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Baseline Assessment", social_platform: "Twitter"})
    }

    const shareLinkedin = () => {
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Baseline Assessment", social_platform: "Linkedin"})
    }

    const shareWhatsapp = () => {
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article:')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)


        var message = `${msg} \n ${blogLink}`

        // Opening URL
        window.open(`https://api.whatsapp.com/send?text=${message}`);
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Baseline Assessment", social_platform: "Whatsapp"})
    }


    //////////////////////////////////////////////////////////


    // Navigate to contact us page

    const goContact = (subject) => {
        navigate(`/contact_us?subject=${subject}`)
    }

    /////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        <ThemeProvider theme={theme}>
            <div className='blogpage-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>
                <Helmet>
                    <title>Why Perform a Baseline & Maturity Assessment</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="blog-section">
                    <div className="blog-top">
                        <div className="title-socials">
                            <h1 className="blog-title">Why Perform a Baseline & Maturity Assessment</h1>

                            <div className="socials">
                                <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="Baseline Maturity-fb" />
                                <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="Baseline Maturity-twitter" />
                                <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="Baseline Maturity-linkedin" />
                                <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="Baseline Maturity-whatsapp" />
                            </div>
                        </div>

                        <div className="author-date">
                            <p className="author">By: John Mathenge</p>
                            <p className="date">Published: 28th August 2023</p>
                        </div>

                    </div>

                    <div className="blog-body">
                        <div className="blog-left">
                            <div className="img-container blur-div" style={{ backgroundImage: `url(${blog4ImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={blog4Img} alt="" className="main-img" loading='lazy' />
                            </div>

                            <p className="blog-p">In the dynamic realm of IT, achieving excellence isn't just a goal—it's a necessity. That's where Baseline Assessment comes into play. A transformative Baseline Assessment, goes beyond traditional IT evaluations. The assessment applies global best practices to scan your organisation's IT environment, covering IT Service Management, Information Security, Governance, business continuity and many more. It's the ultimate compass that not only answers the critical questions "Where Are We Now?" and "What Do We Need To Do?" but also charts a course towards achieving your business goals.</p>

                            <div className="blog-div">
                                <h1 className="section-title">How it works</h1>

                                <p className="blog-p">An IT Baseline Assessment is a systematic process aimed at aligning an organisation's IT environment with its business objectives and global best practices. It involves initial scoping and objective setting, followed by data collection and assessment of current IT practices against predefined criteria. Gap analysis is conducted to identify deficiencies and prioritise them based on impact and risk. The process culminates in the creation of a comprehensive assessment report, outlining findings, recommendations, and a roadmap for improvement. Implementation of recommended changes, continuous monitoring, and periodic reassessment are integral to the iterative process, ensuring ongoing alignment with business goals and integration of best practices for improved IT efficiency and effectiveness.</p>

                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${baseline2BlogSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={baseline2Blog} alt="" className='normal-img' loading='lazy' />
                                </div>

                                <h1 className="section-title">Benefits of Baseline Assessment</h1>

                                <p className="blog-p">There are a couple of reasons as to why you should perform a baseline assessment:</p>

                                <ul className="blog-ul">
                                    <li className="blog-li">Improved Service Quality - The assessment identifies areas where IT service management processes can be enhanced. By addressing these gaps, organisations can improve the quality and consistency of their services, leading to higher customer satisfaction and loyalty.</li>
                                    <li className="blog-li">Efficient Resource Allocation - Assessing ITIL maturity helps organisations identify redundancies, inefficiencies, and areas of resource waste. This enables better allocation of resources, leading to cost savings and optimised utilisation.</li>
                                    <li className="blog-li">Enhanced Risk Management - The assessment highlights vulnerabilities and risks in IT service management processes. Addressing these risks helps mitigate potential disruptions and security breaches, improving overall risk management.</li>
                                    <li className="blog-li">Streamlined Processes - Identifying areas for improvement leads to the streamlining of processes. This results in reduced manual interventions, quicker response times, and more efficient service delivery.</li>
                                    <li className="blog-li">Better Change Management - A mature ITIL framework supports effective change management processes. Organisations can assess how well they manage changes, reduce downtime, and improve overall system stability.</li>
                                    <li className="blog-li">Enhanced Communication - Clear communication channels are crucial for successful service management. An assessment helps identify communication gaps, ensuring that information flows smoothly between teams and stakeholders.</li>
                                    <li className="blog-li">Alignment with Business Goals - An ITIL maturity assessment helps organisations align their IT service management practices with their business goals and objectives. This alignment ensures that IT supports and contributes to the overall business strategy.</li>
                                    <li className="blog-li">Continuous Improvement Culture - Conducting regular assessments encourages a culture of continuous improvement. Teams become more proactive in identifying opportunities for enhancement and adopting best practices.</li>
                                    <li className="blog-li">Measurable Metrics - An assessment provides a baseline for measuring improvements over time. Quantifiable metrics can track progress in terms of service quality, response times, incident resolution, and more.</li>
                                </ul>
                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${assessmentReportSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={assessmentReport} alt="" className="normal-img" loading='lazy' />
                                </div>
                                <h1 className="section-title">The Assessment Report</h1>

                                <p className="blog-p">The culmination of a Baseline Assessment is the comprehensive assessment report, a vital document that serves as a roadmap to IT excellence. This report not only provides a detailed overview of the organisation's current IT landscape, highlighting strengths and weaknesses, but it also offers actionable insights and recommendations for improvement. It's not just about identifying problems; it's about equipping organisations with a clear vision and practical steps to align their IT environment with global best practices and business goals. This invaluable resource empowers organisations to make informed decisions, allocate resources efficiently, and continually evolve their IT strategies to stay competitive in a rapidly changing technological landscape.</p>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Getting Started</h1>

                                <p className="blog-p">To get started you need the right partner to guide you throughout the entire journey. This is a critical decision to achieve success at the end of the road. A trustworthy partner should have the needed expertise, commitment, and a dedication to helping the client achieve their IT goals. They should offer more than just a service, serving as a strategic ally with a proven record of excellence in IT consultancy. ICT Mentors Solutions has a wealth of experience and a deep understanding of industry best practices.We are poised to guide your organisation toward IT excellence. Our professionals possess the expertise to ensure your IT environment seamlessly aligns with both your business objectives and global standards. We bring not only knowledge but also a genuine commitment to your success. When you choose ICT Mentors Solutions, you're selecting a partner uniquely suited to accompany you on your journey to IT excellence.</p>

                            </div>

                            <div className="blog-div">
                                <Link to='/baseline_maturity'>
                                    <Button variant='contained' color='secondary' className='cta-btn' id='Baseline Maturity'>Get Started</Button>
                                </Link>
                            </div>

                            <div className="share-div">
                                <p className="share-p">Share on:</p>

                                <div className="socials">
                                    <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="Baseline Maturity-fb" />
                                    <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="Baseline Maturity-twitter" />
                                    <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="Baseline Maturity-linkedin" />
                                    <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="Baseline Maturity-whatsapp" />
                                </div>
                            </div>
                        </div>

                        <div className="blog-right">
                            <div className="form-container">
                                <h1 className="section-title">Enquire Now</h1>

                                <form onSubmit={onBeforeMsgSubmit} id='blog-form'>
                                    <TextField label="Name" type='text' variant="outlined" name='name' value={name} onChange={e => setName(e.target.value)} />
                                    <TextField label="Email" type='email' variant="outlined" name='email' value={email} onChange={e => setEmail(e.target.value)} />
                                    <TextField label="Phone" type='number' variant="outlined" name='phone' value={phone} onChange={e => setPhone(e.target.value)} />
                                    <TextField label="Message" type='text' multiline rows={4} variant="outlined" name='name' value={msg} onChange={e => setMsg(e.target.value)} />
                                    <LoadingButton
                                        className='contact-btn'
                                        type='submit' variant='contained'
                                        color='primary'
                                        style={{ color: "#ffffff" }}
                                        endIcon={<SendRoundedIcon className='send-icon' />}
                                        loading={loading}
                                        loadingPosition="end"
                                    >
                                        <span>Submit</span>

                                    </LoadingButton>
                                </form>
                            </div>

                            {ads.length > 0 ?
                                <>
                                    {ads.map(ad => (
                                        <>
                                            {ad.takeToContact ?
                                                <div className="ad" style={{ cursor: "pointer" }} onClick={() => goContact(ad.contactSubject)}>
                                                    <img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' />
                                                </div>
                                                :
                                                <>
                                                    {ad.link ?
                                                        <div className="ad">
                                                            <Link to={ad.link}><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                        :
                                                        <div className="ad">
                                                            <Link to='/'><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                    }
                                                </>

                                            }
                                        </>
                                    ))}
                                </>
                                :
                                <></>
                            }

                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default BaselineBlog