import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as AlertProvider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import rootReducer from "./reducers"
import { Provider } from "react-redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import ReactGa from "react-ga4"


// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//     gtmId: 'GTM-WK28SHS5'
// }

// TagManager.initialize(tagManagerArgs)

// Send pageview with a custom path
ReactGa.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

let store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

const alertOptions = {
  timeout: 5000,
  position: "top center"
}

// Load Google Analytics

ReactGa.initialize("G-C98MW9GPV3")




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <App />
      </AlertProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
