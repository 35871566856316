import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/servicedesk-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/baseline.jpg"
import img1Small from "../../images/baseline-small.jpg"
import img2 from "../../images/happy-blackman1.jpg"
import img2Small from "../../images/happy-blackman1-small.jpg"
import img3 from "../../images/happy-blackman2.jpg"
import img3Small from "../../images/happy-blackman2-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const ServiceDesk = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Service Desk"})
        navigate('/contact_us?subject=Service Desk Deployment and Management')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Service Desk Deployment & Management</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Service Desk Deployment & Management</h1>
                            <p className="solution-p appear-right">We work with customers to establish a single point of contact for all IT service provision integrating incident, problem, change, service requests, configuration management and other key practices. Through automation, self- service and comprehensive metrics and continuous improvement, we enable IT productivity optimization and customer service operational efficiency. </p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Improved Service Quality</strong> - ITIL emphasizes the delivery of high-quality services. Implementing ITIL best practices can help ensure that IT services meet or exceed customer expectations. This can lead to higher customer satisfaction and loyalty.</li>
                                <li className="values-li appear-right"><strong>Increased Efficiency</strong> - ITIL provides a framework for streamlining processes and workflows. By following ITIL practices, businesses can reduce operational inefficiencies, minimize service disruptions, and improve the overall efficiency of their IT service delivery.</li>
                                <li className="values-li appear-left"><strong>Cost Reduction</strong> - ITIL encourages cost-effective service management. By optimizing processes and resources, businesses can reduce operational costs and avoid unnecessary expenditures. For example, efficient incident management can help minimize downtime and its associated costs.</li>
                                <li className="values-li appear-right"><strong>Better Change Management</strong> - ITIL's change management processes help organizations implement changes in a controlled and predictable manner. This can reduce the risk of service disruptions and errors that could impact the business.</li>
                                <li className="values-li appear-left"><strong>Enhanced Decision-Making</strong> - ITIL promotes data-driven decision-making through the collection and analysis of service metrics and Key Performance Indicators (KPIs). This enables businesses to make informed decisions about resource allocation, service improvements, and strategic planning.</li>
                                <li className="values-li appear-right"><strong>Improved Accountability</strong> - ITIL defines roles and responsibilities within the IT service management process, which helps establish clear lines of accountability. This can lead to better governance and more effective oversight of IT operations.</li>
                                <li className="values-li appear-left"><strong>Scalability and Flexibility</strong> - ITIL is designed to be scalable, allowing businesses to adapt its principles and practices to meet their specific needs and growth requirements. This flexibility is valuable for organizations of various sizes and industries.</li>
                                <li className="values-li appear-right"><strong>Alignment with Business Goals</strong> - ITIL emphasizes the alignment of IT services with business objectives. This ensures that IT investments and efforts are directed towards supporting the business's strategic goals and priorities.</li>
                                <li className="values-li appear-left"><strong>Compliance and Risk Management</strong> - ITIL includes risk management and compliance components, which help organizations identify and mitigate potential risks and ensure that they adhere to regulatory requirements.</li>
                                <li className="values-li appear-right"><strong>Continuous Improvement</strong> - ITIL promotes a culture of continuous improvement through regular assessment, evaluation, and optimization of IT services. This ongoing improvement process can help businesses stay competitive and responsive to changing customer needs.</li>
                                <li className="values-li appear-left"><strong>Competitive Advantage</strong> - Organizations that implement ITIL best practices effectively may gain a competitive advantage by delivering more reliable and efficient IT services than their competitors.</li>

                            </ul>


                            <p className="solution-p appear-right">It's important to note that the value derived from implementing an ITIL-based service desk system will vary depending on the organization's specific circumstances, including its current IT maturity, size, industry, and the extent to which ITIL practices are effectively implemented. Successful implementation often requires commitment, training, and ongoing support from leadership and staff.</p>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Service Desk' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>

                            <div className="square img6 secondary"></div>

                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default ServiceDesk