import { useState, useEffect } from 'react'
import "./About.css"
import Nav from '../nav/Nav'
import Footer from '../footer/Footer'
import heroImg from "../../images/people-office2.jpg"
import heroImgSmall from "../../images/people-office2-small.jpg"
import wedoImg from "../../images/wedo-img.jpg"
import purposeImg from "../../images/purpose.svg"
import visionImg from "../../images/vision.svg"
import missionImg from "../../images/mission.svg"
import pageNotFound from "../../images/page-not-found.png"
import welcome from "../../images/welcome.png"
import coreValues from "../../images/core-values.png"
import coreValuesSmall from "../../images/core-values-small.png"
import dotGrid from "../../images/dot-grid.png"
import whiteAbstractSquares from "../../images/white-abstract-squares.svg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import canvas1 from "../../images/canvas1.svg"
import canvas2 from "../../images/canvas2.svg"
import canvas3 from "../../images/canvas3.svg"
import canvas4 from "../../images/canvas4.svg"
import canvas5 from "../../images/canvas5.svg"
import ReactGa from "react-ga4"



const About = () => {

  // USEEFFECTS


  // INTERSECTION OBSERVERS

  useEffect(() => {
    const appearLefts = document.querySelectorAll('.appear-left')
    const appearLeftHero = document.querySelectorAll('.appear-left-hero')
    const appearRightAbstract = document.querySelectorAll('.appear-right-abstract')
    const appearRights = document.querySelectorAll('.appear-right')
    const appearDowns = document.querySelectorAll('.appear-down')
    const appearUps = document.querySelectorAll('.appear-up')


    const appearLeftsFull = document.querySelectorAll('.appear-left-full')
    const appearRightsFull = document.querySelectorAll('.appear-right-full')
    const appearDownsFull = document.querySelectorAll('.appear-down-full')


    const appearLeftOptions = {
      threshold: 0.2,
    }

    const appearLeftObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftOptions)

    appearLefts.forEach(appearLeft => {
      appearLeftObserver.observe(appearLeft)
    })


    const appearLeftHeroOptions = {
      threshold: 0.2,
    }

    const appearLeftHeroObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftHeroObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftHeroOptions)

    appearLeftHero.forEach(appearLeftHero => {
      appearLeftHeroObserver.observe(appearLeftHero)
    })


    const appearRightOptions = {
      threshold: 0.2,
    }

    const appearRightObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightOptions)

    appearRights.forEach(appearRight => {
      appearRightObserver.observe(appearRight)
    })


    const appearDownOptions = {
      threshold: 0.2,
    }

    const appearDownObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownOptions)

    appearDowns.forEach(appearRight => {
      appearDownObserver.observe(appearRight)
    })




    const appearLeftFullOptions = {
      threshold: 0.2,
    }

    const appearLeftFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftFullOptions)

    appearLeftsFull.forEach(appearLeft => {
      appearLeftFullObserver.observe(appearLeft)
    })

    const appearRightFullOptions = {
      threshold: 0.2,
    }

    const appearRightFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightFullOptions)

    appearRightsFull.forEach(appearRight => {
      appearRightFullObserver.observe(appearRight)
    })

    const appearDownFullOptions = {
      threshold: 0.2,
    }

    const appearDownFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearDownsFull.forEach(appearRight => {
      appearDownFullObserver.observe(appearRight)
    })

    const appearUpOptions = {
      threshold: 0.2,
    }

    const appearUpObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearUps.forEach(appearUp => {
      appearUpObserver.observe(appearUp)
    })
  }, [])


  // Lazy Load the images

  useEffect(() => {
    const blurDivs = document.querySelectorAll('.blur-div')

    blurDivs.forEach(div => {
      let img = div.querySelector('img')

      const loaded = () => {
        div.classList.add('loaded')
      }

      if (img.complete) {
        loaded()
      } else {
        img.addEventListener("load", loaded)
      }
    })
  }, [])

  /////////////////////////////////////////////////////////////////



  // LOAD DISAPPEAR USEEFFECT

  useEffect(() => {
    const loader = document.querySelector('.loader-wrapper')

    function vanish() {
      loader.classList.add('disappear')
    }


    window.addEventListener('load', vanish)
  }, [])


  ///////////////////////////////////////////////////////////////////////////////////////////////




  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  return (
    <div className='about-container'>

      <Helmet>
        <title>ICT Mentors | About us</title>
        <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
      </Helmet>

      <Nav />

      <section className="hero-section">
        <div className="hero-left">
          <h1 className="hero-h1 appear-left">We Partner To Execute Digital <span className="transformation-span">Transformation</span></h1>

        </div>

        <div className="hero-right">
          <div className="background-div appear-right"></div>
          <div className="img-container appear-left-hero blur-div" style={{ backgroundImage: `url(${heroImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
            <img src={heroImg} alt="" loading='lazy' />
            <div className="overlay"></div>
          </div>

          <div className="right-content">
            <p className="about-p appear-up">ICT Mentors Solutions works alongside organizations to drive operations, customer experience, culture, and business transformations</p>
          </div>
        </div>

      </section>

      <section className="wedo-section">

        <div className="wedo-right">
          <h1 className="section-title appear-left">What We Do</h1>

          <p className="wedo-p appear-right">ICT Mentors Solutions works alongside organizations to drive operations, customer experience, culture, and business transformations</p>

        </div>

      </section>

      <section className="principles-section">

        <img src={whiteAbstractSquares} alt="" className='principles-abstract' loading='lazy' />

        <div className="principles-container">
          <div className="principle principle1">
            <img src={purposeImg} alt="" className='appear-left' loading='lazy' />

            <div className="principle-right">
              <h3 className="principle-right-title appear-right">Our Purpose</h3>

              <p className="principle-right-p appear-left">To transform ICT service provision and management</p>
            </div>
          </div>
          <div className="principle principle2">
            <img src={visionImg} alt="" className='appear-right' loading='lazy' />

            <div className="principle-right">
              <h3 className="principle-right-title appear-left">Our Vision</h3>

              <p className="principle-right-p appear-right">To be a partner of choice that empowers our people and consistently delights our customers</p>
            </div>
          </div>
          <div className="principle principle3">
            <img src={missionImg} alt="" className='appear-left' loading='lazy' />

            <div className="principle-right">
              <h3 className="principle-right-title appear-right">Our Mission</h3>

              <p className="principle-right-p appear-left">We empower businesses to efficiently & effectively run and deliver services by enabling them to realize and optimize their IT value</p>
            </div>
          </div>
        </div>
      </section>

      <section className="objectives-section">
        <h1 className="objectives-title appear-up">Our Strategic Objectives</h1>

        <div className="canvas-wrapper">
          <div className="canvas canvas1 appear-left">
            <img src={canvas1} alt="" />
            <h3 className="objective-h3">Enhance our clients’ operational efficiency</h3>
          </div>
          <div className="canvas canvas2 appear-right">
            <img src={canvas2} alt="" />
            <h3 className="objective-h3">Delight our clients</h3>
          </div>
          <div className="canvas canvas3 appear-left">
            <img src={canvas3} alt="" />
            <h3 className="objective-h3">Develop our Workforce</h3>
          </div>
          <div className="canvas canvas4 appear-right">
            <img src={canvas4} alt="" />
            <h3 className="objective-h3">Increase our profitability</h3>
          </div>
          <div className="canvas canvas5 appear-left">
            <img src={canvas5} alt="" />
            <h3 className="objective-h3">Grow our market share</h3>
          </div>

        </div>

        <div className="doodles-div">
          <img src={pageNotFound} alt="" className='page-doodle' loading='lazy' />
          <img src={welcome} alt="" className='welcome-doodle' loading='lazy' />
        </div>
      </section>

      <section className="values-section">
        <h1 className="section-title appear-down" style={{textDecoration: "underline"}}>Our Core Values</h1>

        <div className="img-container blur-div" style={{ backgroundImage: `url(${coreValuesSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
          <img src={coreValues} alt="Our Core Values" className='core-values appear-up' loading='lazy' />
        </div>
      </section>



      <Footer />
    </div>
  )
}

export default About