import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./CreateBlog.css"

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import dotGrid from "../../images/dot-grid.png"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const CreateBlog = () => {


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })


    const [blogTitle, setBlogTitle] = useState('')
    const [author, setAuthor] = useState('')
    const [date, setDate] = useState('')
    const [blogImg, setBlogImg] = useState({})
    const [normalTitle, setNormalTitle] = useState('')
    const [pTag, setPTag] = useState('')
    const [listTag, setListTag] = useState([])
    const [ctaBtn, setCtaBtn] = useState('')




    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // ACCORDION STUFF

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    ////////////////////////////////////////////////////////////////////////////////////

    // FUNCTIONS

    const blogTitleSubmit = (e) => {

        e.preventDefault();

        const fileInput = document.querySelector('.file-input')

        const fr = new FileReader();

        fr.readAsText(fileInput.files[0])
        fr.addEventListener('load', () => {

            // Create a Blob File for new Blog
            const blob = new Blob([fr.result], { type: 'text/javascript' })

            // Create a URL for Data in Blob
            const url = URL.createObjectURL(blob);

            console.log("Heres the url: ", url)
            console.log("Heres the blob: ", blob)
        })



    }

    const normalTitleSubmit = () => {

    }

    const authorSubmit = () => {

    }

    const dateSubmit = () => {

    }

    const blogImgSubmit = () => {

    }

    const pTagSubmit = () => {

    }

    const listTagSubmit = () => {

    }

    const ctaSubmit = () => {

    }


    ///////////////////////////////////////////////////////////////////////////////////






    return (
        <ThemeProvider theme={theme}>
            <div className='createblog-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>

                <Helmet>
                    <title>ICT Mentors | Create Blog</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="create-section">
                    <div className="create-left">
                        <h1 className="section-title">Components</h1>

                        <div className="components-wrapper">
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography>Blog Title</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={blogTitleSubmit}>
                                            <TextField label="Enter blog title" variant="standard" value={blogTitle} onChange={e => setBlogTitle(e.target.value)} required />
                                            <input type="file" className='file-input' />
                                            <Button variant='contained' color='secondary' type='submit'>Add Title</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <Typography>Author</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={authorSubmit}>
                                            <TextField label="Enter the author" variant="standard" value={author} onChange={e => setAuthor(e.target.value)} required />
                                            <Button variant='contained' color='secondary' type='submit'>Add Author</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                                    <Typography>Date</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={dateSubmit}>
                                            <TextField label="Enter date" variant="standard" value={date} onChange={e => setDate(e.target.value)} required />
                                            <Button variant='contained' color='secondary' type='submit'>Add Date</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                    <Typography>Blog Image</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={blogImgSubmit}>
                                            <TextField label="Upload image" variant="standard" value={blogImg} onChange={e => setBlogImg(e.target.value)} required />
                                            <Button variant='contained' color='secondary' type='submit'>Add Image</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                                    <Typography>Normal Title</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={normalTitleSubmit}>
                                            <TextField label="Enter normal title" variant="standard" value={normalTitle} onChange={e => setNormalTitle(e.target.value)} required />
                                            <Button variant='contained' color='secondary' type='submit'>Add Normal Title</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                                    <Typography>P-Tag</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={pTagSubmit}>
                                            <TextField label="Enter P-Tag" variant="standard" value={pTag} onChange={e => setPTag(e.target.value)} required />
                                            <Button variant='contained' color='secondary' type='submit'>Add P-Tag</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                                    <Typography>List-Tag</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={listTagSubmit}>
                                            <TextField label="Enter List-Tag" variant="standard" value={listTag} onChange={e => setListTag(e.target.value)} required />
                                            <Button variant='contained' color='secondary' type='submit'>Add List-Tag</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                                    <Typography>CTA Button</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="form-container">
                                        <form onSubmit={ctaSubmit}>
                                            <TextField label="Enter CTA Button" variant="standard" value={ctaBtn} onChange={e => setCtaBtn(e.target.value)} required />
                                            <Button variant='contained' color='secondary' type='submit'>Add CTA-Button</Button>
                                        </form>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>

                    <div className="create-right">
                        <h1 className="section-title">Preview</h1>

                        <div className="preview-window"></div>
                    </div>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default CreateBlog