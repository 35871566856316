import {combineReducers} from "redux"
import messages from "./messages"
import errors from "./errors"
import blog from "./blog"


const rootReducer = combineReducers({
    messages,
    errors,
    blog
})

export default rootReducer