import { useState, useEffect } from 'react'
import "./CoursePage.css"
import Nav from "../nav/Nav"
import Footer from "../footer/Footer"
import { Button } from '@mui/material'
import { createTheme, ThemeProvider } from "@mui/material/styles"
import itilImg from "../../images/itil-image.png"
import calendar from "../../images/calendar.svg"
import book from "../../images/book.svg"
import folder from "../../images/folder.svg"
import wallet from "../../images/wallet.svg"
import arrowrightCircle from "../../images/arrow-right-circle.svg"
import { Link, useNavigate } from "react-router-dom"
import funkyLines from "../../images/funky-lines.png"
import courseHero from "../../images/course-hero.png"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import blackFriday from "../../images/itilfoundation-blackfriday.png"
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import Tooltip from '@mui/material/Tooltip';
import x from "../../images/black-friday/x.png"
import offerTitle from "../../images/black-friday/offer-title.png"
import smallEllipse from "../../images/black-friday/small-ellipse.png"
import dotFrame from "../../images/black-friday/dot-frame.png"
import narrowCircle from "../../images/black-friday/narrow-circle.png"
import ReactGa from "react-ga4"



// ACCORDION IMPORTS

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from 'axios'
import { useDispatch } from 'react-redux'


// ACCORDION COMPONENTS


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));




const ItilFoundation = () => {

  // STATES

  const courseId = 'C001'

  const [expanded, setExpanded] = useState('panel1');
  const [similarCourses, SetSimilarCourses] = useState([])


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // INITIALIZATIONS

  const theme = createTheme({
    palette: {
      primary: {
        main: '#27AAE1'
      },

      secondary: {
        main: "#2E3092"
      },
      warning: {
        main: "#ffffff"
      }

    }
  })

  const navigate = useNavigate('')
  const dispatch = useDispatch()

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // USEEFFECTS

  // INTERSECTION OBSERVERS

  useEffect(() => {
    const appearLefts = document.querySelectorAll('.appear-left')
    const appearRights = document.querySelectorAll('.appear-right')
    const appearDowns = document.querySelectorAll('.appear-down')
    const appearUps = document.querySelectorAll('.appear-up')


    const appearLeftsFull = document.querySelectorAll('.appear-left-full')
    const appearRightsFull = document.querySelectorAll('.appear-right-full')
    const appearDownsFull = document.querySelectorAll('.appear-down-full')


    const appearLeftOptions = {
      threshold: 0.2,
    }

    const appearLeftObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftOptions)

    appearLefts.forEach(appearLeft => {
      appearLeftObserver.observe(appearLeft)
    })




    const appearRightOptions = {
      threshold: 0.2,
    }

    const appearRightObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightOptions)

    appearRights.forEach(appearRight => {
      appearRightObserver.observe(appearRight)
    })


    const appearDownOptions = {
      threshold: 0.2,
    }

    const appearDownObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownOptions)

    appearDowns.forEach(appearRight => {
      appearDownObserver.observe(appearRight)
    })




    const appearLeftFullOptions = {
      threshold: 0.2,
    }

    const appearLeftFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearLeftFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearLeftFullOptions)

    appearLeftsFull.forEach(appearLeft => {
      appearLeftFullObserver.observe(appearLeft)
    })

    const appearRightFullOptions = {
      threshold: 0.2,
    }

    const appearRightFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearRightFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearRightFullOptions)

    appearRightsFull.forEach(appearRight => {
      appearRightFullObserver.observe(appearRight)
    })

    const appearDownFullOptions = {
      threshold: 0.2,
    }

    const appearDownFullObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearDownsFull.forEach(appearRight => {
      appearDownFullObserver.observe(appearRight)
    })

    const appearUpOptions = {
      threshold: 0.2,
    }

    const appearUpObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active')
          appearDownFullObserver.unobserve(entry.target)
        } else {
          return
        }
      })
    }, appearDownFullOptions)

    appearUps.forEach(appearUp => {
      appearUpObserver.observe(appearUp)
    })
  }, [])

  ////////////////////////////////////////////////////////////////////////////////////////


  // Get Similar Courses

  useEffect(() => {
    axios.post('https://www.ict-mentors.com/api/get_similar', { 'courseId': courseId })
      .then(res => {
        SetSimilarCourses(res.data)

      })
      .catch(err => {
        const error = {
          message: err.response.data,
          status: err.response.status
        }

        dispatch({
          type: 'GET_ERRORS',
          payload: error
        })

      })
  }, [])



  /////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////





  // FUNCTIONS

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  const openAudience = () => {
    document.querySelector('.coursepage-container .detailed-section .detailed-body .detail-div.active').classList.remove('active')
    document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.active').classList.remove('active')

    const audienceDiv = document.querySelector('.coursepage-container .detailed-section .detailed-body .audience-div')
    const audienceLi = document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.audience-li')


    audienceDiv.classList.add('active')
    audienceLi.classList.add('active')
  }


  const openObjectives = () => {
    document.querySelector('.coursepage-container .detailed-section .detailed-body .detail-div.active').classList.remove('active')
    document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.active').classList.remove('active')

    const objectivesDiv = document.querySelector('.coursepage-container .detailed-section .detailed-body .objectives-div')
    const objectivesLi = document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.objectives-li')


    objectivesDiv.classList.add('active')
    objectivesLi.classList.add('active')

  }

  const openExams = () => {
    document.querySelector('.coursepage-container .detailed-section .detailed-body .detail-div.active').classList.remove('active')
    document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.active').classList.remove('active')

    const examsDiv = document.querySelector('.coursepage-container .detailed-section .detailed-body .exams-div')
    const examsLi = document.querySelector('.coursepage-container .detailed-section .detailed-nav ul li.exams-li')


    examsDiv.classList.add('active')
    examsLi.classList.add('active')
  }

  const navigateCourse = (url) => {
    navigate(`/${url}`)
  }



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  // ACCORDION STUFF


  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


  const goContact = () => {
    window.dataLayer.push({event: 'go_enquire'})

    ReactGa.event("go_enquire", {enquiring_from: "ITIL 4 Foundation"})
    navigate('/contact_us?subject=ITIL 4 Foundation')
  }

  const goBack = () => {
    window.history.back();
  }

  const closeOffer = () => {
    const offerPopup = document.querySelector('.coursepage-container .offer-popup')

    offerPopup.classList.add('disappear')
  }

  const goLanding = () => {
    navigate('/itil_foundation_landing')
  }


  // Set Timeout for Popup
  setTimeout(() => {
    console.log("We are displaying the popup")

    const popup = document.querySelector('.coursepage-container .offer-popup.disappear')

    popup.classList.remove('disappear')
  }, 30000)

  ////////////////////////////////////////////////////////////////////////////////////////



  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




  return (
    <ThemeProvider theme={theme}>
      <div className='coursepage-container'>

        <div className="offer-popup disappear" style={{display: "none"}}>
          <Tooltip title="minimise"><FullscreenExitRoundedIcon className='exit-icon' onClick={closeOffer} /></Tooltip>

          <div className="offer-wrapper">
            <div className="watermarks">
              <h1 className="watermark watermark1">OFFER</h1>
              <h1 className="watermark watermark2">OFFER</h1>
              <h1 className="watermark watermark3">OFFER</h1>
            </div>



            <div className="centre-div">

              <div className="offer-title-div">
                <img src={offerTitle} alt="" className="offer-title" />
                <img src={dotFrame} alt="" className="dot-frame dot-frame2" />

                <img src={narrowCircle} alt="" className="narrow-circle narrow-circle1" />
                <img src={narrowCircle} alt="" className="narrow-circle narrow-circle2" />
                <img src={narrowCircle} alt="" className="narrow-circle narrow-circle3" />
                <img src={narrowCircle} alt="" className="narrow-circle narrow-circle4" />
                <img src={narrowCircle} alt="" className="narrow-circle narrow-circle5" />
                <img src={narrowCircle} alt="" className="narrow-circle narrow-circle6" />
                <img src={narrowCircle} alt="" className="narrow-circle narrow-circle7" />

                <img src={smallEllipse} alt="" className="ellipse ellipse1" />
                <img src={smallEllipse} alt="" className="ellipse ellipse2" />
                <img src={smallEllipse} alt="" className="ellipse ellipse3" />
                <img src={smallEllipse} alt="" className="ellipse ellipse4" />
                <img src={smallEllipse} alt="" className="ellipse ellipse5" />
                <img src={smallEllipse} alt="" className="ellipse ellipse6" />
                <img src={smallEllipse} alt="" className="ellipse ellipse7" />
                <img src={smallEllipse} alt="" className="ellipse ellipse8" />
                <img src={smallEllipse} alt="" className="ellipse ellipse9" />
                <img src={smallEllipse} alt="" className="ellipse ellipse10" />
                <img src={smallEllipse} alt="" className="ellipse ellipse11" />

                <img src={x} alt="" className="x x1" />
                <img src={x} alt="" className="x x2" />
                <img src={x} alt="" className="x x3" />
                <img src={x} alt="" className="x x4" />
                <img src={x} alt="" className="x x5" />
                <img src={x} alt="" className="x x6" />
                <img src={x} alt="" className="x x7" />
                <img src={x} alt="" className="x x8" />
                <img src={x} alt="" className="x x9" />
                <img src={x} alt="" className="x x10" />
                <img src={x} alt="" className="x x11" />
              </div>

              <div className="discount-div">
                <h1 className="discount-h1">UP TO 45% Discount</h1>
              </div>

              <div className="product-div">
                <h1 className="product-title">ON ITIL 4 FOUNDATION</h1>

                <p className="warning-p">Discount ends pretty soon</p>

              </div>


              <div className="elements">
                <img src={dotFrame} alt="" className="dot-frame dot-frame2" />
                <img src={dotFrame} alt="" className="dot-frame dot-frame3" />
                <img src={dotFrame} alt="" className="dot-frame dot-frame4" />

              </div>
            </div>
          </div>

          <div className="buttons-div">
            <Button variant='outlined' className='cancel-btn' color='primary' onClick={closeOffer}>Cancel</Button>
            <Button variant='contained' className='proceed-btn' color='primary' onClick={goLanding}>Check it out</Button>
          </div>
        </div>

        <Helmet>
          <title>ITIL 4 Foundation Training Course</title>
          <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
        </Helmet>

        <Nav />
        <section className="hero-section">
          <div className="triangle"></div>
          <div className="hero-left appear-left">

            <svg width="58" height="52" viewBox="0 0 58 52" fill="none" className='back-arrow' onClick={goBack}>
              <path d="M18 3.75L4.25 17.5L18 31.25" stroke="#011B25" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4.25 17.5H26.25C41.4382 17.5 53.75 29.8118 53.75 45V47.75" stroke="#011B25" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
            </svg>


            <h1 className="hero-h1">ITIL 4 Foundation Training Course</h1>

            <ul className="hero-ul">
              <li>
                <div className="bullet-div"></div>
                <span className="point-span">Understand how to use the ITIL® 4 guidance to improve your work and organisation.</span>
              </li>
              <li>
                <div className="bullet-div"></div>
                <span className="point-span">Attain in-depth knowledge of the management of IT-enabled services in the digital economy.</span>
              </li>
              <li>
                <div className="bullet-div"></div>
                <span className="point-span">Understand how value streams increase speed and efficiency</span>
              </li>
              <li>
                <div className="bullet-div"></div>
                <span className="point-span">Know how modern IT and digital service organizations operate</span>
              </li>
            </ul>

            <div className="buttons-div">
              <Button variant="outlined" color="secondary" onClick={goContact} className='enquire-btn' id='itil_foundation'>Enquire Now</Button>
            </div>
          </div>

          <div className="hero-right ">
            <img src={courseHero} alt="" className='course-hero-img appear-right' loading='lazy' />
          </div>
        </section>


        <section className="overview-section appear-left">
          <h1 className="section-title">Overview</h1>

          <p className="overview-p">ITIL® 4 Foundation is one of the widely-recognized entry-level certification courses provided by PeopleCert in the IT Service Management category. With widespread digital transformation, enterprises need an ITSM framework that takes into account the evolving trends in the market. This results in the transition from ITIL® V3 to ITIL® 4 which provides a holistic operating model to deliver tech-enabled products services. This ITIL® Foundation course is ideal for professionals who are looking to gain a basic understanding of the ITIL® framework and enhance their IT Service Management strategy in an organization.</p>
        </section>

        <section className="detailed-section">
          <div className="triangle"></div>
          <div className="detailed-nav appear-left">
            <ul>
              <li className="audience-li active" onClick={openAudience}>Target Audience</li>
              <li className="objectives-li" onClick={openObjectives}>Course Objectives</li>
              <li className="exams-li" onClick={openExams}>Exams</li>
            </ul>
          </div>


          <div className="detailed-body appear-right">

            <div className="audience-div detail-div active">
              <h2 className="section-title">Target Audience for ITIL 4 Certification Course</h2>

              <p className="audience-p">Job roles that can take up ITIL® v4 training include, but are not limited to:</p>

              <ul className="audience-ul">
                <li className="audience-li">IT Managers</li>
                <li className="audience-li">IT Project Managers</li>
                <li className="audience-li">Network Operators</li>
                <li className="audience-li">IT Suppliers</li>
                <li className="audience-li">Help Desk Managers</li>
                <li className="audience-li">Incident Managers</li>
                <li className="audience-li">System Analysts</li>
                <li className="audience-li">System Administrators</li>
                <li className="audience-li">Security Managers</li>
                <li className="audience-li">IT Operations Manager</li>
                <li className="audience-li">Database Administrators</li>
                <li className="audience-li">IT Professionals who are part of ITSM improvement tasks</li>
                <li className="audience-li">Anybody who wants to gain knowledge on ITIL® 4 best practices</li>
                <li className="audience-li">Anybody who is looking to clear their ITIL® 4 Foundation certification exam</li>
              </ul>
            </div>


            <div className="objectives-div detail-div">
              <h2 className="section-title">Course Objectives</h2>

              <p className="objectives-p">Participants who take part in the ITIL® 4 Foundation training will learn about:</p>

              <ul className="objectives-ul">
                <li className="objectives-li">An overview of ITIL® 4 guiding principles</li>
                <li className="objectives-li">Identifying opportunities to implement IT best practices using ITIL® 4 framework</li>
                <li className="objectives-li">ITIL® 4 terminology and concepts and use similar language with other IT teams</li>
                <li className="objectives-li">Service value system</li>
                <li className="objectives-li">Service value chain activities and how they interconnect with other processes</li>
                <li className="objectives-li">4 new dimensions of Service Management</li>
                <li className="objectives-li">Understanding the importance of IT services and business integration</li>
                <li className="objectives-li">Continual Improvement and its benefits</li>
                <li className="objectives-li">How to clear your ITIL® 4 Foundation exam on the first attempt</li>
              </ul>
            </div>
            <div className="exams-div detail-div">
              <h2 className="section-title">Exams</h2>

              <p className="exams-p">The ITIL® 4 Foundation exam tests knowledge of the basic terminology, concepts, and principles behind ITIL® and service management.</p>

              <ul className="exams-ul">
                <li className="exams-li"><span className="bold-span">Type of questions:</span> Multiple Choice.</li>
                <li className="exams-li"><span className="bold-span">Duration:</span> 60 Minutes.</li>
                <li className="exams-li"><span className="bold-span">Material Allowed:</span> None, this is a ‘closed book’ exam.</li>
                <li className="exams-li"><span className="bold-span">Total marks:</span> 40 marks, there are 40 questions each worth 1 mark.</li>
                <li className="exams-li"><span className="bold-span">Pass Mark:</span> 26 marks, you will need to get 26 questions correct (65%) to pass the exam.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="why-section">

          <h1 className="section-title appear-left">Why train with ICT Mentors Soutions?</h1>

          <div className="reasons">
            <div className="reason reason1 appear-left">
              <img src={calendar} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Industry Expertise</h5>
                <p className="reason-p">Our instructors have vast industry experience for practical, real-world knowledge.</p>
              </div>
            </div>
            <div className="reason reason2 appear-right">
              <img src={book} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Global Accreditation</h5>
                <p className="reason-p">We are accredited by global firms to offer trainings and certifications</p>
              </div>
            </div>
            <div className="reason reason3 appear-left">
              <img src={folder} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Personalized Learning</h5>
                <p className="reason-p">Tailored and flexible learning environment, to ensure successful ICT training.</p>
              </div>
            </div>
            <div className="reason reason4 appear-right">
              <img src={wallet} alt="" loading='lazy' />
              <div className="reason-content">
                <h5 className="reason-title">Value for Money</h5>
                <p className="reason-p">Gain top-tier skills without breaking the bank.</p>
              </div>
            </div>
          </div>
        </section>

        {similarCourses.length > 0 &&

          <section className="similar-section">
            <h1 className="section-title">Those who buy this course also go for</h1>

            <div className="similar-courses-container">
              {similarCourses.map(course => (
                <div className="similar-course" onClick={() => navigateCourse(course.short_title)}><p className="similar-p">{course.display_title}</p> <Link to="/"><img src={arrowrightCircle} alt="" className='arrow-right' loading='lazy' /></Link></div>
              ))}

            </div>
          </section>

        }

        <div className="button-divend">
          <Button variant="outlined" color="secondary" onClick={goContact} className='enquire-btn' id='itil_foundation'>Enquire Now</Button>
        </div>

        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default ItilFoundation