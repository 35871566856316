import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/ict-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/people-office1.jpg"
import img1Small from "../../images/people-office1-small.jpg"
import img2 from "../../images/people-office2.jpg"
import img2Small from "../../images/people-office2-small.jpg"
import img3 from "../../images/training-on.jpg"
import img3Small from "../../images/training-on-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const ICTAudits = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "ICT Audits"})
        navigate('/contact_us?subject=ICT Audits')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>ICT Audits</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">ICT Audits</h1>
                            <p className="solution-p appear-right">ICT Mentors solutions team industry experts are able to conduct detailed Enterprise ICT Audit with abilities to identify critical issues (Return on investment, Fitness for purpose, compliances, security, governance etc) and recommend enterprise-specific practices to support and safeguard the governance of information and related technologies.</p>
                            <p className="solution-p appear-right">The outcome helps companies identify and deal with critical gaps in their IT environment to realize value for investment.</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Risk Mitigations</strong> - By thoroughly assessing all areas of your IT infrastructure, People & IT Processes an audit can help identify vulnerabilities, weaknesses, and potential security threats. Addressing these issues proactively can prevent costly data breaches, downtime, and other IT-related disruptions.</li>
                                <li className="values-li appear-right"><strong>Cost Reduction</strong> - An IT audit can reveal areas of inefficiency in your technology systems. This may include redundant software or hardware, underutilized resources, or processes that can be streamlined. Identifying and rectifying these inefficiencies can lead to cost savings in the long run.</li>
                                <li className="values-li appear-left"><strong>Compliance Assurance</strong> - Many industries have strict regulatory requirements regarding data security and privacy (e.g., GDPR, DPA). An IT audit can help ensure that your systems and processes are in compliance with these regulations, reducing the risk of fines or legal issues.</li>
                                <li className="values-li appear-right"><strong>Improved Performance</strong> - Through performance analysis, an IT audit can help identify bottlenecks, slow processes, or outdated technology that may be hindering the productivity of your employees. Optimizing IT systems can lead to improved overall performance.</li>
                                <li className="values-li appear-left"><strong>Data Integrity</strong> - Ensuring the accuracy and reliability of data is crucial for business operations. An IT audit can assess data management practices and help identify areas where data integrity may be compromised. This is particularly important for businesses heavily reliant on data analytics and decision-making.</li>
                                <li className="values-li appear-right"><strong>Disaster Recovery and Business Continuity</strong> - An IT audit can assess your disaster recovery and business continuity plans. Ensuring that these plans are robust and up-to-date can minimize the impact of unforeseen disasters or disruptions</li>
                                <li className="values-li appear-left"><strong>Strategic Alignment</strong> - IT audits can also assess whether your IT infrastructure aligns with your business goals and objectives. It can help identify areas where technology can be leveraged to drive innovation and competitive advantage.</li>
                                <li className="values-li appear-right"><strong>Vendor Management</strong> - Evaluating your relationships with IT vendors and suppliers can help ensure you are getting value for your investments. An audit can identify opportunities for renegotiating contracts or finding more cost-effective solutions.</li>
                                <li className="values-li appear-left"><strong>Security Enhancement</strong> - Cybersecurity is a top concern for businesses today. An IT audit can assess the strength of your security measures, identify potential vulnerabilities, and recommend improvements to protect sensitive data and assets.</li>
                                <li className="values-li appear-right"><strong>Stakeholder Confidence</strong> - Demonstrating a commitment to IT governance and security through regular audits can enhance the confidence of customers, partners, and investors in your organization.</li>
                                <li className="values-li appear-left"><strong>Long-term Planning</strong> - An IT audit can provide valuable insights for long-term strategic planning. It can help you prioritize IT investments and initiatives that will support your business growth and sustainability</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='ICT Audits' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default ICTAudits