import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/vulnerability-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/happy-blackman1.jpg"
import img1Small from "../../images/happy-blackman1-small.jpg"
import img2 from "../../images/happy-blackman2.jpg"
import img2Small from "../../images/happy-blackman2-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const Vulnerability = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////


    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Vulnerability Assessment"})
        navigate('/contact_us?subject=Vulnerability Assessment and Patch Management')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>Vulnerability Assessment & Patch Management</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">Vulnerability Assessment & Patch Management</h1>
                            <p className="solution-p appear-right">IT vulnerabilities once exploited can lead to damaged reputation, loss of data and IP, fines and costly remediation.</p>
                            <p className="solution-p appear-right">ICT Mentors solutions will provide comprehensive risk-based vulnerability assessment tools and services that are focused on protecting your business-critical infrastructure. This will effectively and efficiently manage patching within the entire organization’s Infrastructure.</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Risk Mitigation</strong> - Identifying and prioritizing vulnerabilities helps businesses mitigate potential security risks before they can be exploited by malicious actors.</li>
                                <li className="values-li appear-right"><strong>Compliance</strong> - Many regulatory frameworks and industry standards require businesses to conduct vulnerability assessments and remediate identified issues as part of compliance efforts.</li>
                                <li className="values-li appear-left"><strong>Proactive Security</strong> - It fosters a proactive approach to security by continuously monitoring and assessing the organization's IT infrastructure for weaknesses.</li>
                                <li className="values-li appear-right"><strong>Security Updates</strong> - Ensures that all software and systems are up-to-date with the latest security patches, reducing the risk of known vulnerabilities being exploited.</li>
                                <li className="values-li appear-left"><strong>System Stability</strong> - Patches not only address security flaws but also enhance system stability and performance, leading to better overall business operations.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Vulnerability Assessment' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>

                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default Vulnerability