import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/iso20000-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/digital-transformation.jpg"
import img1Small from "../../images/digital-transformation-small.jpg"
import img2 from "../../images/digitalvsit-transformation.jpg"
import img2Small from "../../images/digitalvsit-transformation-small.jpg"
import img3 from "../../images/baseline2-blog.jpg"
import img3Small from "../../images/baseline2-blog-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const ISO20000 = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "ISO 20000 Solution"})
        navigate('/contact_us?subject=ISO 20000 Implementation')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>ISO/IEC 20000 Implementation</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">ISO/IEC 20000 Implementation</h1>
                            <p className="solution-p appear-right">ISO 20000 is the international standard that enables IT organizations to ensure that their IT service management (ITSM) processes are aligned both with the needs of the business and with international best practice.</p>
                            <p className="solution-p appear-right">Implementing an IT service management system (SMS) that complies with ISO 20000 supports and enhances global best practices and is the logical next step fororganizations that have adopted the ITIL methodology.</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Improved Service Quality</strong> - ISO 20000 emphasizes the need for a systematic approach to ITSM, leading to improved service quality and consistency. This can result in higher customer satisfaction and loyalty.</li>
                                <li className="values-li appear-right"><strong>Increased Efficiency</strong> - The framework encourages organizations to optimize their IT processes and workflows. As a result, businesses can achieve greater efficiency in delivering IT services, reducing costs, and improving resource utilization.</li>
                                <li className="values-li appear-left"><strong>Enhanced Risk Management</strong> - ISO 20000 incorporates risk management principles, helping businesses identify and mitigate potential IT service disruptions or security breaches. This proactive approach can minimize business risks and vulnerabilities.</li>
                                <li className="values-li appear-right"><strong>Compliance and Legal Requirements</strong> - ISO 20000 compliance can help businesses meet legal and regulatory requirements related to IT services, data protection, and information security. This can prevent costly fines and legal issues.</li>
                                <li className="values-li appear-left"><strong>Increased Competitive Advantage</strong> - Being ISO 20000 certified can give your business a competitive edge. It demonstrates your commitment to delivering high-quality IT services, which can be a valuable selling point for attracting new customers or clients.</li>
                                <li className="values-li appear-right"><strong>Better Alignment with Business Goals</strong> - ISO 20000 promotes the alignment of IT services with overall business objectives. This ensures that IT investments and initiatives are more closely tied to the company's strategic goals.</li>
                                <li className="values-li appear-left"><strong>Improved Communication</strong> - The framework encourages effective communication and collaboration between IT teams and other business units. This can lead to better coordination and understanding of IT service needs.</li>
                                <li className="values-li appear-right"><strong>Continuous Improvement</strong> - ISO 20000 promotes a culture of continuous improvement in IT service delivery. By regularly reviewing and optimizing processes, organizations can adapt to changing business needs and technology trends.</li>
                                <li className="values-li appear-left"><strong>Enhanced Customer Trust</strong> - ISO 20000 certification can build trust with customers, partners, and stakeholders. They can have confidence in the reliability and quality of your IT services, which can lead to stronger relationships and long-term partnerships.</li>
                                <li className="values-li appear-right"><strong>Boost Cost Savings</strong> - While there may be initial costs associated with implementing ISO 20000, the long-term benefits, such as reduced downtime, improved resource utilization, and better risk management, can lead to significant cost savings.</li>
                                <li className="values-li appear-left"><strong>Increased Global Recognition</strong> - ISO standards are recognized and respected worldwide. Achieving ISO 20000 certification can open up opportunities for international business and collaboration.</li>
                                <li className="values-li appear-right"><strong>Enhanced Service Innovation</strong> - The framework encourages organizations to innovate in IT service delivery, which can lead to the development of new products or services that drive revenue growth.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='ISO 20000' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default ISO20000