import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./Solutions.css"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import funkyLines from "../../images/funky-lines.png"
import scribble from "../../images/training-scribble.svg"
import arrowReverse from "../../images/arrow-reverse.svg"
import solutionsHero from "../../images/solutions-hero.png"
import solutionsHeroSmall from "../../images/solutions-hero-small.png"
import construction from "../../images/construction.png"
import baloon from "../../images/baloon.png"
import SingleSolutionDiv from '../single-solutiondiv/SingleSolutionDiv'
import ReactPaginate from 'react-paginate';
import circleAbstract from "../../images/circle-abstract.svg"
import planets from "../../images/planets.png"
import satelite from "../../images/satelite.png"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const Solutions = () => {
    // USESTATES

    const [solutions, setSoutions] = useState([
        {
            title: "IT Strategy Formulation",
            description: "ICT Mentors Solutions will serve as a trusted partner to guide organizations in drawing up an IT strategic plan aligned to the business plan...",
            shortUrl: "strategy_formulation",
            fadeIn: "appear-left"
        },
        {
            title: "Security Operations Center",
            description: "Security Operations Centre (SOC) will proactively and continuously monitor the organization’s security environment to prevent, detect, analyze and respond to cyber security threats...",
            shortUrl: "soc",
            fadeIn: "appear-right"
        },
        {
            title: "Implementation of Global Best Frameworks",
            description: "Businesses globally recognize that effective and efficient IT Services Provision is underpinned by globally defined frameworks that ensure these services are aligned to the business and customer demands while reducing the costs through improved utilization of resources. ICT Mentors Solutions will walk with you to realize effective implementation of these frameworks...",
            shortUrl: "global_frameworks",
            fadeIn: "appear-up"
        },
        {
            title: "Baseline & Maturity Assessment",
            description: "ICT Mentors Solutions’ team of experts will apply global best practices to assess your organization’s IT environment (in IT Service Management, Information Security, Governance and business continuity). This exercise will provide a snapshot of the current organization’s IT environment and identify the gaps to achieve the desired state in alignment to the business goals and global best practices...",
            shortUrl: "baseline_maturity",
            fadeIn: "appear-down"
        },
        {
            title: "Service Desk Deployment & Management",
            description: "We work with customers to establish a single point of contact for all IT service provision integrating incident, problem, change, service requests, configuration management and other key practices...",
            shortUrl: "service_desk",
            fadeIn: ""
        },
        {
            title: "Business Continuity & Disaster Recovery",
            description: "Every year, management of companies report that they are not confident in their organization's ability to recover following a disaster. An effective business continuity and disaster recovery planning needs to be integrated with the day-to-day operations...",
            shortUrl: "business_continuity",
            fadeIn: ""
        },
        {
            title: "Business Process Re-engineering",
            description: "With ICT Mentors Solutions as your preferred business partner, we walk with you in transforming and re-engineering your business processes to drive efficiencies, customer satisfaction and realization of IT value...",
            shortUrl: "business_process",
            fadeIn: ""
        },
        {
            title: "Digital Transformation Experience",
            description: "This is an intensive workshop bringing together IT and business champions. It focuses on an honest and aligned team evaluation of the critical key pillars underpinning digital transformation (includes data, security, applications, governance and others). It looks at their current maturity and the desired maturity (against global best practices and the business vision and strategy)...",
            shortUrl: "digital_transformation",
            fadeIn: ""
        },
        {
            title: "ICT Audits",
            description: "ICT Mentors solutions team industry experts are able to conduct detailed Enterprise ICT Audit with abilities to identify critical issues (Return on investment, Fitness for purpose, compliances, security, governance etc) and recommend enterprise-specific practices to support and safeguard the governance of information and related technologies...",
            shortUrl: "ict_audits",
            fadeIn: ""
        },
        {
            title: "ISO/IEC 20000 implementation",
            description: "ISO 20000 is the international standard that enables IT organizations to ensure that their IT service management (ITSM) processes are aligned both with the needs of the business and with international best practice...",
            shortUrl: "iso_20000",
            fadeIn: ""
        },
        {
            title: "Project Advisory Services",
            description: "At ICT Mentors Solutions we undertake Project Advisory Services to our clients including business process analysis, financial analysis, performance assessment, project management and risk assessment for the assigned project to ensure cost, time, quality and scope are achieved in a changing organization’s environment...",
            shortUrl: "project_advisory",
            fadeIn: ""
        },
        {
            title: "Agile Coaching",
            description: "ICT Mentors Solutions will partner with organizations to imagine and drive strategies that will deliver accelerated innovation and time to market. Our adaptive portfolio management and seamless delivery expertise, enabled by the top leadership in the organization and talent and leveraged through competitive intelligence and technical capability will ensure delivery of high impact results...",
            shortUrl: "strategy_formulation",
            fadeIn: ""
        },
        {
            title: "Vulnerability Assessment & Patch Management",
            description: "IT vulnerabilities once exploited can lead to damaged reputation, loss of data and IP, fines and costly remediation. ICT Mentors solutions will provide comprehensive risk-based vulnerability assessment tools and services that are focused on protecting your business-critical infrastructure. This will effectively and efficiently manage patching within the entire organization’s Infrastructure...",
            shortUrl: "vulnerability",
            fadeIn: ""
        },
        {
            title: "Penetration Testing",
            description: "ICT Mentors Solutions will serve as a trusted partner to guide organizations in drawing up an IT strategic plan aligned to the business plan...",
            shortUrl: "pentest",
            fadeIn: ""
        },
        {
            title: "ISO 27001- Information Security Management System (ISMS)",
            description: "Protecting information assets is vital to the core survival of an organization. With the increase in cyberattacks and viruses worldwide, it has become essential for organizations to adopt innovative and rigorous procedures to keep these assets out of the reach of exploiters. ISO 27001 aims to ensure protection of the confidentiality, integrity, and availability of your information and provides you and your customers the confidence that their data is safe....",
            shortUrl: "implement_iso27001",
            fadeIn: ""
        },

    ])
    const [currentSolutions, setCurrentSolutions] = useState([]);


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearLeftPaginate = document.querySelectorAll('.solutions-container .paginate-ul.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')
        const appearDowns = document.querySelectorAll('.appear-down')
        const appearUps = document.querySelectorAll('.appear-up')


        const appearLeftsFull = document.querySelectorAll('.appear-left-full')
        const appearRightsFull = document.querySelectorAll('.appear-right-full')
        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })

        const appearLeftPaginateOptions = {
            threshold: 0.2,
        }

        const appearLeftPaginateObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftPaginateOptions)

        appearLeftPaginate.forEach(appearLeftPaginate => {
            appearLeftPaginateObserver.observe(appearLeftPaginate)
        })



        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftFullOptions = {
            threshold: 0.2,
        }

        const appearLeftFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftFullOptions)

        appearLeftsFull.forEach(appearLeft => {
            appearLeftFullObserver.observe(appearLeft)
        })

        const appearRightFullOptions = {
            threshold: 0.2,
        }

        const appearRightFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFullOptions)

        appearRightsFull.forEach(appearRight => {
            appearRightFullObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // FUNCTIONS

    // Pagination Stuff

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 6

    const [pageCount, setPageCount] = useState(0);


    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentSolutions(solutions.slice(itemOffset, endOffset));



        setPageCount(Math.ceil(solutions.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, solutions]);



    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % solutions.length;
        scrollWrapperTop()
        setItemOffset(newOffset);
    };

    ///////////////////////////////////////////////////



    const scrollSection = () => {
        const solutionsSection = document.querySelector('.solutions-container .solutions-section')
        const bodyRect = document.body.getBoundingClientRect()
        const elementRect = solutionsSection.getBoundingClientRect()

        const navContainer = document.querySelector('.nav2-container')

        let verticalPosition = elementRect.top - bodyRect.top
        let navHeight = navContainer.offsetHeight + 6;

        window.scrollTo(0, verticalPosition - navHeight)
    }

    const scrollWrapperTop = () => {
        const solutionsSection = document.querySelector('.solutions-container .solutions-section')
        const bodyRect = document.body.getBoundingClientRect()
        const elementRect = solutionsSection.getBoundingClientRect()

        const navContainer = document.querySelector('.nav2-container')

        let verticalPosition = elementRect.top - bodyRect.top
        let navHeight = navContainer.offsetHeight + 6;

        window.scrollTo(0, verticalPosition - navHeight)
    }




    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    return (
        <ThemeProvider theme={theme}>
            <div className='solutions-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>ICT Mentors | Solutions</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="hero-section">

                    <img src={planets} alt="" className="planets" loading='lazy' />
                    <img src={satelite} alt="" className="satelite" loading='lazy' />

                    <div className="hero-left">
                        <h1 className="hero-title appear-left">Adopt global solutions for <span>value creation</span>.
                            <img src={scribble} alt="" className="scribble" loading='lazy' />
                        </h1>


                        <p className="hero-p appear-up">Embrace best practices to drive operational efficiency and excellent customer experience, in a secure environment  </p>

                        <div className="checkout appear-right" onClick={scrollSection}>
                            <span>Learn More</span>
                            <img src={arrowReverse} alt="" className='arrow-reverse' loading='lazy' />
                        </div>
                    </div>

                    <div className="hero-right">
                        <div className="img-container blur-div" style={{ backgroundImage: `url(${solutionsHeroSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}><img src={solutionsHero} alt="" className="hero-big appear-right" /></div>
                        <img src={construction} alt="" className="hero-small appear-right" loading='lazy' />
                    </div>


                </section>

                <hr />

                <section className="solutions-section">
                    <img src={baloon} alt="" className="baloon appear-left" loading='lazy' />
                    <img src={circleAbstract} alt="" className='abstract abstract1 appear-right' loading='lazy' />
                    <img src={circleAbstract} alt="" className='abstract abstract2 appear-left' loading='lazy' />

                    <div className="solutions-wrapper appear-left">

                        {currentSolutions.map(solution => (
                            <SingleSolutionDiv title={solution.title} description={solution.description} shortUrl={solution.shortUrl} />
                        ))}

                    </div>
                </section>

                <div className="appear-down">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        className='paginate-ul'
                    />
                </div>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default Solutions