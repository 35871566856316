import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import longArrowRight from "../../images/long-arrow-right.svg"
import "./SingleSolutionDiv.css"
import ReactGa from "react-ga4"

const SingleSolutionDiv = ({ title, description, shortUrl, fadeIn }) => {

    // INITIALIZATIONS

    const navigate = useNavigate()


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')
        const appearDowns = document.querySelectorAll('.appear-down')
        const appearUps = document.querySelectorAll('.appear-up')


        const appearLeftsFull = document.querySelectorAll('.appear-left-full')
        const appearRightsFull = document.querySelectorAll('.appear-right-full')
        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })



        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftFullOptions = {
            threshold: 0.2,
        }

        const appearLeftFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftFullOptions)

        appearLeftsFull.forEach(appearLeft => {
            appearLeftFullObserver.observe(appearLeft)
        })

        const appearRightFullOptions = {
            threshold: 0.2,
        }

        const appearRightFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFullOptions)

        appearRightsFull.forEach(appearRight => {
            appearRightFullObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className={`single-solution-container ${fadeIn}`}>
            <div className="top-solution">
                <h2 className="solution-title">{title}</h2>
                <p className="description">{description}</p>
            </div>

            <div className="view-btn" id={title} onClick={() => {navigate(`/${shortUrl}`); ReactGa.event("view_single_solution", {solution_title: title})}}>
                <span>View</span>

                <img src={longArrowRight} alt="" className='long-arrow' loading='lazy' />
            </div>
        </div>
    )
}

export default SingleSolutionDiv