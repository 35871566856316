import { useState, useEffect } from 'react'
import "./BlogPage.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import blog4Img from "../../images/blog4.jpg"
import blog4ImgSmall from "../../images/blog4-small.png"
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import dotGrid from "../../images/dot-grid.png"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import axios from 'axios';
import TextField from '@mui/material/TextField';
import baseline2Blog from "../../images/baseline2-blog.jpg"
import baseline2BlogSmall from "../../images/baseline2-blog-small.jpg"
import assessmentReport from "../../images/assessment-report.jpg"
import assessmentReportSmall from "../../images/assessment-report-small.jpg"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"
import { useSelector, useDispatch } from "react-redux"


const BaseBlog = () => {

    // USESTATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [ads, setAds] = useState([])
    const blog = useSelector(state => state.blog)
    const [code, setCode] = useState('')



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');



    // USEEFFECTS


    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')


        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    // LOAD BLOG ADS

    // useEffect(() => {
    //     axios.get('https://www.ict-mentors.com/api/get_blog_ads')
    //         .then(res => {
    //             setAds(res.data)
    //         })
    //         .catch(err => {
    //             const error = {
    //                 message: err.response.data,
    //                 status: err.response.status
    //             }

    //             dispatch({
    //                 type: 'GET_ERRORS',
    //                 payload: error
    //             })

    //             setLoading(false)
    //         })
    // }, [])

    ////////////////////////////////////////////////////////////////



    // LOAD DISAPPEAR USEEFFECT

    useEffect(() => {
        const loader = document.querySelector('.loader-wrapper')

        function vanish() {
            loader.classList.add('disappear')
        }


        window.addEventListener('load', vanish)
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.blog-p')
        const appearLeftImg = document.querySelectorAll('.img-container')
        const appearLeftAuthor = document.querySelectorAll('.author-date')
        const appearRightAd = document.querySelectorAll('.ad')
        const appearRights = document.querySelectorAll('.section-title')
        const appearRightSocials = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .socials')
        const appearRightForm = document.querySelectorAll('.blogpage-container .blog-right .form-container form')
        const appearLeftShare = document.querySelectorAll('.blogpage-container .share-div')
        const appearDowns = document.querySelectorAll('.blogpage-container .blog-ul .blog-li')
        const appearUps = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .blog-title')



        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftImgOptions = {
            threshold: 0.2,
        }

        const appearLeftImgObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftImgObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftImgOptions)

        appearLeftImg.forEach(appearLeft => {
            appearLeftImgObserver.observe(appearLeft)
        })

        const appearLeftAuthorOptions = {
            threshold: 0.2,
        }

        const appearLeftAuthorObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftAuthorObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftAuthorOptions)

        appearLeftAuthor.forEach(appearLeft => {
            appearLeftAuthorObserver.observe(appearLeft)
        })

        const appearLeftShareOptions = {
            threshold: 0.2,
        }

        const appearLeftShareObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftShareObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftShareOptions)

        appearLeftShare.forEach(appearLeft => {
            appearLeftShareObserver.observe(appearLeft)
        })

        const appearRightAdOptions = {
            threshold: 0.2,
        }

        const appearRightAdObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightAdObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightAdOptions)

        appearRightAd.forEach(appearRight => {
            appearRightAdObserver.observe(appearRight)
        })

        const appearRightFormOptions = {
            threshold: 0.2,
        }

        const appearRightFormObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFormObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFormOptions)

        appearRightForm.forEach(appearRight => {
            appearRightFormObserver.observe(appearRight)
        })

        const appearRightSocialsOptions = {
            threshold: 0.2,
        }

        const appearRightSocialsObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightSocialsObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightSocialsOptions)

        appearRightSocials.forEach(appearRight => {
            appearRightSocialsObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearUpOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    useEffect(() => {

        if (blog.code !== undefined) {
            
            const blogSection = document.querySelector('.blogpage-container .blog-section')
            
            blogSection.innerHTML = blog.code
        } else {
            getCode()
        }
    }, [])

    useEffect(() => {

        if(code.length > 1) {
            const blogSection = document.querySelector('.blogpage-container .blog-section')
            blogSection.innerHTML = code
        }

    }, [code])


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS

    // Get Code if Page reloaded

    const getCode = () => {

        console.log("We are here trying to obtain the code")

        const searchParams = new URLSearchParams(window.location.search);

        const postId = searchParams.get('id')

        if (blog.code == undefined) {
            axios.get(`https://www.googleapis.com/blogger/v3/blogs/7447408006844939584/posts/${postId}?key=AIzaSyAGmI2Td0rD6hfiFCQoqpdnukECvNY3D7M`)
                .then(res => {
                    console.log("Here's the response: ", res.data)

                    setCode(res.data.content)

                })
                .catch(err => {
                    console.log("Here's the error: ", err)
                })
        }
    }


    // SEND ENQUIRY

    const onBeforeMsgSubmit = (e) => {
        e.preventDefault()


        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (msg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "msg": msg,
            }


            onSubmitMsg(payload)
        }


    }


    const onSubmitMsg = (payload) => {

        window.dataLayer.push({ event: 'inquiry_submission' })

        ReactGa.event("inquiry_submission", { form_id: "Baseline Blog Form" })

        axios.post("https://www.ict-mentors.com/api/send_mail_blog", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setLoading(false)

                setName('')
                setEmail('')
                setPhone('')
                setMsg('')
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }



    ///////////////////////////////////////////////////////


    // SHARE iN SOCIALS

    const shareFb = () => {
        // const blogLink = encodeURI(window.location.href)
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.facebook.com/share.php?u=${blogLink}`)
        window.dataLayer.push({ event: 'share_blog' })

        ReactGa.event("share_blog", { blog_name: "Baseline Assessment", social_platform: "Facebook" })
    }

    const shareTwitter = () => {
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.twitter.com/share?url=${blogLink}`)
        window.dataLayer.push({ event: 'share_blog' })

        ReactGa.event("share_blog", { blog_name: "Baseline Assessment", social_platform: "Twitter" })
    }

    const shareLinkedin = () => {
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${blogLink}`)
        window.dataLayer.push({ event: 'share_blog' })

        ReactGa.event("share_blog", { blog_name: "Baseline Assessment", social_platform: "Linkedin" })
    }

    const shareWhatsapp = () => {
        const blogLink = encodeURI('https://ict-mentors.com/baseline_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article:')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)


        var message = `${msg} \n ${blogLink}`

        // Opening URL
        window.open(`https://api.whatsapp.com/send?text=${message}`);
        window.dataLayer.push({ event: 'share_blog' })

        ReactGa.event("share_blog", { blog_name: "Baseline Assessment", social_platform: "Whatsapp" })
    }


    //////////////////////////////////////////////////////////


    // Navigate to contact us page

    const goContact = (subject) => {
        navigate(`/contact_us?subject=${subject}`)
    }

    /////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        <ThemeProvider theme={theme}>
            <div className='blogpage-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>
                <Helmet>
                    <title>Why Perform a Baseline & Maturity Assessment</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="blog-section">

                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default BaseBlog