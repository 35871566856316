import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-hero-big.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/baseline2-blog.jpg"
import img2 from "../../images/digital-transformation.jpg"
import img3 from "../../images/digitalvsit-transformation.jpg"
import img1Small from "../../images/baseline2-blog-small.jpg"
import img2Small from "../../images/digital-transformation-small.jpg"
import img3Small from "../../images/digitalvsit-transformation-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const StrategyFormulation = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "Strategy Formulation"})
        navigate('/contact_us?subject=IT Strategy Formulation')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>IT Strategy Formulation</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">IT Strategy Formulation</h1>
                            <p className="solution-p appear-right">ICT Mentors Solutions Limited will work with organizations to build an effective IT strategic plan that will underpin the realization of the business plan and innovation. The plan will guide the business in the automation and digitization process for an efficient and competitive business operation.</p>

                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left"><strong>Alignment with Business Goals</strong> - A well-defined IT strategy ensures that technology initiatives are closely aligned with the overall business objectives. This alignment helps the organization focus its resources and efforts on activities that directly contribute to business growth and success.</li>
                                <li className="values-li appear-right"><strong>Cost Optimization</strong> - IT strategy can help identify opportunities to optimize IT spending. By aligning technology investments with business priorities, organizations can reduce unnecessary costs, eliminate redundant systems, and streamline IT operations.</li>
                                <li className="values-li appear-left"><strong>Improved Decision</strong> - Making: A clear IT strategy provides a framework for making informed decisions about technology investments. It helps in prioritizing projects and selecting technologies that best support the business's long-term goals, resulting in better decision-making throughout the organization.</li>
                                <li className="values-li appear-right"><strong>Enhanced Efficiency and Productivity</strong> - An effective IT strategy can lead to process improvements and automation, which can increase operational efficiency and employee productivity. This, in turn, can reduce operational costs and improve the bottom line.</li>
                                <li className="values-li appear-left"><strong>Risk Mitigation</strong> - IT strategies also focus on cybersecurity, data protection, and disaster recovery planning. These measures can help mitigate risks associated with data breaches, system failures, and other IT-related disruptions, safeguarding the organization's reputation and financial stability.</li>
                                <li className="values-li appear-right"><strong>Scalability and Flexibility</strong> - A well-crafted IT strategy takes into account the organization's future growth and scalability needs. It ensures that IT systems and infrastructure can adapt to changing business requirements and market conditions.</li>
                                <li className="values-li appear-left"><strong>Competitive Advantage</strong> - Leveraging technology effectively can provide a competitive edge. A strong IT strategy can help identify opportunities for innovation, such as implementing new technologies or digital transformation initiatives that set the business apart from competitors.</li>
                                <li className="values-li appear-right"><strong>Customer Experience</strong> - IT can significantly impact the customer experience. An IT strategy that focuses on improving customer-facing technologies, such as websites, apps, and customer support systems, can enhance customer satisfaction and loyalty.</li>
                                <li className="values-li appear-left"><strong>Data-Driven Insights</strong> - IT strategies often involve data management and analytics components. Leveraging data effectively can lead to better insights into customer behavior, market trends, and operational performance, enabling data-driven decision-making.</li>
                                <li className="values-li appear-right"><strong>Compliance and Regulatory Adherence</strong> - An IT strategy can help ensure that the organization complies with industry-specific regulations and data protection laws. Non-compliance can result in fines and damage to the brand's reputation.</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='Strategy Formulation' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <div className="square-wrapper">
                                    <img src={img1} alt="" loading='lazy' />
                                </div>
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>

                            <div className="square img4 secondary"></div>

                            <div className="square-img img5 blur-div" style={{ backgroundImage: `url(${img3Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img3} alt="" loading='lazy' />
                            </div>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default StrategyFormulation