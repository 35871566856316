import { useState, useEffect } from 'react'
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import "./SingleSolution.css"
import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import funkyLines from "../../images/funky-lines.png"
import solutionsHero from "../../images/solution-images/iso27001-solution-img.png"
import heroSmall from "../../images/solution-hero-small.png"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import img1 from "../../images/recognize-ddos.jpg"
import img1Small from "../../images/recognize-ddos-small.jpg"
import img2 from "../../images/training-on.jpg"
import img2Small from "../../images/training-on-small.jpg"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const ISO27001 = () => {


    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////

    // Lazy Load the images

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')

        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])

    /////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const navigate = useNavigate('')


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const goContact = () => {
        window.dataLayer.push({ event: 'go_enquire' })

        ReactGa.event("go_enquire", {enquiring_from: "ISO 27001 Solution"})
        navigate('/contact_us?subject=ISO 27001 Implementation')
    }


    return (
        <ThemeProvider theme={theme}>
            <div className='singlesolution-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>

                <Helmet>
                    <title>ISO 27001 - Information Security Management System</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <div className="solution-wrapper">
                    <div className="solution-left">

                        <div className="solution-left-top">
                            <img src={heroSmall} alt="" className="hero-small" loading='lazy' />

                            <h1 className="section-title appear-left">ISO 27001 - Information Security Management System</h1>
                            <p className="solution-p appear-right">Protecting information assets is vital to the core survival of an organization. With the increase in cyberattacks and viruses worldwide, it has become essential for organizations to adopt innovative and rigorous procedures to keep these assets out of the reach of exploiters. ISO 27001 aims to ensure protection of the confidentiality, integrity, and availability of your information and provides you and your customers the confidence that their data is safe. </p>
                            <p className="solution-p appear-right">The team of ICT Mentors Solutions will work with you to realize this critical standard ensuring the protection of the organization’s information assets.</p>
                        </div>

                        <div className="solution-left-value">
                            <h1 className="section-title appear-left">Value to Business</h1>

                            <ul className="values-ul">
                                <li className="values-li appear-left">Build and implement best business practices in your business for successful execution of strategies</li>
                                <li className="values-li appear-right">Don’t go in blinded bu success, plan the strategy out and you’ll be successful</li>
                                <li className="values-li appear-left">Utilize frameworks that have been proven in business to work just right.</li>
                                <li className="values-li appear-right">Improve your organizational instinct in detecting flaws in the plan, hence you move flawlessly into the world of success</li>
                            </ul>

                            <Button variant='outlined' color='secondary' className='appear-right reach-btn enquire-btn' id='ISO 27001' onClick={goContact}>Reach Out</Button>
                        </div>

                    </div>

                    <div className="solution-right">
                        <div className="squares-div">
                            <div className="square primary"></div>
                            <div className="square secondary"></div>
                            <div className="square primary"></div>
                            <div className="square secondary"></div>

                        </div>

                        <div className="imgs-div">
                            <div className="square-img img1 blur-div" style={{ backgroundImage: `url(${img1Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img1} alt="" loading='lazy' />
                            </div>

                            <div className="square img2 primary"></div>

                            <div className="square-img img3 blur-div" style={{ backgroundImage: `url(${img2Small})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={img2} alt="" loading='lazy' />
                            </div>


                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default ISO27001