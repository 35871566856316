import { useState, useEffect } from 'react'
import "./BlogPage.css"
import Nav2 from '../nav2/Nav2'
import Footer from '../footer/Footer'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import blog2Img from "../../images/blog2.jpg"
import blog2ImgSmall from "../../images/blog2-small.jpg"
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import dotGrid from "../../images/dot-grid.png"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useDispatch } from "react-redux"
import axios from 'axios';
import TextField from '@mui/material/TextField';
import vulnerability2Blog from "../../images/vulnerability2-blog.jpg"
import vulnerability2BlogSmall from "../../images/vulnerability2-blog-small.jpg"
import vulnerability3Blog from "../../images/vulnerability3-blog.jpg"
import vulnerability3BlogSmall from "../../images/vulnerability3-blog-small.jpg"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"



const VulnerabilityBlog = () => {

    // USESTATES

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [ads, setAds] = useState([])

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: '#27AAE1'
            },

            secondary: {
                main: "#2E3092"
            },
            warning: {
                main: "#ffffff"
            }

        }
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');


    // USEEFFECTS

    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')


        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    // LOAD BLOG ADS

    useEffect(() => {
        axios.get('https://www.ict-mentors.com/api/get_blog_ads')
            .then(res => {
                setAds(res.data)
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }, [])

    ////////////////////////////////////////////////////////////////

    // LOAD DISAPPEAR USEEFFECT

    useEffect(() => {
        const loader = document.querySelector('.loader-wrapper')

        function vanish() {
            loader.classList.add('disappear')
        }


        window.addEventListener('load', vanish)
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////////////////


    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.blog-p')
        const appearLeftImg = document.querySelectorAll('.img-container')
        const appearLeftAuthor = document.querySelectorAll('.author-date')
        const appearRightAd = document.querySelectorAll('.ad')
        const appearRights = document.querySelectorAll('.section-title')
        const appearRightSocials = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .socials')
        const appearRightForm = document.querySelectorAll('.blogpage-container .blog-right .form-container form')
        const appearLeftShare = document.querySelectorAll('.blogpage-container .share-div')
        const appearDowns = document.querySelectorAll('.blogpage-container .blog-ul .blog-li')
        const appearUps = document.querySelectorAll('.blogpage-container .blog-section .blog-top .title-socials .blog-title')



        const appearDownsFull = document.querySelectorAll('.appear-down-full')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })




        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


        const appearDownOptions = {
            threshold: 0.2,
        }

        const appearDownObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownOptions)

        appearDowns.forEach(appearRight => {
            appearDownObserver.observe(appearRight)
        })




        const appearLeftImgOptions = {
            threshold: 0.2,
        }

        const appearLeftImgObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftImgObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftImgOptions)

        appearLeftImg.forEach(appearLeft => {
            appearLeftImgObserver.observe(appearLeft)
        })

        const appearLeftAuthorOptions = {
            threshold: 0.2,
        }

        const appearLeftAuthorObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftAuthorObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftAuthorOptions)

        appearLeftAuthor.forEach(appearLeft => {
            appearLeftAuthorObserver.observe(appearLeft)
        })

        const appearLeftShareOptions = {
            threshold: 0.2,
        }

        const appearLeftShareObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftShareObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftShareOptions)

        appearLeftShare.forEach(appearLeft => {
            appearLeftShareObserver.observe(appearLeft)
        })

        const appearRightAdOptions = {
            threshold: 0.2,
        }

        const appearRightAdObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightAdObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightAdOptions)

        appearRightAd.forEach(appearRight => {
            appearRightAdObserver.observe(appearRight)
        })

        const appearRightFormOptions = {
            threshold: 0.2,
        }

        const appearRightFormObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightFormObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightFormOptions)

        appearRightForm.forEach(appearRight => {
            appearRightFormObserver.observe(appearRight)
        })

        const appearRightSocialsOptions = {
            threshold: 0.2,
        }

        const appearRightSocialsObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightSocialsObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightSocialsOptions)

        appearRightSocials.forEach(appearRight => {
            appearRightSocialsObserver.observe(appearRight)
        })

        const appearDownFullOptions = {
            threshold: 0.2,
        }

        const appearDownFullObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearDownFullOptions)

        appearDownsFull.forEach(appearRight => {
            appearDownFullObserver.observe(appearRight)
        })

        const appearUpOptions = {
            threshold: 0.2,
        }

        const appearUpObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearDownFullObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearUpOptions)

        appearUps.forEach(appearUp => {
            appearUpObserver.observe(appearUp)
        })
    }, [])

    /////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS


    // SEND ENQUIRY

    const onBeforeMsgSubmit = (e) => {
        e.preventDefault()


        if (name.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (email.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phone.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (msg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": name,
                "email": email,
                "phone": phone,
                "msg": msg,
            }
    
    
            onSubmitMsg(payload)
        }

        
    }


    const onSubmitMsg = (payload) => {

        window.dataLayer.push({event: 'inquiry_submission'})

        ReactGa.event("inquiry_submission", {form_id: "Vulnerability Assessment Blog Form"})

        axios.post("https://www.ict-mentors.com/api/send_mail_blog", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setLoading(false)

                setName('')
                setEmail('')
                setPhone('')
                setMsg('')
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)
            })
    }



    ///////////////////////////////////////////////////////




    // SHARE iN SOCIALS

    const shareFb = () => {
        // const blogLink = encodeURI(window.location.href)
        const blogLink = encodeURI('https://ict-mentors.com/vulnerability_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.facebook.com/share.php?u=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Vulnerability Assessment", social_platform: "Facebook"})

    }

    const shareTwitter = () => {
        const blogLink = encodeURI('https://ict-mentors.com/vulnerability_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.twitter.com/share?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Vulnerability Assessment", social_platform: "Twitter"})

    }

    const shareLinkedin = () => {
        const blogLink = encodeURI('https://ict-mentors.com/vulnerability_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)

        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${blogLink}`)
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Vulnerability Assessment", social_platform: "Linkedin"})

    }

    const shareWhatsapp = () => {
        const blogLink = encodeURI('https://ict-mentors.com/vulnerability_blog')
        const msg = encodeURIComponent('Hey, I found this interesting article:')
        const title = encodeURIComponent(document.querySelector('.blog-title').textContent)


        var message = `${msg} \n ${blogLink}`

        // Opening URL
        window.open(`https://api.whatsapp.com/send?text=${message}`);
        window.dataLayer.push({event: 'share_blog'})

        ReactGa.event("share_blog", {blog_name: "Vulnerability Assessment", social_platform: "Whatsapp"})

    }


    //////////////////////////////////////////////////////////

    // Navigate to contact us page

    const goContact = (subject) => {
        navigate(`/contact_us?subject=${subject}`)
    }

    /////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




    return (
        <ThemeProvider theme={theme}>
            <div className='blogpage-container' style={{ background: `url(${dotGrid}), repeat`, backgroundSize: "250px" }}>

                <Helmet>
                    <title>Managing vulnerabilities Within an Organization</title>
                    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
                </Helmet>

                <Nav2 />

                <section className="blog-section">
                    <div className="blog-top">
                        <div className="title-socials">
                            <h1 className="blog-title">Managing vulnerabilities Within an Organization</h1>

                            <div className="socials">
                                <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="Vulnerability Assessment-fb" />
                                <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="Vulnerability Assessment-twitter" />
                                <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="Vulnerability Assessment-linkedin" />
                                <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="Vulnerability Assessment-whatsapp" />

                            </div>
                        </div>

                        <div className="author-date">
                            <p className="author">By: John Mathenge</p>
                            <p className="date">Published: 28th August 2023</p>
                        </div>

                    </div>

                    <div className="blog-body">
                        <div className="blog-left">
                            <div className="img-container blur-div" style={{ backgroundImage: `url(${blog2ImgSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                <img src={blog2Img} alt="" className="main-img" loading='lazy' />
                            </div>

                            <p className="blog-p">In today's interconnected digital landscape, organisations face an ever-increasing number of cyber threats that can jeopardise sensitive data, disrupt operations, and tarnish reputations. Managing vulnerabilities has become a crucial aspect of any organisation's cybersecurity strategy. By proactively identifying, assessing, and mitigating potential weaknesses, businesses can fortify their defences against cyberattacks. In this blog, we will delve into the best practices for managing vulnerabilities in an organisation, helping you build a robust security posture to protect your assets and customers.</p>

                            <div className="blog-div">
                                <h1 className="section-title">Understanding Vulnerabilities</h1>

                                <p className="blog-p">To effectively manage vulnerabilities, it's essential to first understand what they are. Vulnerabilities are weaknesses in software, hardware, networks, or procedures that can be exploited by malicious actors. These vulnerabilities can arise from coding errors, misconfigurations, lack of updates, or even human negligence. By staying informed about the latest vulnerabilities and understanding their impact on your organisation, you can stay one step ahead of potential threats</p>

                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${vulnerability2BlogSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={vulnerability2Blog} alt="" className='normal-img' loading='lazy' />
                                </div>

                                <h1 className="section-title">Implementing a Vulnerability Management Program</h1>

                                <p className="blog-p">Creating a comprehensive vulnerability management program is the cornerstone of your organisation's security strategy. The program should involve the following steps:</p>

                                <ul className="blog-ul">
                                    <li className="blog-li">Asset Inventory - Know what devices, software, and systems are in your network. Regularly update this inventory as new assets are added or removed. </li>
                                    <li className="blog-li">Vulnerability Scanning - Conduct regular scans using automated tools to identify vulnerabilities across your network. These scans should cover both internal and external assets.</li>
                                    <li className="blog-li">Risk Assessment - Prioritise vulnerabilities based on their potential impact on your organisation and the likelihood of exploitation.</li>
                                    <li className="blog-li">Patch Management - Promptly apply security patches and updates to fix identified vulnerabilities. Automated patch management tools can streamline this process.</li>
                                    <li className="blog-li">Continuous Monitoring - Employ continuous monitoring to detect and respond to new vulnerabilities and threats as they emerge.</li>

                                </ul>
                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Establish Incident Response Plans</h1>

                                <p className="blog-p">No security system is completely foolproof, and breaches can still occur even with the best vulnerability management practices. Having well-defined incident response plans is crucial for minimising damage and recovering quickly. Your response plans should include the following elements:</p>

                                <ul className="blog-ul">
                                    <li className="blog-li">Roles and Responsibilities - Clearly define the roles of each team member during a security incident. This ensures a coordinated and effective response.</li>
                                    <li className="blog-li">Communication Protocols - Establish communication channels and protocols to quickly disseminate information during an incident.</li>
                                    <li className="blog-li">Containment and Recovery Procedures - Outline step-by-step procedures for containing the incident, eradicating threats, and restoring affected systems.</li>
                                </ul>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Collaborate with Security Experts</h1>

                                <p className="blog-p">Navigating the rapidly evolving cybersecurity landscape can be daunting. Consider collaborating with external cybersecurity experts who can offer valuable insights, perform penetration testing, and assess your vulnerability management program for blind spots.</p>

                            </div>

                            <div className="blog-div">
                                <div className="img-container blur-div" style={{ backgroundImage: `url(${vulnerability3BlogSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                                    <img src={vulnerability3Blog} alt="" className="normal-img" loading='lazy' />
                                </div>

                                <h1 className="section-title">Conclusion</h1>

                                <p className="blog-p">Managing vulnerabilities is an ongoing process that demands constant vigilance, adaptability, and a proactive mindset. By understanding the nature of vulnerabilities, implementing a comprehensive vulnerability management program, educating your workforce, and establishing effective incident response plans, you can significantly enhance your organisation's cybersecurity posture. Protecting your assets, customers, and reputation should be a top priority, and the best defence starts with effective vulnerability management. Remember, staying secure is an ongoing journey, and with the right approach, you can safeguard your organisation against even the most sophisticated cyber threats.</p>

                            </div>

                            <div className="blog-div">
                                <h1 className="section-title">Choose the right partner</h1>

                                <p className="blog-p">ICT Mentors Solutions stands as the ideal partner for your organisation's vulnerability management needs. With a wealth of experience and a relentless commitment to safeguarding your business-critical infrastructure, we offer comprehensive vulnerability assessment tools and solutions that ensure effective and efficient patch management across your entire organisation. Our approach blends industry best practices with cutting-edge technology, to identify, assess, and remediate vulnerabilities, protecting your systems from potential threats. With ICT Mentors Solutions, you're not just securing your infrastructure; you're gaining a trusted ally dedicated to your organisation's security and success.</p>

                            </div>

                            <div className="blog-div">
                                <Link to='/vulnerability'>
                                    <Button variant='contained' color='secondary' className='cta-btn' id='Vulnerability Assessment'>Get Started</Button>
                                </Link>
                            </div>

                            <div className="share-div">
                                <p className="share-p">Share on:</p>

                                <div className="socials">
                                    <FacebookRoundedIcon className='share-blog fb-icon' onClick={shareFb} id="Vulnerability Assessment-fb" />
                                    <TwitterIcon className='share-blog twitter-icon' onClick={shareTwitter} id="Vulnerability Assessment-twitter" />
                                    <LinkedInIcon className='share-blog linkedin-icon' onClick={shareLinkedin} id="Vulnerability Assessment-linkedin" />
                                    <WhatsAppIcon className='share-blog whatsapp-icon' onClick={shareWhatsapp} id="Vulnerability Assessment-whatsapp" />

                                </div>
                            </div>
                        </div>

                        <div className="blog-right">
                            <div className="form-container">
                                <h1 className="section-title">Enquire Now</h1>

                                <form onSubmit={onBeforeMsgSubmit} id='blog-form'>
                                    <TextField label="Name" type='text' variant="outlined" name='name' value={name} onChange={e => setName(e.target.value)} />
                                    <TextField label="Email" type='email' variant="outlined" name='email' value={email} onChange={e => setEmail(e.target.value)} />
                                    <TextField label="Phone" type='number' variant="outlined" name='phone' value={phone} onChange={e => setPhone(e.target.value)} />
                                    <TextField label="Message" type='text' multiline rows={4} variant="outlined" name='name' value={msg} onChange={e => setMsg(e.target.value)} />
                                    <LoadingButton
                                        className='contact-btn'
                                        type='submit' variant='contained'
                                        color='primary'
                                        style={{ color: "#ffffff" }}
                                        endIcon={<SendRoundedIcon className='send-icon' />}
                                        loading={loading}
                                        loadingPosition="end"
                                    >
                                        <span>Submit</span>

                                    </LoadingButton>
                                </form>
                            </div>

                            {ads.length > 0 ?
                                <>
                                    {ads.map(ad => (
                                        <>
                                            {ad.takeToContact ?
                                                <div className="ad" style={{ cursor: "pointer" }} onClick={() => goContact(ad.contactSubject)}>
                                                    <img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' />
                                                </div>
                                                :
                                                <>
                                                    {ad.link ?
                                                        <div className="ad">
                                                            <Link to={ad.link}><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                        :
                                                        <div className="ad">
                                                            <Link to='/'><img src={ad.img[0] == 'h' ? ad.img : `https://www.ict-mentors.com${ad.img}`} alt="" loading='lazy' /></Link>
                                                        </div>
                                                    }
                                                </>

                                            }
                                        </>
                                    ))}
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </ThemeProvider>
    )
}

export default VulnerabilityBlog